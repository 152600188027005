import {
	Box,
	Button,
	Center,
	Icon,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	SimpleGrid,
	useToast,
} from '@chakra-ui/react'
import {
	FiHome,
	FiLogIn,
	FiLogOut,
	FiPlus,
	FiUser,
	FiUserPlus,
} from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { logout } from '../redux/auth/authActions'
import { CgProfile } from 'react-icons/cg'
import { RiLockPasswordLine } from 'react-icons/ri'

const BottomNavigation = () => {
	const toast = useToast()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { user } = useSelector((state) => state.auth)

	return (
		<SimpleGrid
			display={['grid', 'grid', 'none', 'none']}
			pos={'fixed'}
			bottom={'-1px'}
			left={0}
			zIndex={777}
			w={'100%'}
			bg={'brand.500'}
			templateColumns={'repeat(3, 1fr)'}
			textAlign={'center'}
		>
			<Center as={Link} to={'/'} h={'50px'}>
				<Icon fontSize={'1.6rem'} color={'brand.50'} as={FiHome} />
			</Center>
			<Box h={'50px'}>
				<IconButton
					w={'60px'}
					h={'60px'}
					transform={'translateY(-30px)'}
					variant={'brand'}
					borderWidth={'5px'}
					borderStyle={'solid'}
					borderColor={'white'}
					fontSize={'2rem'}
					boxShadow={'2xl'}
					size={'lg'}
					rounded={'full'}
					onClick={() => navigate('/business')}
					icon={<FiPlus />}
				/>
			</Box>
			<Center h={'50px'}>
				<Menu>
					<MenuButton
						rounded={'full'}
						as={IconButton}
						variant={'outline'}
						colorScheme={'white'}
						aria-label="Options"
						fontSize={'1.1rem'}
						placement={'bottom-left'}
						borderColor={'transparent'}
						w={'100%'}
						_focus={{
							boxShadow: 'none',
						}}
						_active={{
							boxShadow: 'none',
						}}
						ps={'1'}
						pe={'3'}
						icon={<Icon fontSize={'1.6rem'} color={'brand.50'} as={FiUser} />}
					/>

					<MenuList color={'gray.700'}>
						{!user ? (
							<>
								<MenuItem
									as={Link}
									to={'/signin'}
									fontWeight={'500'}
									icon={<FiLogIn />}
									command=""
								>
									Sign In
								</MenuItem>
								<MenuItem
									as={Link}
									to={'/register'}
									fontWeight={'500'}
									icon={<FiUserPlus />}
									command=""
								>
									Register
								</MenuItem>
							</>
						) : (
							<>
								<MenuItem
									as={Link}
									bg={'transparent'}
									to={`/profile/edit-profile`}
									fontWeight={'500'}
									icon={<CgProfile />}
									command=""
								>
									My Profile
								</MenuItem>
								<MenuItem
									as={Button}
									bg={'transparent'}
									onClick={() => {
										navigate(`/profile/change-password`)
									}}
									fontWeight={'500'}
									icon={<RiLockPasswordLine />}
									command=""
								>
									Change Password
								</MenuItem>
								<MenuItem
									as={Button}
									bg={'transparent'}
									onClick={() => {
										dispatch(
											logout((msg, status) => {
												toast({
													title: 'Sign Out',
													description: msg,
													status: status,
													duration: 4000,
													isClosable: true,
												})
												return <Navigate to={'/signin'} />
											})
										)
									}}
									fontWeight={'500'}
									icon={<FiLogOut />}
									command=""
								>
									Sign Out
								</MenuItem>
							</>
						)}
					</MenuList>
				</Menu>
			</Center>
		</SimpleGrid>
	)
}

export default BottomNavigation
