import {
	Stack,
	Checkbox,
	CheckboxGroup,
	Text,
	Box,
	IconButton,
} from '@chakra-ui/react'
import FilterWidget from './FilterWidget'
import PricingGroup from './PricingGroup'
import { getRequest } from '../services/base.service'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { objectToQueryParams } from '../helpers/utils'
import { fetchBusinessList, toggleBusinessFilter } from '../redux'
import { FiX } from 'react-icons/fi'

const LeftSidebarFilters = ({ qParamsObj }) => {
	const [products, setProducts] = useState([])
	const [selectedData, setSelectedData] = useState({})
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const { type, slug } = useParams()
	const { isShowingBusinessFilter } = useSelector((state) => state.common)

	useEffect(() => {
		fetchProducts()
		setSelectedData(qParamsObj)
	}, [qParamsObj])

	const fetchProducts = () => {
		getRequest(`products`)
			.then((res) => {
				setProducts(res.result)
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const searchBusiness = (qData) => {
		const location = window.location
		console.log('Location: ', location)

		console.log('Location: ', type ? { ...qData, [type]: slug } : qData)
		const formData = type ? { ...qData, [type]: slug } : qData

		dispatch(
			fetchBusinessList(formData, (isSuccess) => {
				if (isSuccess)
					navigate(`${location.pathname}?${objectToQueryParams(qData)}`)
			})
		)
	}

	const handleCheckboxGroupOnChange = (name, values) => {
		let qParams = { ...selectedData }
		for (let [key, value] of searchParams.entries()) {
			qParams[key] = value
		}

		setSelectedData({ ...qParams, [name]: values })
		searchBusiness({ ...qParams, [name]: values })
	}

	return (
		<>
			<Box
				pos={'fixed'}
				display={['block', 'block', 'block', 'none']}
				top={0}
				h={'100%'}
				w={'100%'}
				cursor={'pointer'}
				onClick={() => dispatch(toggleBusinessFilter())}
				zIndex={isShowingBusinessFilter ? 990 : -999}
				visibility={isShowingBusinessFilter ? 'visible' : 'hidden'}
				transition={'all .25s'}
				left={0}
				bg={'rgba(0, 0, 0, .3)'}
			/>
			<Stack
				pos={['fixed', 'fixed', 'fixed', 'static']}
				zIndex={[999, 999, 999, 0]}
				left={isShowingBusinessFilter ? 0 : '-400px'}
				transition={'all .25s'}
				top={0}
				bg={'white'}
				ps={[2, 2, 2, 0]}
				gap={3}
				pt="5"
				pe="4"
				maxH={'100%'}
				// overflowY={'auto'}
				minH={'100%'}
				w={'320px'}
			>
				<IconButton
					display={['flex', 'flex', 'flex', 'none']}
					pos={'absolute'}
					top={'7px'}
					size={'sm'}
					onClick={() => dispatch(toggleBusinessFilter())}
					variant={'outline'}
					rounded={'full'}
					right={'10px'}
					icon={<FiX />}
				/>
				<FilterWidget title={'Pricing'}>
					<PricingGroup
						defaultValue={qParamsObj?.pricing}
						onChange={handleCheckboxGroupOnChange}
					/>
				</FilterWidget>

				{products.length > 0 && (
					<FilterWidget title={'Products'}>
						<CheckboxGroup
							defaultValue={qParamsObj?.products?.split(',')}
							onChange={(values) =>
								handleCheckboxGroupOnChange('products', values.join(','))
							}
						>
							<Stack gap={1}>
								{products.map((product, index) => (
									<Checkbox key={index} value={product?.name} colorScheme="red">
										{product?.name}
									</Checkbox>
								))}
								{/* <Text
								as="button"
								align={'left'}
								color={'brand.500'}
								textDecoration={'underline'}
							>
								See More
							</Text> */}
							</Stack>
						</CheckboxGroup>
					</FilterWidget>
				)}

				{products.length > 0 && (
					<FilterWidget title={'Offers'}>
						<CheckboxGroup
							defaultValue={qParamsObj?.offers?.split(',')}
							onChange={(values) =>
								handleCheckboxGroupOnChange('offers', values.join(','))
							}
						>
							<Stack gap={1}>
								<Checkbox colorScheme="red" value="Special Offers">
									Special Offers
								</Checkbox>
								<Checkbox colorScheme="red" value={'No Deposit'}>
									No Deposit
								</Checkbox>
								<Checkbox colorScheme="red" value={'Pick and Drop'}>
									Pick and Drop
								</Checkbox>
								<Checkbox colorScheme="red" value={'Free Delivery'}>
									Free Delivery
								</Checkbox>
							</Stack>
						</CheckboxGroup>
					</FilterWidget>
				)}
			</Stack>
		</>
	)
}

export default LeftSidebarFilters
