import { Box, Heading, Text } from '@chakra-ui/react'

const AdminStatsCard = ({ title, value }) => {
	return (
		<Box
			borderWidth={'1px'}
			borderStyle={'solid'}
			borderColor={'brand.500'}
			rounded={'8'}
			p={4}

		>
			<Heading color={'brand.500'} as="h1" fontSize={'3xl'}>
				{value}
			</Heading>
			<Text color={'brand.500'} as="h2" fontSize={'lg'}>
				{title}
			</Text>
		</Box>
	)
}

export default AdminStatsCard
