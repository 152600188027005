import { Box, calc, Center, Flex, Icon, Stack, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { GoDashboard, GoBriefcase, GoPlus, GoSignOut } from 'react-icons/go'
import { useDispatch, useSelector } from 'react-redux'
import { FiX, FiFileText, FiMapPin, FiPlusCircle } from 'react-icons/fi'
import { logout } from '../../redux/auth/authActions'
import { toggleAdminSidebar } from '../../redux/common/commonActions'

const AdminLeftNavbar = () => {
	const dispatch = useDispatch()
	const { toggleAdminSidebarFlag } = useSelector((state) => state.common)
	console.log(toggleAdminSidebarFlag)
	return (
		<Box>
			<Box
				h={'100%'}
				w={'100%'}
				bg={'black'}
				pos={'fixed'}
				top={'0'}
				left={[
					`${toggleAdminSidebarFlag ? '0' : '-100%'}`,
					`${toggleAdminSidebarFlag ? '0' : '-100%'}`,
					'-100%',
					'-100%',
				]}
				opacity={'0.5'}
				zIndex={'998'}
				onClick={() => dispatch(toggleAdminSidebar())}
			/>
			<Flex
				h={'100vh'}
				w={'300px'}
				flexDir={'column'}
				justify={'space-between'}
				alignItems={'center'}
				zIndex={'999'}
				pos={'fixed'}
				top={0}
				left={[
					`${toggleAdminSidebarFlag ? '0' : '-500px'}`,
					`${toggleAdminSidebarFlag ? '0' : '-500px'}`,
					'0',
					'0',
				]}
				transition={'.5s'}
			>
				<Box width={'100%'} h={'calc(100% - 60px)'} bg={'brand.500'}>
					<Flex
						h="60px"
						alignItems={'center'}
						justify={'space-between'}
						ps={'4'}
						pe={'4'}
						w="100%"
					>
						<Text fontWeight={'bold'} color="white" textTransform={'uppercase'}>
							RentMy B. D.
						</Text>
						<Icon
							display={['flex', 'flex', 'none', 'none']}
							as={FiX}
							color={'white'}
							_hover={{ cursor: 'pointer' }}
							onClick={() => dispatch(toggleAdminSidebar())}
						/>
					</Flex>
					<Box pt={'7'} h={'calc(100% - 60px)'} overflowX={'hidden'}>
						<Stack gap={4} fontWeight={'500'} color={'gray.700'}>
							<Text
								ps={'4'}
								size={'12px'}
								color={'brand.200'}
								letterSpacing={'.08rem'}
							>
								Explore
							</Text>

							<Text
								as={Link}
								display={'flex'}
								fontWeight={'500'}
								color="brand.50"
								alignItems={'center'}
								ps={'4'}
								to="/admin/dashboard"
							>
								<Icon as={GoDashboard} mb={'2px'} me="3" opacity={'0.8'} />
								<Text as="span">Dashboard</Text>
							</Text>

							<Text
								as={Link}
								display={'flex'}
								fontWeight={'500'}
								color="brand.50"
								alignItems={'center'}
								ps={'4'}
								to="/admin/business-list"
							>
								<Icon as={GoBriefcase} mb={'2px'} me="3" opacity={'0.8'} />
								<Text as="span">Business List</Text>
							</Text>

							<Text
								as={Link}
								display={'flex'}
								fontWeight={'500'}
								color="brand.50"
								alignItems={'center'}
								ps={'4'}
								to="/admin/business/create"
							>
								<Icon as={GoPlus} mb={'2px'} me="3" opacity={'0.8'} />
								<Text as="span">Add New Business</Text>
							</Text>

							<Text
								as={Link}
								display={'flex'}
								fontWeight={'500'}
								color="brand.50"
								alignItems={'center'}
								ps={'4'}
								to="/admin/claimed-list"
							>
								<Icon as={FiFileText} mb={'2px'} me="3" opacity={'0.8'} />
								<Text as="span">Claimed List</Text>
							</Text>

							{/* <Text
							as={Link}
							display={'flex'}
							fontWeight={'500'}
							color="brand.50"
							alignItems={'center'}
							ps={'4'}
							to="#"
						>
							<Icon as={FaRegGem} mb={'2px'} me="3" opacity={'0.8'} />
							<Text as="span">Sponsor Management</Text>
						</Text> */}

							<Text
								ps={'4'}
								size={'12px'}
								color={'brand.200'}
								letterSpacing={'.08rem'}
							>
								Location
							</Text>

							<Text
								as={Link}
								display={'flex'}
								fontWeight={'500'}
								color="brand.50"
								alignItems={'center'}
								ps={'4'}
								to="/admin/location/add"
							>
								<Icon as={FiPlusCircle} mb={'2px'} me="3" opacity={'0.8'} />
								<Text as="span">Add Location</Text>
							</Text>

							<Text
								as={Link}
								display={'flex'}
								fontWeight={'500'}
								color="brand.50"
								alignItems={'center'}
								ps={'4'}
								to="/admin/location/countries"
							>
								<Icon as={FiMapPin} mb={'2px'} me="3" opacity={'0.8'} />
								<Text as="span">Countries</Text>
							</Text>

							<Text
								as={Link}
								display={'flex'}
								fontWeight={'500'}
								color="brand.50"
								alignItems={'center'}
								ps={'4'}
								to="/admin/location/states"
							>
								<Icon as={FiMapPin} mb={'2px'} me="3" opacity={'0.8'} />
								<Text as="span">States/Province</Text>
							</Text>

							<Text
								as={Link}
								display={'flex'}
								fontWeight={'500'}
								color="brand.50"
								alignItems={'center'}
								ps={'4'}
								to="/admin/location/cities"
							>
								<Icon as={FiMapPin} mb={'2px'} me="3" opacity={'0.8'} />
								<Text as="span">Cities</Text>
							</Text>

							<Text
								ps={'4'}
								size={'12px'}
								color={'brand.200'}
								letterSpacing={'.08rem'}
							>
								Others
							</Text>

							{/* <Text
							as={Link}
							display={'flex'}
							fontWeight={'500'}
							color="brand.50"
							alignItems={'center'}
							ps={'4'}
							to="#"
						>
							<Icon as={FaCogs} mb={'2px'} me="3" opacity={'0.8'} />
							<Text as="span">Settings</Text>
						</Text> */}

							<Text
								as={'button'}
								onClick={() => dispatch(logout())}
								display={'flex'}
								fontWeight={'500'}
								color="brand.50"
								alignItems={'center'}
								ps={'4'}
							>
								<Icon as={GoSignOut} me="3" opacity={'0.8'} />
								<Text as="span">Sign Out</Text>
							</Text>
						</Stack>
					</Box>
				</Box>
				<Center w="100%" h={'60px'} bg={'brand.500'} color={'brand.100'}>
					<Text fontSize={'13px'}>&copy; 2022 RBD. All right reserved</Text>
				</Center>
			</Flex>
		</Box>
	)
}

export default AdminLeftNavbar
