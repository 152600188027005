import {
	CURRENT_PAGE_HEADING,
	TOGGLE_ADMIN_SIDEBAR,
	TOGGLE_ADMIN_SIDEBAR_ON_URL_CHANGE,
	TOGGLE_BUSINESS_FILTER,
} from './actionTypes'

const initialState = {
	isShowingBusinessFilter: false,
	toggleAdminSidebarFlag: false,
	currentPageHeading: '',
}

const reducer = (state = initialState, action) => {
	switch (('Reducer LOG', action.type)) {
		/**
		 * Toggle business filter
		 */
		case TOGGLE_BUSINESS_FILTER:
			return {
				...state,
				isShowingBusinessFilter: !state.isShowingBusinessFilter,
			}
		case TOGGLE_ADMIN_SIDEBAR:
			return {
				...state,
				toggleAdminSidebarFlag: !state.toggleAdminSidebarFlag,
			}
		case TOGGLE_ADMIN_SIDEBAR_ON_URL_CHANGE:
			return {
				...state,
				toggleAdminSidebarFlag: false,
			}
		case CURRENT_PAGE_HEADING:
			return {
				...state,
				currentPageHeading: action.payload,
			}
		default:
			return state
	}
}

export default reducer
