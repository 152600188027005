import {
	Box,
	Button,
	Flex,
	Image,
	Spacer,
	Heading,
	Text,
	Badge,
} from '@chakra-ui/react'
import moment from 'moment'
import { Link } from 'react-router-dom'

const AdminBusinessCard = ({ companyName, data }) => {
	const setBadgeColorScheme = (status) => {
		let colorScheme

		if (status === 'approved') {
			colorScheme = 'green'
		} else if (status === 'pending') {
			colorScheme = 'yellow'
		} else {
			colorScheme = 'red'
		}

		return colorScheme
	}

	return (
		<Box borderWidth={'1px'} borderColor={'gray.300'} p={2}>
			<Flex
				align="center"
				flexDirection={['column', 'column', 'row', 'row']}
				p={['3', '3', '0', '0']}
			>
				<Box mb={['2', '2', '0', '0']}>
					<Flex
						align="center"
						flexDirection={['column', 'column', 'row', 'row']}
						justify={['center','center','start','start',]}
						textAlign={['center','center','start','start',]}
					>
						<Image
							src={data?.logo}
							alt=""
							boxSize={'80px'}
							objectFit={'contain'}
							mr={['0', '0', '4', '4']}
							mb={['2', '2', '0', '0']}
						/>
						<Box>
							<Heading as="h3" fontSize="xl">
								{companyName}

								<Badge ms={2} colorScheme={setBadgeColorScheme(data?.status)}>
									{data?.status}
								</Badge>
							</Heading>

							<Text>Created on: {moment(data?.created_at).format('LL')}</Text>
						</Box>
					</Flex>
				</Box>
				<Spacer />
				<Box>
					<Button
						as={Link}
						to={`/admin/business-list/details/${data?.id}`}
						size={'sm'}
						w={'100px'}
						variant={'brand'}
						ml={['0', '0', '2', '0']}
					>
						View
					</Button>
				</Box>
			</Flex>
		</Box>
	)
}

export default AdminBusinessCard
