import {
	Box,
	Center,
	FormControl,
	Image,
	Input,
	Stack,
	Text,
	Button,
	Heading,
	InputGroup,
	InputRightElement,
	Icon,
	Alert,
	AlertTitle,
	AlertDescription,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { login } from '../../redux/auth/authActions'
import { BiShow, BiHide } from 'react-icons/bi'
import { useState } from 'react'

const AuthAdminSignin = () => {
	const [showPass, setShowPass] = useState(false)
	const { user, loginErrors } = useSelector((state) => state.auth)
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()
	const dispatch = useDispatch()
	const { loading } = useSelector((status) => status.auth)
	const handleSignin = (data) => {
		dispatch(login(data, 'admin/login'))
	}

	if (user && user?.role === 'admin') {
		return <Navigate to={'/admin'} />
	}

	return (
		<Center w={'100%'} h={'100vh'} px={'10px'}>
			<Box
				border={'1px'}
				borderColor={'brand.500'}
				w={'400px'}
				p={5}
				align={'center'}
				borderRadius={'md'}
			>
				<Center w={'100px'} h={'100px'} bg={'brand.500'} mb={3}>
					<Image
						w={'90px'}
						bg={'brand.500'}
						src="https://rentmy.co/wp-content/uploads/2019/11/logo-white.png"
						alt="RentMy Logo"
					/>
				</Center>
				<Heading fontSize="lg" mb={5}>
					Rental Business Directory Admin
				</Heading>
				{loginErrors?.email[0] && (
					<Alert status="error" mb={'5'}>
						<Box>
							<AlertTitle mr={2}>Login Error</AlertTitle>
							<AlertDescription>{loginErrors?.email[0]}</AlertDescription>
						</Box>
					</Alert>
				)}
				<Stack gap={'3'} onSubmit={handleSubmit(handleSignin)} as="form">
					<FormControl>
						<Input
							size={'lg'}
							id="email"
							type="email"
							placeholder="Email Address"
							borderColor="gray.300"
							isInvalid={errors.email?.type === 'required'}
							{...register('email', {
								required: true,
								pattern: {
									value: /\S+@\S+\.\S+/,
									message: 'Entered value does not match email format',
								},
							})}
						/>
						<Text color="red">
							{errors.email?.type === 'required' && 'Email is required'}
						</Text>
					</FormControl>

					<FormControl>
						<InputGroup size={'lg'}>
							<Input
								size={'lg'}
								id="password"
								type={showPass ? 'text' : 'password'}
								borderColor="gray.300"
								placeholder="Password"
								isInvalid={errors.password?.type === 'required'}
								{...register('password', { required: true })}
							/>
							<InputRightElement>
								<Icon
									_hover={{ cursor: 'pointer' }}
									as={showPass ? BiHide : BiShow}
									onClick={() => setShowPass(!showPass)}
								/>
							</InputRightElement>
						</InputGroup>
						<Text color="red">
							{errors.password?.type === 'required' && 'Password is required'}
						</Text>
					</FormControl>

					<Button
						type="submit"
						w={'full'}
						size={'lg'}
						variant={'brand'}
						isLoading={loading}
						loadingText="Signing in..."
					>
						Sign In
					</Button>
				</Stack>
			</Box>
		</Center>
	)
}

export default AuthAdminSignin
