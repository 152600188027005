import { useEffect, useState } from 'react'
import {
	FormControl,
	Grid,
	Input,
	GridItem,
	Select,
	Button,
} from '@chakra-ui/react'
import { FiSearch } from 'react-icons/fi'
import { getRequest } from '../services/base.service'
import { fetchBusinessList } from '../redux'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { objectToQueryParams } from '../helpers/utils'

const PageSearchForm = ({ qParamsObj }) => {
	const [countries, setCountries] = useState([])
	const [states, setStates] = useState([])
	const [cities, setCities] = useState([])
	const [selectedData, setSelectedData] = useState({})

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	useEffect(() => {
		fetchCountries()
		setSelectedData(qParamsObj)
		if (qParamsObj?.country) {
			fetchStates(qParamsObj.country)
		}

		if (qParamsObj?.state) {
			fetchCities(qParamsObj.state)
		}
	}, [qParamsObj])

	const handleOnChange = (name, value) => {
		if (name === 'country') {
			if (!value) {
				setStates([])
				setCities([])
			} else {
				fetchStates(value)
			}
			setSelectedData({
				...selectedData,
				country: value,
				state: '',
				city: '',
			})
			return
		} else if (name === 'state') {
			if (!value) {
				setCities([])
			} else {
				fetchCities(value)
			}
			setSelectedData({ ...selectedData, state: value, city: '' })
			return
		} else {
			setSelectedData({ ...selectedData, [name]: value })
		}
	}

	const fetchCountries = () => {
		getRequest('countries')
			.then((res) => {
				setCountries(res.result)
			})
			.catch((err) => {
				console.error(err)
			})
	}

	const fetchStates = (countryId) => {
		getRequest(`states/${countryId}`)
			.then((res) => {
				setStates(res.result?.states || [])
				setCities(res.result?.cities || [])
			})
			.catch((err) => {
				console.error(err)
			})
	}

	const fetchCities = (stateId) => {
		getRequest(`cities/${stateId}`)
			.then((res) => {
				setCities(res.result?.cities || [])
			})
			.catch((err) => {
				console.error(err)
			})
	}

	const handleSubmitSearchForm = (e) => {
		e.preventDefault()

		let qParams = {}
		for (let [key, value] of searchParams.entries()) {
			qParams[key] = value
		}

		dispatch(
			fetchBusinessList({ ...qParams, ...selectedData }, (isSuccess) => {
				if (isSuccess) {
					// setSelectedData(qParams)
					navigate(
						`/search?${objectToQueryParams({ ...qParams, ...selectedData })}`,
						{
							replace: true,
						}
					)
				}
			})
		)
	}

	return (
		<Grid
			as={'form'}
			onSubmit={handleSubmitSearchForm}
			templateRows={'repeat(2, 1fr)'}
			templateColumns={'repeat(4, 1fr)'}
			gap={4}
		>
			<GridItem colSpan={[4, 4, 3, 3]}>
				<FormControl>
					<Input
						id="keywords"
						defaultValue={qParamsObj?.keyword}
						onChange={(e) => handleOnChange('keyword', e.target.value)}
						type="text"
						size={'lg'}
						borderColor={'gray.500'}
						placeholder="Business/Product/Product Category"
					/>
				</FormControl>
			</GridItem>

			<GridItem colSpan={[2, 2, 1, 1]}>
				<FormControl>
					<Select
						value={selectedData?.country}
						borderColor={'gray.500'}
						size={'lg'}
						onChange={(e) => handleOnChange('country', e.target.value)}
						placeholder={'Country'}
					>
						{countries.map((country, index) => (
							<option key={index} value={country.id}>
								{country.name}
							</option>
						))}
					</Select>
				</FormControl>
			</GridItem>

			<GridItem colSpan={[2, 2, 1, 1]}>
				<Select
					size={'lg'}
					value={selectedData?.state}
					isDisabled={!states.length > 0}
					onChange={(e) => handleOnChange('state', e.target.value)}
					borderColor={'gray.500'}
					placeholder={'State'}
				>
					{states.map((state, index) => (
						<option key={index} value={state.id}>
							{state.name}
						</option>
					))}
				</Select>
			</GridItem>

			<GridItem colSpan={[2, 2, 1, 1]}>
				<Select
					size={'lg'}
					value={selectedData?.city}
					isDisabled={!cities.length > 0}
					onChange={(e) => handleOnChange('city', e.target.value)}
					borderColor={'gray.500'}
					placeholder={'City'}
				>
					{cities.map((city, index) => (
						<option key={index} value={city.id}>
							{city.name}
						</option>
					))}
				</Select>
			</GridItem>

			<GridItem colSpan={[2, 2, 1, 1]}>
				<FormControl>
					<Input
						id="zipcode"
						defaultValue={qParamsObj?.zipcode}
						onChange={(e) => handleOnChange('zipcode', e.target.value)}
						type="text"
						size={'lg'}
						borderColor={'gray.500'}
						placeholder="Zip Code"
					/>
				</FormControl>
			</GridItem>

			<GridItem colSpan={[4, 4, 1, 1]}>
				<Button
					size={'lg'}
					w={'full'}
					type={'submit'}
					bg={'brand.500'}
					color={'brand.50'}
					_hover={{
						bg: 'brand.600',
						color: 'white',
					}}
					_focus={{
						bg: 'brand.700',
						color: 'white',
					}}
					_active={{
						bg: 'brand.700',
						color: 'white',
					}}
					leftIcon={<FiSearch />}
				>
					Search
				</Button>
			</GridItem>
		</Grid>
	)
}

export default PageSearchForm
