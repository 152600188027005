import {
	CURRENT_PAGE_HEADING,
	TOGGLE_ADMIN_SIDEBAR,
	TOGGLE_ADMIN_SIDEBAR_ON_URL_CHANGE,
	TOGGLE_BUSINESS_FILTER,
} from './actionTypes'

export const toggleBusinessFilter = () => {
	return {
		type: TOGGLE_BUSINESS_FILTER,
	}
}
export const toggleAdminSidebar = () => {
	return {
		type: TOGGLE_ADMIN_SIDEBAR,
	}
}
export const setAdminSidebarFalseOnUrlChange = () => {
	return {
		type: TOGGLE_ADMIN_SIDEBAR_ON_URL_CHANGE,
	}
}

export const setCurrentPageHeading = (pageHeading) => {
	return {
		type: CURRENT_PAGE_HEADING,
		payload: pageHeading,
	}
}
