const Button = {
	baseStyle: {
		fontWeight: '500',
	},
	variants: {
		brand: {
			bg: 'brand.500',
			color: 'brand.50',
			_hover: {
				bg: 'brand.600',
				color: 'brand.50',
				_disabled: {
					opacity: 0.4,
					bg: 'brand.500',
					color: 'brand.50',
				},
			},
			_focus: {
				bg: 'brand.600',
				color: 'brand.50',
			},
			_active: {
				bg: 'brand.600',
				color: 'brand.50',
			},
			_disabled: {
				opacity: 0.4,
				bg: 'brand.500',
				color: 'brand.50',
			},
		},
	},
}

export default Button
