import {
	Box,
	Center,
	Container,
	Flex,
	Heading,
	IconButton,
	SimpleGrid,
	Spinner,
	Text,
} from '@chakra-ui/react'
import LeftSidebarFilters from '../components/LeftSidebarFilters'
import BusinessCard from '../components/BusinessCard'
import PageSearchForm from '../components/PageSearchForm'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { queryParamsToObject } from '../helpers/utils'
import { fetchBusinessList, toggleBusinessFilter } from '../redux'
import { FiFilter } from 'react-icons/fi'

const Search = () => {
	const [params, setParams] = useState(null)
	const business = useSelector((state) => state.business)
	const dispatch = useDispatch()

	useEffect(() => {}, [])

	useEffect(() => {
		const qParams = window.location.search
			? queryParamsToObject(window.location.search.substring(1))
			: {}
		setParams(qParams)
		dispatch(fetchBusinessList(qParams))
	}, [dispatch])

	return (
		<>
			<Box
				py={'8'}
				borderBottomWidth={'1px'}
				borderBottomStyle={'solid'}
				borderColor={'gray.300'}
			>
				<Container maxW={'container.xl'}>
					<PageSearchForm qParamsObj={params} />
				</Container>
			</Box>

			<Container maxW={'container.xl'}>
				<Flex>
					<LeftSidebarFilters qParamsObj={params} />

					<Box
						borderLeftWidth={[0, 0, 0, '1px']}
						borderLeftStyle={'solid'}
						borderLeftColor={'gray.300'}
						w={['100%', '100%', '100%', 'calc(100% - 320px)']}
						ps={[0, 0, 0, '6']}
						pb={'6'}
						pt={5}
					>
						{/* <Box mb={'6'}>
							<Heading
								as={'h4'}
								fontWeight={'700'}
								fontSize={'1.5rem'}
								mb={'4'}
							>
								XYZ near San Francisco, CA 94114
							</Heading>

							<Heading as={'h3'} fontSize={'1.3rem'} mb={'4'}>
								Sponsor
							</Heading>
							<BusinessCard />
						</Box> */}

						<Box>
							<Flex justify={'space-between'} mb={'4'} alignItems={'center'}>
								<Heading fontSize={'1.3rem'}>All Result</Heading>

								<Box display={['block', 'block', 'block', 'none']}>
									<IconButton
										onClick={() => dispatch(toggleBusinessFilter())}
										icon={<FiFilter />}
									/>
								</Box>
							</Flex>

							{business.isLoading ? (
								<Center h={'300px'}>
									<Box>
										<Spinner
											thickness="4px"
											speed="0.65s"
											emptyColor="gray.200"
											color="brand.500"
											size="xl"
										/>
										<Text textAlign={'center'}>Loading...</Text>
									</Box>
								</Center>
							) : (
								<>
									{business.businessList.length > 0 ? (
										<SimpleGrid gap={[4, 5, 6, 6]} columns={[1, 1, 2, 2]}>
											{business.businessList.map((business, index) => (
												<BusinessCard key={index} data={business} />
											))}
										</SimpleGrid>
									) : (
										<Box>No business found</Box>
									)}
								</>
							)}
						</Box>
					</Box>
				</Flex>
			</Container>
		</>
	)
}

export default Search
