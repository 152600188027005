import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody,
	PopoverArrow,
	Box,
	Avatar,
	Text,
	Stack,
	Icon,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FaCogs } from 'react-icons/fa'
import { GoSignOut } from 'react-icons/go'
import { useDispatch } from 'react-redux'
import { logout } from '../redux/auth/authActions'

const AdminHeaderUser = () => {
	const dispatch = useDispatch()

	return (
		<Popover placement={'bottom-end'} boxShadow="none">
			<PopoverTrigger>
				<Box
					as="button"
					display={'flex'}
					pt="1"
					pe="2"
					pb="1"
					ps="2"
					bg={'gray.100'}
					alignItems={'center'}
					rounded={5}
				>
					<Avatar size={'sm'} />

					<Text
						display={['none', 'inline-block', 'inline-block', 'inline-block']}
						as={'span'}
						color={'gray.600'}
						fontWeight={'500'}
						ms={'2'}
					>
						Admin
					</Text>
				</Box>
			</PopoverTrigger>
			<PopoverContent
				outline={'none'}
				maxW={'200px'}
				_focus={{ boxShadow: 'none' }}
				boxShadow={'none'}
			>
				<PopoverArrow />
				<PopoverBody pt="3" pb="3">
					<Stack gap={'1'}>
						<Text
							as={Link}
							to={'/admin'}
							display={'flex'}
							alignItems={'center'}
						>
							<Icon me="2" color={'gray.600'} as={FaCogs} />

							<Text as="span">Settings</Text>
						</Text>
						<Text as={'button'} display={'flex'} alignItems={'center'}>
							<Icon me="2" color={'gray.600'} as={GoSignOut} />

							<Text onClick={() => dispatch(logout())}>Sign Out</Text>
						</Text>
					</Stack>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	)
}

export default AdminHeaderUser
