import { combineReducers } from 'redux'
import authReducer from './auth/authReducer'
import businessReducer from './business/businessReducer'
import commonReducer from './common/commonReducer'

const rootReducer = combineReducers({
	auth: authReducer,
	business: businessReducer,
	common: commonReducer,
})

export default rootReducer
