import { Box, Container, Grid, GridItem, Heading, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { getRequest } from '../services/base.service'
import CityCard from './CityCard'

const TopLocations = () => {
	const [topLocations, setTopLocations] = useState([])

	useEffect(() => {
		getRequest(`top-location`, {
			params: { location_type: 'city', limit: 8 },
		}).then((res) => {
			setTopLocations(res.result)
		})
	}, [])

	return (
		<Box py={['50px', '70px', '80px', '80px']}>
			<Container maxW={'container.xl'}>
				<Box textAlign={'center'} mb={10}>
					<Heading fontSize={'2rem'} as={'h3'}>
						Worldwide Top Cities
					</Heading>
					<Text mt={'3'}>Lorem ipsum is a placeholder text commonly used</Text>
				</Box>

				<Grid
					templateColumns={[
						'repeat(2, 1fr)',
						'repeat(2, 1fr)',
						'repeat(4, 1fr)',
						'repeat(4, 1fr)',
					]}
					gap={[3, 4, 5, 6]}
				>
					{topLocations.map((location, index) => (
						<GridItem key={index}>
							<CityCard data={location} />
						</GridItem>
					))}
				</Grid>
			</Container>
		</Box>
	)
}

export default TopLocations
