import React, { useEffect, useState } from 'react'
import {
	Box,
	HStack,
	IconButton,
	Input,
	Select,
	Stack,
	Spinner,
	Center,
	Text,
	Alert,
	Icon,
	Collapse,
} from '@chakra-ui/react'
import {
	Pagination,
	usePagination,
	PaginationNext,
	PaginationPage,
	PaginationPrevious,
	PaginationContainer,
	PaginationPageGroup,
	PaginationSeparator,
} from '@ajna/pagination'
import AdminBlockCard from '../../components/AdminBlockCard'
import AdminBreadcrumb from '../../components/AdminBreadcrumb'
import { GoSearch } from 'react-icons/go'
import AdminClaimedBusinessCard from '../../components/AdminClaimedBusinessCard'
import { getRequest } from '../../services/base.service'
import { IoIosArrowBack } from 'react-icons/io'
import { IoIosArrowForward } from 'react-icons/io'
import { FiFilter } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { setAdminSidebarFalseOnUrlChange, setCurrentPageHeading } from '../../redux/common/commonActions'

const breadCrumb = [
	{ label: 'Dashboard', isCurrentPage: false, link: '/admin/dashboard' },
	{ label: 'Claimed List', isCurrentPage: true },
]
const ClaimedList = () => {
	const [claimList, setClaimList] = useState(null)
	const [showMobileActions, setShowMobileActions] = useState(false)
	const [spinner, setSpinner] = useState({ status: true })
	const [queryParams, setQueryParams] = useState(null)
	const [businessList, setBusinessList] = useState(null)
	const [paginationProperty, setPaginationProperty] = useState({
		total: 1,
		currentPage: 1,
		pageSize: 1,
	})
	const {
		pages,
		pagesCount,
		currentPage,
		setCurrentPage,
		isDisabled,
		setPageSize,
	} = usePagination({
		total: paginationProperty.total,
		limits: {
			outer: 2,
			inner: 2,
		},
		initialState: {
			pageSize: paginationProperty.pageSize,
			isDisabled: false,
			currentPage: 1,
		},
	})
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(setCurrentPageHeading('Claimed List'))
		dispatch(setAdminSidebarFalseOnUrlChange())
	}, [])
	useEffect(() => {
		fetchClaimList({ page: currentPage })
	}, [currentPage])

	const fetchClaimList = (params = {}) => {
		setSpinner({ status: true })
		getRequest('admin/claims', { params })
			.then((res) => {
				setClaimList(res.result?.data)
				setPaginationProperty({
					total: res.result.total,
					currentPage: res.result.current_page,
					pageSize: res.result.per_page,
				})
				setPageSize(res.result.per_page)
				setSpinner({ status: false })
			})
			.catch((err) => {
				console.log('ERR: ', err)
			})
	}
	const handlePageChange = (nextPage) => {
		setCurrentPage(nextPage)
	}

	const handleSearch = (e) => {
		e.preventDefault()
		setCurrentPage(1)
		fetchClaimList({ ...queryParams, page: 1 })
	}

	function handleChange(key, event) {
		setQueryParams({ ...queryParams, [key]: event.target.value })
		setSpinner({ status: true })
		setCurrentPage(1)
		fetchClaimList({ ...queryParams, [key]: event.target.value, page: 1 })
	}

	return (
		<>
			<AdminBreadcrumb items={breadCrumb} />
			<Box py={'7'}>
				<AdminBlockCard
					title={'Claimed List'}
					actions={
						<HStack>
							<Select
								display={['none', 'none', 'none', 'flex']}
								placeholder="Filter By"
								w={'120px'}
								size={'sm'}
								borderColor={'gray.300'}
								value={queryParams?.status}
								onChange={(e) => handleChange('status', e)}
							>
								<option value={'pending'}>Pending</option>
								<option value={'approved'}>Approved</option>
								<option value={'declined'}>Declined</option>
								<option value={'blocked'}>Blocked</option>
							</Select>
							<Select
								display={['none', 'none', 'none', 'flex']}
								placeholder="Sort By"
								w={'120px'}
								size={'sm'}
								borderColor={'gray.300'}
								value={queryParams?.sort}
								onChange={(e) => handleChange('sort', e)}
							>
								<option value={'asc'}>New</option>
								<option value={'desc'}>Older</option>
							</Select>
							<Input
								placeholder="Search..."
								w={['100%', '100%', '100%', '120px']}
								size={'sm'}
								borderColor={'gray.300'}
								onChange={(e) => {
									setQueryParams({ ...queryParams, keyword: e.target.value })
								}}
							/>
							<Box>
								<IconButton
									aria-label="Search"
									size={'sm'}
									icon={<GoSearch />}
									variant={'brand'}
									type="submit"
									onClick={handleSearch}
								/>
							</Box>
							<Icon
								as={FiFilter}
								display={[
									'inline-block',
									'inline-block',
									'inline-block',
									'none',
								]}
								onClick={() => {
									setShowMobileActions(!showMobileActions)
								}}
								_hover={{ cursor: 'pointer' }}
							/>
						</HStack>
					}
					mobileActions={
						<Collapse
							in={showMobileActions}
							unmountOnExit={true}
							animateOpacity
						>
							<HStack
								display={[
									`${showMobileActions ? 'flex' : 'none'}`,
									`${showMobileActions ? 'flex' : 'none'}`,
									`${showMobileActions ? 'flex' : 'none'}`,
									'none',
								]}
								transition={'2s'}
							>
								<Select
									placeholder="Filter By"
									// w={'120px'}
									size={'sm'}
									borderColor={'gray.300'}
									value={queryParams?.status}
									onChange={(e) => handleChange('status', e)}
								>
									<option value={'pending'}>Pending</option>
									<option value={'approved'}>Approved</option>
									<option value={'declined'}>Declined</option>
									<option value={'blocked'}>Blocked</option>
								</Select>
								<Select
									placeholder="Sort By"
									size={'sm'}
									borderColor={'gray.300'}
									value={queryParams?.sort}
									onChange={(e) => handleChange('sort', e)}
								>
									<option value={'desc'}>New</option>
									<option value={'asc'}>Older</option>
								</Select>
							</HStack>
						</Collapse>
					}
				>
					<Stack gap={2}>
						{spinner.status ? (
							<Center>
								<Spinner color="red.500" size="xl" speed="0.6s" />
							</Center>
						) : claimList.length > 0 ? (
							<>
								<Stack gap={2}>
									{claimList?.map((claim, index) => (
										<AdminClaimedBusinessCard
											key={index}
											companyName={claim?.name}
											data={claim}
										/>
									))}
								</Stack>
								<Pagination
									pagesCount={pagesCount}
									currentPage={currentPage}
									isDisabled={isDisabled}
									onPageChange={handlePageChange}
								>
									<PaginationContainer align="center" justify="center" p={4}>
										<PaginationPrevious
											w={7}
											h={7}
											_hover={{
												bg: 'gray.100',
											}}
											bg="white"
											color="gray.700"
											_disable={{ bg: 'white' }}
										>
											<Text>
												<IoIosArrowBack />
											</Text>
										</PaginationPrevious>
										<PaginationPageGroup
											isInline
											align="center"
											mx="1"
											separator={
												<PaginationSeparator
													fontSize="sm"
													w={7}
													jumpSize={11}
												/>
											}
										>
											{pages.map((page) => (
												<PaginationPage
													w={7}
													h={7}
													bg="gray.200"
													border={'1px'}
													borderColor={'gray.300'}
													key={`pagination_page_${page}`}
													page={page}
													color={'black.500'}
													fontSize="sm"
													_hover={{
														bg: 'brand.600',
														color: 'white',
													}}
													_current={{
														bg: 'brand.500',
														fontSize: 'sm',
														color: 'white',
														borderColor: 'brand.500',
													}}
												/>
											))}
										</PaginationPageGroup>
										<PaginationNext
											w={7}
											h={7}
											_hover={{
												bg: 'gray.100',
											}}
											bg="white"
											color="gray.700"
											_disable={{ bg: 'white' }}
										>
											<Text>
												<IoIosArrowForward />
											</Text>
										</PaginationNext>
									</PaginationContainer>
								</Pagination>
							</>
						) : (
							<Alert status="error">
								<Center h={'100%'} w={'100%'}>
									NO DATA FOUND.
								</Center>
							</Alert>
						)}
					</Stack>
				</AdminBlockCard>
			</Box>
		</>
	)
}

export default ClaimedList
