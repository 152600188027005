import {
	Button,
	Stack,
	// FormErrorMessage,
} from '@chakra-ui/react'
import { FaFacebook } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import { profileUpdate } from '../redux/auth/authActions'
import { useState } from 'react'
import firebase from 'firebase/compat/app'
import {
	auth as firebaseAuth,
	googleProvider,
	facebookAuth,
	facebookProvider,
} from '../services/firebase'
import { postRequest } from '../services/base.service'
import { useDispatch, useSelector } from 'react-redux'

const SocialAuthenticationCard = () => {
	const dispatch = useDispatch()

	const signWithGoogle = () => {
		firebaseAthentication(googleProvider, 'google')
	}
	const signWithFacebook = () => {
		firebaseAthentication(facebookProvider, 'facebook')
	}
	const firebaseAthentication = (provider, type) => {
		firebaseAuth.signInWithPopup(provider).then((result) => {
			firebase.auth().onAuthStateChanged((result) => {
				result?.getIdToken().then(function (idToken) {
					const postData = {
						name: result.displayName,
						email: result.email,
						firebase_uid: result.uid,
						token: idToken,
						login_type: type,
					}
					postRequest('firebase/login', postData)
						.then((res) => {
							console.log(res)
							dispatch(profileUpdate(res?.result))
						})
						.catch((error) => {})
				})
			})
		})
	}

	return (
		<Stack mt="6" gap={'3'}>
			<Button
				w={'full'}
				size={'lg'}
				colorScheme={'facebook'}
				onClick={signWithFacebook}
				leftIcon={<FaFacebook />}
			>
				Continue with Facebook
			</Button>

			<Button
				w={'full'}
				size={'lg'}
				variant={'outline'}
				onClick={signWithGoogle}
				leftIcon={<FcGoogle />}
			>
				Continue with Google
			</Button>
		</Stack>
	)
}

export default SocialAuthenticationCard
