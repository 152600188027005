import { Box, Flex, Heading, Text } from '@chakra-ui/react'

const AdminBlockCard = ({ children, title, shortDesc, actions, mobileActions }) => {
	return (
		<Box
			borderWidth={'1px'}
			borderStyle={'solid'}
			borderColor={'gray.300'}
			rounded={'8'}
		>
			<Flex justify={'space-between'} pt={5} px={5}>
				{(title || shortDesc) && (
					<Box>
						{title && (
							<Heading as="h3"  fontWeight={'700'} fontSize={'20px'}>
								{title}
							</Heading>
						)}
						{shortDesc && (
							<Text mt={1} color={'gray.600'}>
								{shortDesc}
							</Text>
						)}
					</Box>
				)}

				{actions && <Box>{actions}</Box>}
			</Flex>
			{mobileActions && <Box w={'100%'} p={5}>{mobileActions}</Box>}
			<Box p={5}>{children}</Box>
		</Box>
	)
}

export default AdminBlockCard
