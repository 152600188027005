import {
	Alert,
	AlertIcon,
	AlertTitle,
	AspectRatio,
	Box,
	Button,
	Checkbox,
	Container,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	Heading,
	Image,
	Input,
	ListItem,
	OrderedList,
	Stack,
	Text,
	Textarea,
} from '@chakra-ui/react'
import { FiUpload } from 'react-icons/fi'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getRequest, postRequest } from '../services/base.service'
import { serialize } from 'object-to-formdata'
import { useToast } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'

const ClaimYourBusiness = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [business, setBusiness] = useState(null)
	const [phone, setPhone] = useState(null)
	const [browsedFiles, setBrowsedFiles] = useState([])
	const [browsedFileNames, setBrowsedFileNames] = useState([])
	const [isSubmitting, setIsSubmitting] = useState(false)

	const navigate = useNavigate()

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()
	const toast = useToast()

	const params = useParams()

	useEffect(() => {
		const { slug } = params
		fetchBusinessDetails(slug)
	}, [params])

	const fetchBusinessDetails = (slug) => {
		getRequest(`business/details/${slug}`)
			.then((res) => {
				setBusiness(res.result)
				setIsLoading(false)
			})
			.catch((error) => {
				setIsLoading(false)
				console.log(error)
			})
	}

	const handelBrowseFile = (e) => {
		if (e.target.files.length > 0) {
			const browsedFileNamesArr = []
			setBrowsedFiles(e.target.files)
			Array.from(e.target.files).forEach((file) => {
				browsedFileNamesArr.push(file.name)
			})
			setBrowsedFileNames(browsedFileNames.concat(browsedFileNamesArr))
		}
	}

	const removeFile = (index) => {
		const browsedFilesCopy = [...browsedFiles]
		const browsedFileNamesCopy = [...browsedFileNames]
		browsedFilesCopy.splice(index, 1)
		browsedFileNamesCopy.splice(index, 1)
		setBrowsedFiles(browsedFilesCopy)
		setBrowsedFileNames(browsedFileNamesCopy)
	}

	const objectToFormData = (data) => {
		const object = data
		const formData = serialize(object, { indices: true })
		return formData
	}
	const handleSubmitData = (data) => {
		setIsSubmitting(true)
		postRequest(
			'business/claim/request',
			objectToFormData({
				...data,
				business_id: business.id,
				documents: browsedFiles,
			})
		)
			.then((res) => {
				setIsSubmitting(false)
				toast({
					title: 'Successfully Submitted',
					status: 'success',
					duration: 3000,
					isClosable: true,
				})
				if (res) {
					navigate('/')
				}
			})
			.catch((err) => {
				setIsSubmitting(false)
				console.log('ERR: ', err)
				toast({
					title: 'Something Went Wrong!',
					status: 'error',
					duration: 3000,
					isClosable: true,
				})
			})
	}

	return (
		<Box>
			{business && !isLoading && (
				<Container py={5} maxW={'container.xl'}>
					<Box mb={'8'}>
						<Heading as="h3" fontSize={'2rem'}>
							Claim Your Business
						</Heading>
						<Text mt={'3'}>
							Use he following form to claim the business. Make sure you upload
							relevant and genuine document to assist with the swift approval
							process. Our associates may try to reach on phone or through email
							or further queries. Please provide valid contact details.
						</Text>
					</Box>

					<Box pt={5}>
						<Flex
							flexDir={['column', 'column', 'row', 'row']}
							alignItems={['center', 'center', 'initial', 'initial']}
						>
							<AspectRatio
								w={'200px'}
								h={'200px'}
								ratio={1 / 1}
								bg={'gray.100'}
							>
								<Image
									w="100%"
									h="100%"
									objectFit={'contain'}
									p={3}
									src={business.logo}
									alt=""
								/>
							</AspectRatio>

							<Box
								w={['100%', '100%', 'calc(100% - 200px)', 'calc(100% - 200px)']}
								ps={[0, 0, 6, 6]}
								mt={[3, 3, 0, 0]}
							>
								<Heading
									as={'h2'}
									fontSize={'1.5rem'}
									textAlign={['center', 'center', 'left', 'left']}
								>
									{business.name}
								</Heading>

								<Stack mt="4">
									<Flex justify={['center', 'center', 'initial', 'initial']}>
										<Text fontWeight={'500'}>Website: </Text>
										<Text ml={'3'} as={'a'} href={business.website}>
											{business.website}
										</Text>
									</Flex>

									<Flex justify={['center', 'center', 'initial', 'initial']}>
										<Text fontWeight={'500'}>Phone: </Text>
										<Text ml={'3'}>{business.phone}</Text>
									</Flex>

									<Flex justify={['center', 'center', 'initial', 'initial']}>
										<Text fontWeight={'500'}>WhatsApp: </Text>
										<Text ml={'3'}>{business.whatsapp_no}</Text>
									</Flex>

									<Flex justify={['center', 'center', 'initial', 'initial']}>
										<Text fontWeight={'500'}>Email: </Text>
										<Text ml={'3'}>{business.email}</Text>
									</Flex>
								</Stack>
							</Box>
						</Flex>

						<Box as="form" onSubmit={handleSubmit(handleSubmitData)} pt={8}>
							<Grid
								templateRows="repeat(1, 1fr)"
								templateColumns={[
									'repeat(1, 1fr)',
									'repeat(1, 1fr)',
									'repeat(7, 1fr)',
									'repeat(7, 1fr)',
								]}
								gap={6}
							>
								<GridItem colSpan={[1, 1, 4, 4]}>
									<Stack spacing={'5'}>
										<FormControl>
											<FormLabel>Email</FormLabel>
											<Input
												name={'email'}
												type={'email'}
												size={'lg'}
												borderColor={'gray.400'}
												placeholder={'Enter business email'}
												{...register('email', { required: true })}
											/>
											<Text color="red">
												{errors.email?.type === 'required' &&
													'Email is required'}
											</Text>
											{/* {errors.email?.message && (
												<Text color="red">{errors.email?.message}</Text>
											)} */}
										</FormControl>

										<FormControl>
											<FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
											<Input
												name="phone"
												// international
												as={PhoneInput}
												value={phone}
												defaultCountry={'US'}
												_focus={{ outline: 'none' }}
												placeholder="Enter phone number"
												size={'lg'}
												onChange={(phoneNumber) => setPhone(phoneNumber)}
												borderColor={'gray.300'}
												{...register('phone', { required: true })}
											/>
											<Text color="red">
												{errors.phone?.type === 'required' &&
													'Phone number is required'}
											</Text>
										</FormControl>

										<FormControl>
											<FormLabel>WhatsApp</FormLabel>
											<Input
												type={'text'}
												size={'lg'}
												borderColor={'gray.400'}
												placeholder={'Enter WhatsApp number'}
												{...register('whatsapp_no', { required: true })}
											/>
											<Text color="red">
												{errors.whatsapp_no?.type === 'required' &&
													'WhatsApp is required'}
											</Text>
										</FormControl>

										<FormControl>
											<FormLabel>Claim Note (Minimum word 150)</FormLabel>
											<Textarea
												rows={4}
												borderColor={'gray.400'}
												placeholder={'Enter Claim Note...'}
												{...register('claim_note', { required: true })}
											/>
											<Text color="red">
												{errors.claim_note?.type === 'required' &&
													'Claim Note  is required'}
											</Text>
										</FormControl>
									</Stack>
								</GridItem>

								<GridItem colSpan={[1, 1, 3, 3]}>
									<Stack spacing={5}>
										<FormControl>
											<FormLabel>Upload Document</FormLabel>
											<OrderedList spacing={1} mb={'3'}>
												{browsedFiles &&
													browsedFileNames.length > 0 &&
													browsedFileNames.map((file, index) => (
														<ListItem
															key={index}
															_hover={{
																'& > .btnRM': {
																	display: 'inline-block',
																},
															}}
															justifyContent={'space-between'}
														>
															{file}
															<Text
																className={'btnRM'}
																as={'button'}
																type={'button'}
																h={'20px'}
																bg={'red.100'}
																display={'none'}
																lineHeight={'20px'}
																w={'20px'}
																ms={3}
																onClick={() => removeFile(index)}
															>
																&times;
															</Text>
														</ListItem>
													))}
											</OrderedList>
											<Button
												leftIcon={<FiUpload />}
												as={'label'}
												htmlFor={'file'}
												variant={'outline'}
												colorScheme={'blue'}
												size={'lg'}
												w={'200px'}
											>
												<Input
													type={'file'}
													multiple
													id={'file'}
													zIndex={'-99999'}
													opacity={'0'}
													p={0}
													w={0}
													h={0}
													isRequired
													onChange={handelBrowseFile}
												/>
												Browse File
											</Button>
										</FormControl>
									</Stack>
								</GridItem>
							</Grid>

							<Stack spacing={3} mt={4}>
								<Checkbox
									{...register('is_valid_info', { required: true })}
									value="1"
									colorScheme={'red'}
								>
									I agree the information provided as accuse to the best of my
									knowledge.
								</Checkbox>

								<Checkbox
									{...register('is_agree_terms_condition', {
										required: true,
									})}
									value="1"
									colorScheme={'red'}
								>
									I agree to RentMy Directory Business Terms and acknowledge our
									Privacy Policy. I agree to receive emails or calls about the
									claim
								</Checkbox>
								{(errors.is_agree_terms_condition?.type === 'required' ||
									errors.is_valid_info?.type === 'required') && (
									<Alert status="error">
										<AlertIcon />
										<AlertTitle mr={2}>This field is required</AlertTitle>
									</Alert>
								)}
							</Stack>

							<Button
								isLoading={isSubmitting}
								loadingText="Submitting..."
								type="submit"
								mt={5}
								variant={'brand'}
								size={'lg'}
							>
								Submit
							</Button>
						</Box>
					</Box>
				</Container>
			)}
		</Box>
	)
}

export default ClaimYourBusiness
