import React, { useEffect, useState } from 'react'
import {
	Box,
	Stack,
	Input,
	HStack,
	IconButton,
	Spinner,
	Center,
	Text,
	Alert,
	Table,
	TableCaption,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	Button,
	useToast,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
} from '@chakra-ui/react'
import {
	Pagination,
	usePagination,
	PaginationNext,
	PaginationPage,
	PaginationPrevious,
	PaginationContainer,
	PaginationPageGroup,
	PaginationSeparator,
} from '@ajna/pagination'
import AdminBlockCard from '../../components/AdminBlockCard'
import AdminBreadcrumb from '../../components/AdminBreadcrumb'
import AdminBusinessCard from '../../components/AdminBusinessCard'
import { GoSearch } from 'react-icons/go'
import { deleteRequest, getRequest } from '../../services/base.service'
import { IoIosArrowBack } from 'react-icons/io'
import { IoIosArrowForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setAdminSidebarFalseOnUrlChange, setCurrentPageHeading } from '../../redux/common/commonActions'

const breadCrumb = [
	{ label: 'Dashboard', isCurrentPage: false, link: '/admin/dashboard' },
	{ label: 'Location', isCurrentPage: true, link: '/admin/location' },
	{ label: 'Country', isCurrentPage: true },
]

const LocationCountry = () => {
	const navigate = useNavigate()
	const [paginationProperty, setPaginationProperty] = useState({
		total: 1,
		currentPage: 1,
		pageSize: 1,
	})
	const [spinner, setSpinner] = useState({ status: true })
	const [queryParams, setQueryParams] = useState({ keyword: '' })
	const [countryList, setCountryList] = useState([])
	const {
		pages,
		pagesCount,
		currentPage,
		setCurrentPage,
		isDisabled,
		setPageSize,
	} = usePagination({
		total: paginationProperty.total,
		limits: {
			outer: 2,
			inner: 2,
		},
		initialState: {
			pageSize: paginationProperty.pageSize,
			isDisabled: false,
			currentPage: 1,
		},
	})
	const [isLoading, setIsLoading] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [countryId, setCountryId] = useState(null)
	const cancelRef = React.useRef()
	const toast = useToast()
	const dispatch = useDispatch()

	const onClose = () => {
		setIsOpen(false)
		setCountryId(null)
	}

	useEffect(() => {
		dispatch(setCurrentPageHeading('Country List'))
		dispatch(setAdminSidebarFalseOnUrlChange())
	}, [])
	
	useEffect(() => {
		fetchCountryList({ page: currentPage })
	}, [currentPage])

	const fetchCountryList = (params = {}) => {
		setSpinner({ status: true })
		getRequest('admin/countries', { params })
			.then((res) => {
				setCountryList(res.result?.data)
				setPaginationProperty({
					total: res.result.total,
					currentPage: res.result.current_page,
					pageSize: res.result.per_page,
				})
				setPageSize(res.result.per_page)
				setSpinner({ status: false })
			})
			.catch((err) => {
				console.log('ERR: ', err)
				setSpinner({ status: false })
			})
	}

	const handlePageChange = (nextPage) => {
		setCurrentPage(nextPage)
	}

	const handleSearch = (e) => {
		e.preventDefault()
		setCurrentPage(1)
		fetchCountryList({ ...queryParams, page: 1 })
	}

	const handleDelete = () => {
		setIsLoading(true)
		deleteRequest(`admin/countries/${countryId}`)
			.then((res) => {
				setCountryId(null)
				setIsLoading(false)
				toast({
					title: 'Successfully Deleted.',
					status: 'success',
					duration: 5000,
					isClosable: true,
				})
				onClose()
				fetchCountryList({ page: currentPage })
			})
			.catch((err) => {
				setCountryId(null)
				setIsLoading(false)
				toast({
					title: 'Something Went Wrong.',
					status: 'error',
					duration: 5000,
					isClosable: true,
				})
				console.log('ERR: ', err)
				onClose()
			})
	}

	return (
		<>
			<AdminBreadcrumb items={breadCrumb} />

			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete Country
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<Button
								isLoading={isLoading}
								loadingText={'DELETING...'}
								colorScheme="red"
								onClick={handleDelete}
								ml={3}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			<Box py={'7'}>
				<AdminBlockCard
					title={'Country List'}
					actions={
						<HStack>
							<HStack as={'form'}>
								<Input
									placeholder="Search..."
									w={'150px'}
									size={'sm'}
									borderColor={'gray.300'}
									onChange={(e) => {
										setQueryParams({ keyword: e.target.value })
									}}
								/>

								<Box>
									<IconButton
										aria-label="Search"
										size={'sm'}
										icon={<GoSearch />}
										variant={'brand'}
										type="submit"
										onClick={handleSearch}
									/>
								</Box>
							</HStack>
						</HStack>
					}
				>
					{spinner.status ? (
						<Center>
							<Spinner color="red.500" size="xl" speed="0.6s" />
						</Center>
					) : countryList.length > 0 ? (
						<>
							<Stack gap={2} overflowX={'scroll'}>
								<Table
									variant="blackAlpha"
									colorScheme={'whiteAlpha'}
									w={'100%'}
								>
									{/* <TableCaption>
										Imperial to metric conversion factors
									</TableCaption> */}
									<Thead>
										<Tr>
											<Th>#</Th>
											<Th>Country</Th>
											<Th isNumeric>Quantity Business</Th>
											<Th textAlign="right">Actions</Th>
										</Tr>
									</Thead>
									<Tbody>
										{countryList.map((country, index) => (
											<Tr key={country.id}>
												<Td>{25 * (currentPage - 1) + (index + 1)}</Td>
												<Td>{country.name}</Td>
												<Td isNumeric>{country.businesses_count}</Td>
												<Td>
													<HStack
														textAlign="right"
														align="right"
														justify="right"
													>
														<Button
															size="sm"
															colorScheme="teal"
															variant="outline"
															onClick={() => {
																navigate(
																	`/admin/location/edit/${'country'}/${
																		country.id
																	}`
																)
															}}
														>
															EDIT
														</Button>
														<Button
															size="sm"
															colorScheme="pink"
															variant="outline"
															onClick={() => {
																setCountryId(country.id)
																setIsOpen(true)
															}}
														>
															DELETE
														</Button>
													</HStack>
												</Td>
											</Tr>
										))}
									</Tbody>
									{/* <Tfoot>
										<Tr>
											<Th>To convert</Th>
											<Th>into</Th>
											<Th isNumeric>multiply by</Th>
										</Tr>
									</Tfoot> */}
								</Table>
							</Stack>
							<Pagination
								pagesCount={pagesCount}
								currentPage={currentPage}
								isDisabled={isDisabled}
								onPageChange={handlePageChange}
							>
								<PaginationContainer align="center" justify="center" p={4}>
									<PaginationPrevious
										w={7}
										h={7}
										_hover={{
											bg: 'gray.100',
										}}
										bg="white"
										color="gray.700"
										_disable={{ bg: 'white' }}
									>
										<Text>
											<IoIosArrowBack />
										</Text>
									</PaginationPrevious>
									<PaginationPageGroup
										isInline
										align={'center'}
										mx={'1'}
										separator={
											<PaginationSeparator fontSize="sm" w={7} jumpSize={11} />
										}
									>
										{pages.map((page) => (
											<PaginationPage
												w={7}
												h={7}
												bg={'gray.200'}
												border={'1px'}
												borderColor={'gray.300'}
												key={`pagination_page_${page}`}
												page={page}
												color={'black.500'}
												fontSize={'sm'}
												_hover={{
													bg: 'brand.600',
													color: 'white',
												}}
												_current={{
													bg: 'brand.500',
													fontSize: 'sm',
													color: 'white',
													borderColor: 'brand.500',
												}}
											/>
										))}
									</PaginationPageGroup>
									<PaginationNext
										w={7}
										h={7}
										_hover={{
											bg: 'gray.100',
										}}
										bg="white"
										color="gray.700"
										_disable={{ bg: 'white' }}
									>
										<Text>
											<IoIosArrowForward />
										</Text>
									</PaginationNext>
								</PaginationContainer>
							</Pagination>
						</>
					) : (
						<Alert status="error">
							<Center h={'100%'} w={'100%'}>
								NO DATA FOUND.
							</Center>
						</Alert>
					)}
				</AdminBlockCard>
			</Box>
		</>
	)
}

export default LocationCountry
