import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

const AuthRequired = ({ children, signPath, isAdmin }) => {
	let location = useLocation()
	const auth = useSelector((state) => state.auth)

	if (!auth.user) {
		if (!isAdmin)
			return <Navigate to={signPath || '/signin'} state={{ from: location }} />
		return (
			<Navigate to={signPath || '/admin/signin'} state={{ from: location }} />
		)
	}

	return children
}

export default AuthRequired
