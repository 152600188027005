import {
	BrowserRouter as Router,
	Navigate,
	Route,
	Routes,
} from 'react-router-dom'
import AdminLayout from '../layouts/admin/AdminLayout'
import AdminDashboard from '../pages/admin/Dashboard'
import AppHome from '../pages/AppHome'
import AuthSignin from '../pages/auth/AuthSignin'
import AuthRegister from '../pages/auth/AuthRegister'
import NotFound from '../pages/errors/NotFound'
import AppLayout from '../layouts/AppLayout'
import AuthAdminSignin from '../pages/auth/AuthAdminSignin'
import BusinessList from '../pages/admin/BusinessList'
import ClaimedList from '../pages/admin/ClaimedList'
import Search from '../pages/Search'
import BusinessDetails from '../pages/BusinessDetails'
import Business from '../pages/Business'
import AuthRequired from './AuthRequired'
import ClaimYourBusiness from '../pages/ClaimYourBusiness'
import AdminBusinessDetails from '../pages/admin/AdminBusinessDetails'
import AdminClaimedRequests from '../pages/admin/AdminClaimedRequests'
import CreateNewBusiness from '../pages/admin/CreateNewBusiness'
import EditBusiness from '../pages/admin/EditBusiness'
import LocationCountry from '../pages/admin/LocationCountry'
import LocationState from '../pages/admin/LocationState'
import LocationCity from '../pages/admin/LocationCity'
import EditLocation from '../pages/admin/EditLocation'
import AddLocation from '../pages/admin/AddLocation'
import LocationDetails from '../pages/LocationDetails'
import UserProfile from '../pages/UserProfile'
import EditProfile from '../pages/EditProfile'
import ChangePassword from '../pages/ChangePassword'
import AuthForgetPassword from '../pages/auth/AuthForgetPassword'
import AuthResetPassword from '../pages/auth/AuthResetPassword'

const AppRouter = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<AppLayout />}>
					<Route index element={<AppHome />} />
					<Route path={':type/:slug'} element={<LocationDetails />} />
					<Route path="search" element={<Search />} />

					<Route
						path="business"
						element={
							<AuthRequired>
								<Business />
							</AuthRequired>
						}
					/>

					<Route
						path="claim-your-business/:slug"
						element={
							<AuthRequired>
								<ClaimYourBusiness />
							</AuthRequired>
						}
					/>
					<Route
						path="business/:slug"
						element={<BusinessDetails />}
					/>

					<Route
						path="/profile"
						element={
							<AuthRequired>
								<UserProfile />
							</AuthRequired>
						}
					>
						<Route
							index
							element={
								<Navigate to={'/profile/edit-profile'} />
							}
						/>

						<Route
							path="edit-profile"
							element={<EditProfile />}
						/>
						<Route
							path="change-password"
							element={<ChangePassword />}
						/>
					</Route>
				</Route>

				{/* Admin */}
				<Route
					path="/admin"
					element={
						<AuthRequired isAdmin signPath={'/admin/signin'}>
							<AdminLayout />
						</AuthRequired>
					}
				>
					<Route
						index
						element={<Navigate to={'/admin/dashboard'} />}
					/>
					<Route path="dashboard" element={<AdminDashboard />} />
					<Route path="business-list" element={<BusinessList />} />
					<Route
						path="business/create"
						element={<CreateNewBusiness />}
					/>
					<Route
						path="business/edit/:id"
						element={<EditBusiness />}
					/>
					<Route
						path="business-list/details/:id"
						element={<AdminBusinessDetails />}
					/>
					<Route path="claimed-list" element={<ClaimedList />} />
					<Route
						path="claimed-list/details/:id"
						element={<AdminBusinessDetails usedAs={'claim'} />}
					/>

					<Route
						path="claimed-list/details/:id/requests"
						element={<AdminClaimedRequests />}
					/>

					<Route
						path="location"
						element={<Navigate to={'/admin/location/countries'} />}
					/>
					<Route path="location/add" element={<AddLocation />} />
					<Route
						path="location/countries"
						element={<LocationCountry />}
					/>
					<Route path="location/states" element={<LocationState />} />
					<Route path="location/cities" element={<LocationCity />} />
					<Route
						path="location/edit/:locationType/:id"
						element={<EditLocation />}
					/>
				</Route>

				{/* Auth */}
				<Route path="/admin/signin" element={<AuthAdminSignin />} />
				<Route path="/signin" element={<AuthSignin />} />
				<Route path="/register" element={<AuthRegister />} />
				<Route path="/forget-password" element={<AuthForgetPassword />} />
				<Route path="/reset-password" element={<AuthResetPassword />} />

				<Route path="*" element={<NotFound />} />
			</Routes>
		</Router>
	)
}

export default AppRouter
