import {
	Box,
	Button,
	Heading,
	Stack,
	Text,
	FormControl,
	// FormLabel,
	// FormErrorMessage,
	// FormHelperText,
	Select,
	SimpleGrid,
	Input,
	Icon,
	InputGroup,
	InputRightElement,
	// FormErrorMessage,
} from '@chakra-ui/react'
import { Link, Navigate } from 'react-router-dom'
import LabelDivider from '../../components/LabelDivider'
import AuthLayout from '../../layouts/AuthLayout'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { userRegister } from '../../redux/auth/authActions'
import SocialAuthenticationCard from '../../components/SocialAuthenticationCard'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { useState } from 'react'
import { BiShow, BiHide } from 'react-icons/bi'

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]

const AuthRegister = () => {
	const dispatch = useDispatch()
	const { user } = useSelector((status) => status.auth)
	const { loading } = useSelector((status) => status.auth)
	const { regErrors } = useSelector((status) => status.auth)
	const [showPass, setShowPass] = useState(false)
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()

	const handleRegistration = (data) => {
		let birthday = `${data.year}-${data.month}-${data.day}`
		dispatch(
			userRegister(
				{
					name: data.name,
					email: data.email,
					password: data.password,
					zipcode: data.zipcode,
					birthday,
				},
				(msg, status) => {}
			)
		)
	}

	if (user) {
		return <Navigate to="/" />
	}

	return (
		<AuthLayout>
			<Box mx={'auto'} maxW={'420px'}>
				<Text
					as={Link}
					to={'/'}
					mb={'3'}
					fontWeight={500}
					color={'gray.500'}
					display={'flex'}
					alignItems={'center'}
					textTransform={'uppercase'}
				>
					<Icon as={FaLongArrowAltLeft} me={3} /> Back to home
				</Text>

				<Heading as="h3" fontSize={'28px'}>
					Create New Account
				</Heading>
				<Text mt="2">It's free to signup and only takes a minute.</Text>

				<SocialAuthenticationCard />

				<LabelDivider mt={'5'} mb={'5'} text={'or'} />
				{/* {regErrors?.email && (
					<Alert mt="3" rounded="md" status="warning">
						{regErrors?.email[0]}
					</Alert>
				)} */}

				<Stack gap={'3'} as="form" onSubmit={handleSubmit(handleRegistration)}>
					<FormControl>
						{/* <FormLabel htmlFor="name">Name</FormLabel> */}
						<Input
							size={'lg'}
							id="name"
							type="text"
							placeholder="Full name"
							isInvalid={errors.name?.type === 'required' || regErrors?.name}
							{...register('name', { required: true })}
						/>
						<Text fontSize="sm" color="red.500">
							{errors.name?.type === 'required' && 'Name is required'}
						</Text>
						{regErrors?.name && (
							<Text fontSize="sm" mt="2" color="red.500">
								{regErrors?.name[0]}
							</Text>
						)}
					</FormControl>

					<FormControl>
						{/* <FormLabel htmlFor="email">Email address</FormLabel> */}
						<Input
							size={'lg'}
							id="email"
							type="email"
							placeholder="Email address"
							isInvalid={errors.email?.type === 'required' || regErrors?.email}
							{...register('email', { required: true })}
						/>
						<Text fontSize="sm" color="red.500">
							{errors.email?.type === 'required' && 'Email is required'}
						</Text>
						{regErrors?.email && (
							<Text fontSize="sm" mt="2" color="red.500">
								{regErrors?.email[0]}
							</Text>
						)}
						{/* <FormHelperText>We'll never share your email.</FormHelperText> */}
						{/* <FormErrorMessage>Hello world!</FormErrorMessage> */}
					</FormControl>
					<FormControl>
						{/* <FormLabel htmlFor="password">Password</FormLabel> */}
						<InputGroup size={'lg'}>
							<Input
								size={'lg'}
								id="password"
								type={showPass ? 'text' : 'password'}
								placeholder="Enter your password"
								isInvalid={
									errors.password?.type === 'required' || regErrors?.password
								}
								{...register('password', { required: true })}
							/>
							<InputRightElement>
								<Icon
									_hover={{ cursor: 'pointer' }}
									as={showPass ? BiHide : BiShow}
									onClick={() => setShowPass(!showPass)}
								/>
							</InputRightElement>
						</InputGroup>
						<Text fontSize="sm" color="red.500">
							{errors.password?.type === 'required' && 'Password is required'}
						</Text>
						{regErrors?.password && (
							<Text fontSize="sm" mt="2" color="red.500">
								{regErrors?.password[0]}
							</Text>
						)}
					</FormControl>

					<FormControl>
						{/* <FormLabel htmlFor="text">Zip Code</FormLabel> */}
						<Input
							size={'lg'}
							id="zipCode"
							type="text"
							placeholder="Zip code"
							isInvalid={
								errors.zipcode?.type === 'required' || regErrors?.zipcode
							}
							{...register('zipcode', { required: true })}
						/>
						<Text fontSize="sm" color="red.500">
							{errors.zipcode?.type === 'required' && 'Zipcode is required'}
						</Text>
						{regErrors?.zipcode && (
							<Text fontSize="sm" mt="2" color="red.500">
								{regErrors?.zipcode[0]}
							</Text>
						)}
					</FormControl>

					<SimpleGrid columns={3} spacing={4}>
						<Box>
							<Select
								size={'lg'}
								placeholder="Day"
								isInvalid={errors.day?.type === 'required'}
								{...register('day', { required: true })}
							>
								{[...Array(31).keys()].map((date, index) => (
									<option key={date + 1} value={date + 1}>
										{date + 1}
									</option>
								))}
							</Select>
							<Text fontSize="sm" color="red.500">
								{errors.day?.type === 'required' && 'Day is required'}
							</Text>
						</Box>
						<Box>
							<Select
								size={'lg'}
								placeholder="Month"
								isInvalid={errors.month?.type === 'required'}
								{...register('month', { required: true })}
							>
								{[...Array(12).keys()].map((month) => (
									<option key={month + 1} value={month + 1}>
										{months[month]}
									</option>
								))}
							</Select>
							<Text fontSize="sm" color="red.500">
								{errors.month?.type === 'required' && 'Month is required'}
							</Text>
						</Box>
						<Box>
							<Select
								size={'lg'}
								placeholder="Year"
								isInvalid={errors.year?.type === 'required'}
								{...register('year', { required: true })}
							>
								{Array.from(
									{ length: 120 },
									(_, i) => new Date().getFullYear() - 120 + i
								)
									.reverse()
									.map((year) => (
										<option key={year} value={year}>
											{year}
										</option>
									))}
							</Select>
							<Text fontSize="sm" color="red.500">
								{errors.year?.type === 'required' && 'Year is required'}
							</Text>
						</Box>
					</SimpleGrid>
					{regErrors?.birthday && (
						<Text fontSize="sm" mt="2" mb="2" color="red.500">
							{regErrors?.birthday[0]}
						</Text>
					)}
					<Text fontSize={'sm'} as="em" color={'gray.500'}>
						By proceeding, you agree to trems of Services and Police of the
						RentMy platform
					</Text>

					<Button
						w={'full'}
						size={'lg'}
						variant={'brand'}
						type="submit"
						isLoading={loading}
						loadingText="Submitting..."
					>
						Register
					</Button>
					{/* <Button as={Link} w={'full'} size={'lg'} variant={'brand'} to="/">
						Register
					</Button> */}

					<Text>
						Already have an account.{' '}
						<Text as={Link} color={'brand.500'} to="/signin">
							Sign In
						</Text>
					</Text>
				</Stack>
			</Box>
		</AuthLayout>
	)
}

export default AuthRegister
