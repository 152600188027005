import { getRequest } from '../../services/base.service'
import { BUSINESS_SEARCH_REQUEST, BUSINESS_SEARCH_SUCCESS } from './actionTypes'

export const fetchBusinessList = (params, callback = () => null) => {
	return (dispatch) => {
		dispatch(businessSearchRequest())
		getRequest('businesses', { params })
			.then((res) => {
				dispatch(businessSearchSuccess(res.result))
				callback(true)
			})
			.catch((error) => {
				dispatch(businessSearchFailed(error))
				callback(false)
			})
	}
}

const businessSearchRequest = () => {
	return {
		type: BUSINESS_SEARCH_REQUEST,
	}
}

const businessSearchSuccess = (payload) => {
	return {
		type: BUSINESS_SEARCH_SUCCESS,
		payload,
	}
}

const businessSearchFailed = (errors) => {
	return {
		type: BUSINESS_SEARCH_SUCCESS,
		errors,
	}
}
