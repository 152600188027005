import {
	IconButton,
	Box,
	Container,
	Flex,
	HStack,
	Image,
	Text,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerBody,
	Button,
	useDisclosure,
	DrawerFooter,
	Stack,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Avatar,
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { logout } from '../redux/auth/authActions'
import {
	FiBriefcase,
	FiHome,
	FiLogIn,
	FiLogOut,
	FiMenu,
	FiPlusCircle,
	FiUserPlus,
} from 'react-icons/fi'
import { CgProfile } from 'react-icons/cg'
import { useRef } from 'react'

const AppHeader = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const btnRef = useRef()

	const navigate = useNavigate()

	const dispatch = useDispatch()
	const { user } = useSelector((state) => state.auth)

	return (
		<>
			<Box pos={'relative'} zIndex={9999}>
				<Drawer
					isOpen={isOpen}
					placement="left"
					onClose={onClose}
					finalFocusRef={btnRef}
				>
					<DrawerOverlay />
					<DrawerContent bg={'brand.500'}>
						<DrawerCloseButton
							color={'brand.100'}
							_hover={{ color: 'brand.50' }}
						/>
						<DrawerHeader
							fontSize={'1rem'}
							fontWeight={'500'}
							color={'brand.200'}
							textTransform={'uppercase'}
						>
							Navigation
						</DrawerHeader>

						<DrawerBody>
							<Stack
								gap={2}
								fontSize={'1.2rem'}
								color={'brand.50'}
								fontWeight={500}
							>
								<Text
									as={Link}
									_hover={{ textDecoration: 'underline' }}
									to={'/'}
								>
									Home
								</Text>

								{!user ? (
									<>
										<Text
											as={Link}
											_hover={{
												textDecoration: 'underline',
											}}
											to={'/signin'}
										>
											Sign In
										</Text>
										<Text
											as={Link}
											_hover={{
												textDecoration: 'underline',
											}}
											to={'/register'}
										>
											Sign Up
										</Text>
									</>
								) : (
									<>
										{user.role !== 'admin' ? (
											<Text
												as={Link}
												_hover={{
													textDecoration: 'underline',
												}}
												to={'/business'}
											>
												My Business
											</Text>
										) : (
											<Text
												as={Link}
												_hover={{
													textDecoration: 'underline',
												}}
												to={'/admin/dashboard'}
											>
												Go to Dashboard
											</Text>
										)}

										<Text
											textAlign={'left'}
											as={'button'}
											onClick={() => {
												dispatch(
													logout((msg, status) => {
														return <Navigate to={'/signin'} />
													})
												)
											}}
											fontWeight={500}
											_hover={{
												textDecoration: 'underline',
											}}
										>
											Sign Out
										</Text>
									</>
								)}
							</Stack>
						</DrawerBody>
						<DrawerFooter justifyContent={'center'} color={'brand.100'}>
							© 2022 RBD.
						</DrawerFooter>
					</DrawerContent>
				</Drawer>
			</Box>

			<Box
				bg={'brand.500'}
				pos={'fixed'}
				w={'100%'}
				top={'0'}
				left={'0'}
				zIndex={'999'}
			>
				<Container maxW={'100%'}>
					<Flex h={'70px'} justify={'space-between'} alignItems={'center'}>
						<Box as={Link} to={'/'}>
							<Image
								bg={'brand.500'}
								maxW={'96px'}
								src={
									'https://rentmy.co/wp-content/uploads/2019/11/logo-white.png'
								}
							/>
						</Box>
						<Box>
							<IconButton
								display={['flex', 'flex', 'none', 'none']}
								rounded={'full'}
								ref={btnRef}
								color={'brand.50'}
								bg={'transparent'}
								fontSize={'2rem'}
								onClick={onOpen}
								_focus={{
									outline: 'none',
									boxShadow: 'none',
								}}
								_active={{
									outline: 'none',
									boxShadow: 'none',
								}}
								colorScheme={'red'}
								icon={<FiMenu />}
							/>

							<HStack
								gap={2}
								display={['none', 'none', 'flex', 'flex']}
								color={'brand.50'}
								_hover={{
									color: 'white',
								}}
								fontWeight={500}
							>
								<Button
									fontSize={'1.1rem'}
									bg={'transparent'}
									_focus={{ bg: 'transparent' }}
									_active={{ bg: 'transparent' }}
									leftIcon={<FiHome />}
									as={Link}
									_hover={{ textDecoration: 'underline' }}
									to={'/'}
								>
									Home
								</Button>

								{!user ? (
									<>
										{/* <Text
										as={Link}
										_hover={{ textDecoration: 'underline' }}
										to={'/signin'}
									>
										Sing In
									</Text> */}
										<Button
											bg={'transparent'}
											_focus={{ bg: 'transparent' }}
											_active={{ bg: 'transparent' }}
											fontSize={'1.1rem'}
											as={Link}
											leftIcon={<FiPlusCircle />}
											_hover={{
												textDecoration: 'underline',
											}}
											to={'/business'}
										>
											Add New Business
										</Button>
									</>
								) : (
									<>
										{user.role !== 'admin' ? (
											<Button
												bg={'transparent'}
												_focus={{ bg: 'transparent' }}
												_active={{ bg: 'transparent' }}
												fontSize={'1.1rem'}
												as={Link}
												leftIcon={<FiBriefcase />}
												_hover={{
													textDecoration: 'underline',
												}}
												to={'/business'}
											>
												My Business
											</Button>
										) : (
											<Button
												bg={'transparent'}
												_focus={{ bg: 'transparent' }}
												_active={{ bg: 'transparent' }}
												fontSize={'1.1rem'}
												leftIcon={<FiBriefcase />}
												as={Link}
												_hover={{
													textDecoration: 'underline',
												}}
												to={'/admin/dashboard'}
											>
												Go to Dashboard
											</Button>
										)}
									</>
								)}
								<Menu>
									<MenuButton
										rounded={'full'}
										as={Button}
										variant={'outline'}
										colorScheme={'white'}
										aria-label="Options"
										fontSize={'1.1rem'}
										placement={'bottom-left'}
										ps={'1'}
										pe={'3'}
										leftIcon={<Avatar src={user?.logo} h={'30px'} w={'30px'} />}
									>
										{!user ? 'User' : user.name}
									</MenuButton>
									<MenuList color={'gray.700'}>
										{!user ? (
											<>
												<MenuItem
													as={Link}
													to={'/signin'}
													fontWeight={'500'}
													icon={<FiLogIn />}
													command=""
												>
													Sign In
												</MenuItem>
												<MenuItem
													as={Link}
													to={'/register'}
													fontWeight={'500'}
													icon={<FiUserPlus />}
													command=""
												>
													Register
												</MenuItem>
											</>
										) : (
											<>
												<MenuItem
													as={Link}
													bg={'transparent'}
													to={`/profile/edit-profile`}
													fontWeight={'500'}
													icon={<CgProfile />}
													command=""
												>
													My Profile
												</MenuItem>
												<MenuItem
													as={Button}
													bg={'transparent'}
													onClick={() => {
														dispatch(
															logout((msg, status) => {
																return <Navigate to={'/signin'} />
															})
														)
													}}
													fontWeight={'500'}
													icon={<FiLogOut />}
													command=""
												>
													Sign Out
												</MenuItem>
											</>
										)}
									</MenuList>
								</Menu>
							</HStack>
						</Box>
					</Flex>
				</Container>
			</Box>
		</>
	)
}

export default AppHeader
