import { Flex, Box, Container } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import AdminHeader from './AdminHeader'
import AdminLeftNavbar from './AdminLeftNavbar'

const AdminLayout = () => {
	const { user } = useSelector((state) => state.auth)

	if (user && user?.role !== 'admin') return <Navigate to={'/'} />

	return (
		<Flex minH={'100vh'} w={'100%'}>
			<AdminLeftNavbar />
			<AdminHeader />

			<Box h={'100vh'} pt={'60px'} ps={['0', '0', '300px', '300px']} w="100%">
				<Box minH={'calc(100vh - 60px)'} px={[1,3,3,3]} overflowX={'hidden'}>
					<Container maxW={'container.xl'}>
						<Outlet />
					</Container>
				</Box>
			</Box>
		</Flex>
	)
}

export default AdminLayout
