import {
	Box,
	Button,
	Heading,
	Stack,
	Text,
	FormControl,
	Input,
	Flex,

	Icon,
	FormLabel,
	InputGroup,
	InputRightElement,
	useToast,
} from '@chakra-ui/react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AuthLayout from '../../layouts/AuthLayout'
import { useForm } from 'react-hook-form'
// import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { BiShow, BiHide } from 'react-icons/bi'
import { postRequest } from '../../services/base.service'

const AuthResetPassword = () => {
	// const { state } = useLocation()
	const [token, setToken] = useState('')
	const [doesPasswordMatched, setDoesPasswordMatched] = useState(true)
	const [serverErrors, setServerErrors] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	// const [redirectPath] = useState(state?.from?.pathname || '/')
	const [showPass, setShowPass] = useState({
		newPass: false,
		confirmPass: false,
	})
	const toast = useToast()
	const navigate = useNavigate()
	const location = useLocation()
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()

	useEffect(() => {
		setToken(location?.search.split('?token=')[1])
	}, [location])

	const handleOnSubmit = (data) => {
		// dispatch(login(data, 'login', (message, status) => {}))
		setIsLoading(true)
		
			setDoesPasswordMatched(true)
			postRequest('reset-password', { ...data, token: token })
				.then((res) => {
					setIsLoading(false)
					toast({
						description: res.message,
						status: 'success',
						duration: 5000,
						isClosable: true,
					})
					navigate('/signin')
				})
				.catch((err) => {
					setIsLoading(false)
					setServerErrors(err.response?.data?.errors)
					console.log('ERR: ', err)
					toast({
						description: 'Something went wrong',
						status: 'error',
						duration: 5000,
						isClosable: true,
					})
				})
		
	}

	// if (user) {
	// 	if (user?.role !== 'admin') return <Navigate to={redirectPath} />
	// 	return <Navigate to="/admin/dashboard" />
	// }

	return (
		<AuthLayout>
			<Box mx={'auto'} maxW={'420px'}>
				<Text
					as={Link}
					to={'/'}
					mb={'3'}
					fontWeight={500}
					color={'gray.500'}
					display={'flex'}
					alignItems={'center'}
					textTransform={'uppercase'}
				>
					<Icon as={FaLongArrowAltLeft} me={3} /> Back to home
				</Text>
				<Heading as="h3" fontSize={'28px'}>
					Reset Password
				</Heading>
				<Flex h="70vh" align={'center'}>
					<Stack
						as="form"
						gap={'3'}
						w={'100%'}
						onSubmit={handleSubmit(handleOnSubmit)}
					>
						<FormControl>
							<FormLabel htmlFor="newPassoword">
								New Password
							</FormLabel>
							<InputGroup size={'lg'}>
								<Input
									size={'lg'}
									id="newPassword"
									type={
										showPass.newPass ? 'text' : 'password'
									}
									placeholder="New Password"
									isInvalid={
										errors.password?.type === 'required'
									}
									{...register('password', {
										required: true, minLength: 8,
									})}
								/>
								<InputRightElement>
									<Icon
										_hover={{ cursor: 'pointer' }}
										as={showPass.newPass ? BiHide : BiShow}
										onClick={() =>
											setShowPass({
												...showPass,
												newPass: !showPass.newPass,
											})
										}
									/>
								</InputRightElement>
							</InputGroup>
							<Text color="red" fontSize="sm" mt="2">
								{errors.password?.type === 'required' &&
									'New password field field is required'}
							</Text>
							{errors?.password?.type==='minLength' && (
								<Text color="red" fontSize={'sm'}>
									New password must contain at least 8 characters.
								</Text>
							)}
							{serverErrors?.password && (
								<Text color="red" fontSize={'sm'}>
									{serverErrors?.password[0]}
								</Text>
							)}

							
						</FormControl>

						<FormControl>
							<FormLabel htmlFor="newPassoword">
								Confirm Password
							</FormLabel>
							<InputGroup size={'lg'}>
								<Input
									size={'lg'}
									id="confirmPassword"
									type={
										showPass.confirmPass
											? 'text'
											: 'password'
									}
									placeholder="Confirm Password"
									isInvalid={
										errors.password_confirmation?.type ===
										'required'
									}
									{...register('password_confirmation', {
										required: true, minLength:8,
									})}
								/>
								<InputRightElement>
									<Icon
										_hover={{ cursor: 'pointer' }}
										as={
											showPass.confirmPass
												? BiHide
												: BiShow
										}
										onClick={() =>
											setShowPass({
												...showPass,
												confirmPass:
													!showPass.confirmPass,
											})
										}
									/>
								</InputRightElement>
							</InputGroup>
							<Text color="red" fontSize="sm" mt="2">
								{errors.password_confirmation?.type ===
									'required' &&
									'Confirm password field is required'}
							</Text>
							<Text color="red" fontSize="sm" mt="2">
								{errors.password_confirmation?.type ===
									'minLength' &&
									'Confirm password must contain at least 8 characters.'}
							</Text>
							{serverErrors?.password_confirmation && (
								<Text color="red" fontSize={'sm'}>
									{serverErrors?.password_confirmation[0]}
								</Text>
							)}

							{/* <Text
								as={Link}
								to={'/forget-password'}
								mt="2"
								color="brand.500"
								textDecoration={'underline'}
							>
								Forget password?
							</Text> */}
						</FormControl>
						<Button
							type="submit"
							w={'full'}
							size={'lg'}
							variant={'brand'}
							isLoading={isLoading}
							loadingText="Processing..."
						>
							Reset Password
						</Button>

						{/* <Text>
							Already have an account.{' '}
							<Text as={Link} color={'brand.500'} to="/signin">
								Sign In
							</Text>
						</Text> */}
					</Stack>
				</Flex>
			</Box>
		</AuthLayout>
	)
}

export default AuthResetPassword
