import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Text,
} from '@chakra-ui/react'
import { RiArrowRightSLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'

const AdminBreadcrumb = ({ items }) => {
	return (
		<Breadcrumb
			spacing="7px"
			fontWeight={500}
			pt={'2'}
			fontSize={'15px'}
			separator={
				<Text color="gray.500">
					<RiArrowRightSLine />
				</Text>
			}
		>
			{items.map((item, index) => (
				<BreadcrumbItem
					key={index}
					isCurrentPage={item?.isCurrentPage}
					color={!item?.isCurrentPage ? 'brand.500' : 'gray.600'}
				>
					{!item?.isCurrentPage ? (
						<BreadcrumbLink as={Link} to={item?.link}>
							{item?.label}
						</BreadcrumbLink>
					) : (
						<BreadcrumbLink cursor={'auto'} _hover={{ textDecoration: 'none' }}>
							{item?.label}
						</BreadcrumbLink>
					)}
				</BreadcrumbItem>
			))}
		</Breadcrumb>
	)
}

export default AdminBreadcrumb
