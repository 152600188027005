import React, { useEffect, useState } from 'react'
import {
	Box,
	Input,
	Spinner,
	Center,
	Text,
	Alert,
	FormControl,
	FormLabel,
	Image,
	Select,
	Grid,
	GridItem,
	Button,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import AdminBlockCard from '../../components/AdminBlockCard'
import AdminBreadcrumb from '../../components/AdminBreadcrumb'
import { getRequest, postRequest } from '../../services/base.service'
import { useParams } from 'react-router-dom'
import { serialize } from 'object-to-formdata'
import { useToast } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { setAdminSidebarFalseOnUrlChange, setCurrentPageHeading } from '../../redux/common/commonActions'

const breadCrumb = [
	{ label: 'Dashboard', isCurrentPage: false, link: '/admin/dashboard' },
	{ label: 'Location', isCurrentPage: true, link: '/admin/location' },
	{ label: 'Edit', isCurrentPage: true },
]

const EditLocation = () => {
	const { id, locationType } = useParams()
	const [countryList, setCountryList] = useState([])
	const [stateList, setStateList] = useState([])
	const [serverErrors, setServerErrors] = useState(null)
	const [coverPhotoFile, setCoverPhotoFile] = useState(null)
	const [coverPhoto, setCoverPhoto] = useState(null)
	const [spinner, setSpinner] = useState({ status: true })
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch()
	const toast = useToast()
	const {
		register,
		handleSubmit,
		setValue,
		watch,
		formState: { errors },
	} = useForm()

	const country = watch('country_id')

	const fetchCountryList = () => {
		getRequest(`countries`)
			.then((res) => {
				setCountryList(res.result)
			})
			.catch((err) => {
				console.log('ERR: ', err)
			})
	}

	const fetchStateList = (id) => {
		getRequest(`states/${id}`)
			.then((res) => {
				setStateList(res.result.states)
				console.log('state===', res.result)
				setSpinner({ status: false })
			})
			.catch((err) => {
				console.log('ERR: ', err)
				setSpinner({ status: false })
			})
	}

	useEffect(() => {
		if (locationType === 'country') {
			getRequest(`admin/location/details/${id}`, {
				params: { location_type: locationType },
			})
				.then((res) => {
					setValue('name', res.result?.name)
					setCoverPhoto(res.result?.banner)
					setSpinner({ status: false })
				})
				.catch((err) => {
					console.log('ERR: ', err)
					setSpinner({ status: false })
				})
		} else if (locationType === 'state') {
			fetchCountryList()
			getRequest(`admin/location/details/${id}`, {
				params: { location_type: locationType },
			})
				.then((res) => {
					setValue('country_id', res.result.country_id)
					setValue('name', res.result.name)
					setCoverPhoto(res.result.banner)
					setSpinner({ status: false })
				})
				.catch((err) => {
					console.log('ERR: ', err)
					setSpinner({ status: false })
				})
		} else if (locationType === 'city') {
			fetchCountryList()
			getRequest(`admin/location/details/${id}`, {
				params: { location_type: locationType },
			})
				.then((res) => {
					setValue('country_id', res.result.country_id)
					setValue('state_id', res.result.state_id)
					setValue('name', res.result.name)
					console.log('result===', res.result.country_id)
					fetchStateList(res.result.country_id)
					setCoverPhoto(res.result?.banner)
				})
				.catch((err) => {
					console.log('ERR: ', err)
					setSpinner({ status: false })
				})
		}
		dispatch(setCurrentPageHeading('Edit Location'))
		dispatch(setAdminSidebarFalseOnUrlChange())
	}, [])

	const browseImage = (e) => {
		if (e.target.files.length > 0) {
			setCoverPhotoFile(e.target.files[0])
			setCoverPhoto(URL.createObjectURL(e.target.files[0]))
		}
	}

	const setLocationType = () => {
		return locationType === 'country'
			? 'countries'
			: locationType === 'state'
			? 'states'
			: 'cities'
	}
	const objectToFormData = (data) => {
		const object = data
		const formData = serialize(object, { indices: true })
		return formData
	}

	const handleSubmitFormData = (data) => {
		setIsLoading(true)
		postRequest(
			`admin/${setLocationType()}/${id}`,
			objectToFormData({ ...data, banner: coverPhotoFile })
		)
			.then((res) => {
				toast({
					title: 'Successfully Updated.',
					status: 'success',
					duration: 5000,
					isClosable: true,
				})
				setIsLoading(false)
			})
			.catch((err) => {
				setServerErrors(err.response?.data?.errors)
				console.log('ERR :', err)
				toast({
					title: 'Something Went Wrong.',
					status: 'error',
					duration: 5000,
					isClosable: true,
				})
				setIsLoading(false)
			})
		console.log(data)
	}

	return (
		<>
			<AdminBreadcrumb items={breadCrumb} />
			<Box py={'7'}>
				{
					<AdminBlockCard title={'Edit Location'}>
						{spinner.status ? (
							<Center>
								<Spinner color="red.500" size="xl" speed="0.6s" />
							</Center>
						) : true ? (
							<Grid
								as="form"
								onSubmit={handleSubmit(handleSubmitFormData)}
								templateColumns={'repeat(5, 1fr)'}
								gap={5}
							>
								<GridItem colSpan={3}>
									<FormControl mb={3}>
										<FormLabel htmlFor="country">Country</FormLabel>
										{locationType === 'country' && (
											<>
												<Input
													id="country"
													type="text"
													placeholder={'Type To Change Country'}
													borderColor={'gray.300'}
													{...register('name', {
														required: true,
													})}
													size={'lg'}
												/>
												<Text fontSize="sm" color="red.500">
													{errors.name?.type === 'required' &&
														'Country is required'}
												</Text>

												{serverErrors?.name && (
													<Text fontSize="sm" mt="2" color="red.500">
														{serverErrors?.name[0]}
													</Text>
												)}
											</>
										)}
										{locationType !== 'country' && (
											<>
												<Select
													size={'lg'}
													placeholder="Select Country"
													borderColor={'gray.300'}
													{...register('country_id', {
														required: true,
														onChange: (e) => {
															setValue('state_id', null)
															if (e.target.value) {
																fetchStateList(e.target.value)
															}
															setStateList([])
														},
													})}
												>
													{countryList.length > 0 &&
														countryList.map((country) => (
															<option key={country.id} value={country.id}>
																{country.name}
															</option>
														))}
												</Select>
												<Text fontSize="sm" color="red.500">
													{errors.country_id?.type === 'required' &&
														'Country is required'}
												</Text>
												{serverErrors?.country_id && (
													<Text fontSize="sm" mt="2" color="red.500">
														{serverErrors?.country_id[0]}
													</Text>
												)}
											</>
										)}
									</FormControl>
									{locationType !== 'country' && (
										<FormControl mb={3}>
											<FormLabel htmlFor="state/province">
												State/Province
											</FormLabel>
											{locationType === 'state' && (
												<>
													<Input
														id="state/province"
														type="text"
														placeholder={'Type To Change State/Province'}
														borderColor={'gray.300'}
														size={'lg'}
														{...register('name', {
															required: true,
														})}
													/>
													<Text fontSize="sm" color="red.500">
														{errors.name?.type === 'required' &&
															'State/Province is required'}
													</Text>
													{serverErrors?.name && (
														<Text fontSize="sm" mt="2" color="red.500">
															{serverErrors?.name[0]}
														</Text>
													)}
												</>
											)}
											{locationType === 'city' && (
												<>
													<Select
														size={'lg'}
														placeholder="Select State/Province"
														borderColor={'gray.300'}
														{...register('state_id', {
															required: true,
														})}
													>
														{stateList &&
															stateList.length > 0 &&
															stateList.map((state) => (
																<option key={state.id} value={state.id}>
																	{state.name}
																</option>
															))}
													</Select>
													<Text fontSize="sm" color="red.500">
														{errors.state_id?.type === 'required' &&
															'State/Province is required'}
													</Text>
													{serverErrors?.state_id && (
														<Text fontSize="sm" mt="2" color="red.500">
															{serverErrors?.state_id[0]}
														</Text>
													)}
												</>
											)}
										</FormControl>
									)}
									{locationType === 'city' && (
										<FormControl mb={3}>
											<FormLabel htmlFor="city">City</FormLabel>
											<Input
												id="city"
												type="text"
												placeholder={'Type To Change City'}
												borderColor={'gray.300'}
												size={'lg'}
												{...register('name', {
													required: true,
												})}
											/>
											<Text fontSize="sm" color="red.500">
												{errors.name?.type === 'required' && 'City is required'}
											</Text>
											{serverErrors?.name && (
												<Text fontSize="sm" mt="2" color="red.500">
													{serverErrors?.name[0]}
												</Text>
											)}
										</FormControl>
									)}
									<Button
										type="submit"
										isLoading={isLoading}
										loadingText="UPDATING..."
										variant={'brand'}
									>
										UPDATE
									</Button>
								</GridItem>

								<GridItem colSpan={'2'}>
									<FormControl>
										<FormLabel>Cover Photo</FormLabel>
										<Box
											display={'block'}
											as="label"
											borderWidth={'1px'}
											borderStyle={'1px'}
											borderColor={'gray.400'}
											htmlFor="browseLogo"
											pos={'relative'}
											w={'160px'}
											h={'160px'}
											style={{
												cursor: 'pointer',
												'& > .label': {
													display: 'none',
												},
												'&:hover > .label': {
													display: 'block',
												},
											}}
										>
											{coverPhoto && (
												<Image
													src={coverPhoto ? coverPhoto : ''}
													alt={''}
													w={'100%'}
													h={'100%'}
													objectFit={'contain'}
												/>
											)}
											<Input
												name="coverPhoto"
												zIndex={'-99999'}
												opacity={'0'}
												type={'file'}
												id={'browseLogo'}
												onChange={browseImage}
												accept={'image/*'}
												// {...register('logo', { required: true })}
												// isRequired={!fData}
											/>
											<Text
												as="span"
												className={'label'}
												pos={'absolute'}
												top={'50%'}
												left={'50%'}
												fontSize={'12px'}
												display={'block'}
												whiteSpace={'nowrap'}
												px={'2'}
												py={'1'}
												rounded={'full'}
												color={'white'}
												bg={'blue.500'}
												textAlign={'center'}
												textTransform={'uppercase'}
												transform={'translate(-50%, -50%)'}
											>
												Change Image
											</Text>
										</Box>
									</FormControl>
								</GridItem>
							</Grid>
						) : (
							<Alert status="error">
								<Center h={'100%'} w={'100%'}>
									NO DATA FOUND.
								</Center>
							</Alert>
						)}
					</AdminBlockCard>
				}
			</Box>
		</>
	)
}

export default EditLocation
