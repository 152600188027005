import {
	Box,
	FormControl,
	Text,
	FormLabel,
	Grid,
	GridItem,
	Input,
	Stack,
	Select,
	SimpleGrid,
	Textarea,
	HStack,
	RadioGroup,
	Radio,
	CheckboxGroup,
	Checkbox,
	Button,
	Image,
	Icon,
	AspectRatio,
} from '@chakra-ui/react'
import AdminBreadcrumb from '../../components/AdminBreadcrumb'
import AdminBlockCard from '../../components/AdminBlockCard'
import { TiDelete } from 'react-icons/ti'
import { useState, useEffect } from 'react'
import { FiSave, FiUpload } from 'react-icons/fi'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { CreatableSelect } from 'chakra-react-select'
import { Controller, useForm } from 'react-hook-form'
import { getRequest, postRequest } from '../../services/base.service'
import { useSelector } from 'react-redux'
import { serialize } from 'object-to-formdata'
import { useToast } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import {
	setAdminSidebarFalseOnUrlChange,
	setCurrentPageHeading,
} from '../../redux/common/commonActions'

const breadCrumb = [
	{ label: 'Dashboard', isCurrentPage: false, link: '/admin/dashboard' },
	{
		label: 'Business List',
		isCurrentPage: false,
		link: '/admin/business-list',
	},
	{ label: 'Create New Business', isCurrentPage: true },
]

const CreateNewBusiness = () => {
	const {
		register,
		handleSubmit,
		control,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			name: '',
		},
	})

	const [productCategories, products] = watch([
		'product_categories',
		'products',
	])

	const toast = useToast()
	const [phoneNumber, setPhoneNumber] = useState(null)
	const [logo, setLogo] = useState(null)
	const [logoLink, setLogoLink] = useState(null)

	const [imageGallery, setImageGallery] = useState([])
	const [imageGalleryFiles, setImageGalleryFiles] = useState(null)
	const [LogoFile, setLogoFile] = useState(null)
	const [operatingHr, setOperatingHr] = useState([
		{ day: '', start_time: '', end_time: '' },
	])

	const [countryList, setCountryList] = useState([])
	const [stateList, setStateList] = useState([])
	const [cityList, setCityList] = useState([])
	const [selectedCountry, setSelectedCountry] = useState(null)
	const [selectedState, setSelectedState] = useState(null)
	const [businessFormError, setBusinessFormError] = useState(null)
	const [fData, setFData] = useState(null)

	const [isSaving, setIsSaving] = useState(false)
	const [productCategoryList, setProductCategoryList] = useState([])
	const [productList, setProductList] = useState([])
	const dispatch = useDispatch()

	//fetch country list
	const fetchCountryList = () => {
		getRequest('countries')
			.then((res) => {
				setCountryList(res.result)
			})
			.catch((err) => {
				console.log('ERR: ', err)
			})
	}

	const fetchAllProducts = () => {
		getRequest('products')
			.then((res) => {
				setProductList(
					res.result.map((product) => {
						return { label: product.name, value: product.name }
					})
				)
			})
			.catch((err) => {
				console.log('ERR: ', err)
			})
	}
	const fetchAllProductCategories = () => {
		getRequest('product/categories')
			.then((res) => {
				setProductCategoryList(
					res.result.map((category) => {
						return { label: category.name, value: category.name }
					})
				)
			})
			.catch((err) => {
				console.log('ERR: ', err)
			})
	}

	useEffect(() => {
		fetchCountryList()
		fetchAllProducts()
		fetchAllProductCategories()
		dispatch(setCurrentPageHeading('Create Business'))
		dispatch(setAdminSidebarFalseOnUrlChange())
	}, [])

	//get state list
	const fetchStateList = () => {
		if (selectedCountry) {
			getRequest(`states/${selectedCountry}`)
				.then((res) => {
					setStateList(res.result?.states)
				})
				.catch((err) => {
					console.log('ERR: ', err)
				})
		}
	}
	useEffect(() => {
		fetchStateList()
	}, [selectedCountry])

	//get city list
	const fetchCityList = () => {
		if (selectedState) {
			getRequest(`cities/${selectedState}`)
				.then((res) => {
					setCityList(res.result?.cities)
				})
				.catch((err) => {
					console.log('ERR: ', err)
				})
		}
	}
	useEffect(() => {
		fetchCityList()
	}, [selectedState])
	const { user } = useSelector((state) => state.auth)

	const browseImage = (e) => {
		if (e.target.files.length > 0) {
			setLogoFile(e.target.files[0])
			setLogo(URL.createObjectURL(e.target.files[0]))
		}
	}

	const handleGalleryImage = (e) => {
		if (e.target.files.length > 0) {
			const browsedObjURLs = []
			setImageGalleryFiles(e.target.files)
			Array.from(e.target.files).forEach((file) => {
				browsedObjURLs.push(URL.createObjectURL(file))
			})
			setImageGallery(imageGallery.concat(browsedObjURLs))
		}
	}

	const removePhoto = (index) => {
		const browsedList = [...imageGallery]
		const imageGalleryFilesCopy = [...imageGalleryFiles]
		browsedList.splice(index, 1)
		imageGalleryFilesCopy.splice(index, 1)
		setImageGallery(browsedList)
		setImageGalleryFiles(imageGalleryFilesCopy)
	}
	const handleAddBusiness = (data) => {
		setIsSaving(true)
		postRequest(
			`business/add`,
			objectToFormData({
				...data,
				phone: phoneNumber,
				operating_hours: operatingHr,
				product_categories: productCategories.map((cat) => {
					return { name: cat.value }
				}),
				products: products.map((products) => {
					return { name: products.value }
				}),
				logo: LogoFile,
				business_images: imageGalleryFiles || [],
			})
		)
			.then((res) => {
				toast({
					title: 'Saved Successfully',
					status: 'success',
					duration: 3000,
					isClosable: true,
				})
				setIsSaving(false)
			})
			.catch((err) => {
				console.log('ERR: ', err)
				toast({
					title: 'Something Went Wrong!!!',
					status: 'error',
					duration: 3000,
					isClosable: true,
				})
				setBusinessFormError(err.response?.data?.errors)
				setIsSaving(false)
			})
	}
	const removeOperatingHr = (index) => {
		const operatingHrCopy = [...operatingHr]

		operatingHrCopy.splice(index, 1)

		setOperatingHr(operatingHrCopy)
	}

	const objectToFormData = (data) => {
		const object = data
		const formData = serialize(object, { indices: true })
		return formData
	}
	return (
		<>
			<AdminBreadcrumb items={breadCrumb} />

			<Box py={7}>
				<AdminBlockCard title={'Create New Business'}>
					<Box
						pt={'5'}
						pb={'8'}
						as="form"
						onSubmit={handleSubmit(handleAddBusiness)}
					>
						{/* <Container maxW={'container.lg'}> */}

						<Grid
							templateColumns={[
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(4, 1fr)',
							]}
							mt={6}
							gap={6}
						>
							<GridItem colSpan={3}>
								<Stack gap={2}>
									<FormControl>
										<FormLabel htmlFor="businessName">
											Business Name
										</FormLabel>
										<Input
											id="businessName"
											type="text"
											placeholder={'Enter business name'}
											borderColor={'gray.300'}
											size={'lg'}
											{...register('name', {
												required: true,
											})}
										/>
										<Text fontSize="sm" color="red.500">
											{errors.name?.type === 'required' &&
												'Business Name is required'}
										</Text>
										{businessFormError?.name && (
											<Text
												fontSize="sm"
												mt="2"
												color="red.500"
											>
												{businessFormError?.name[0]}
											</Text>
										)}
									</FormControl>
									<FormControl>
										<FormLabel htmlFor="businessBio">
											Business Bio
										</FormLabel>
										<Textarea
											id="businessBio"
											rows={4}
											placeholder={'Enter business bio'}
											borderColor={'gray.300'}
											size={'lg'}
											{...register('bio', {
												required: true,
											})}
										/>
										<Text fontSize="sm" color="red.500">
											{errors.bio?.type === 'required' &&
												'Business Bio is required'}
										</Text>
										{businessFormError?.bio && (
											<Text
												fontSize="sm"
												mt="2"
												color="red.500"
											>
												{businessFormError?.bio[0]}
											</Text>
										)}
									</FormControl>
									<FormControl>
										<FormLabel htmlFor="addressOne">
											Address 1
										</FormLabel>
										<Input
											id="addressOne"
											type="text"
											placeholder={'Address line 1'}
											borderColor={'gray.300'}
											size={'lg'}
											{...register('address1', {
												required: true,
											})}
										/>
										<Text fontSize="sm" color="red.500">
											{errors.address1?.type ===
												'required' &&
												'Address1 is required'}
										</Text>
										{businessFormError?.address1 && (
											<Text
												fontSize="sm"
												mt="2"
												color="red.500"
											>
												{businessFormError?.address1[0]}
											</Text>
										)}
									</FormControl>
									<FormControl>
										<FormLabel htmlFor="addressTwo">
											Address 2
										</FormLabel>
										<Input
											id="addressTwo"
											type="text"
											placeholder={'Address line 2'}
											borderColor={'gray.300'}
											size={'lg'}
											{...register('address2')}
										/>
										{businessFormError?.address2 && (
											<Text
												fontSize="sm"
												mt="2"
												color="red.500"
											>
												{businessFormError?.address2[0]}
											</Text>
										)}
									</FormControl>

									<SimpleGrid columns={2} spacing={4}>
										<FormControl>
											<FormLabel htmlFor="country">
												Country
											</FormLabel>
											<Select
												id={'country'}
												size={'lg'}
												borderColor={'gray.300'}
												placeholder={'Select country'}
												{...register('country_id', {
													required: true,
													onChange: (e) => {
														setSelectedCountry(
															e.target.value
														)
													},
												})}
											>
												{countryList &&
													countryList.map((count) => (
														<option
															key={count.id}
															value={count.id}
														>
															{count.name}
														</option>
													))}
											</Select>
											<Text fontSize="sm" color="red.500">
												{errors.country_id?.type ===
													'required' &&
													'Country is required'}
											</Text>
											{businessFormError?.country_id && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.country_id[0]
													}
												</Text>
											)}
										</FormControl>
										<FormControl>
											<FormLabel htmlFor="state">
												State/Province
											</FormLabel>
											<Select
												isDisabled={
													!stateList.length > 0
												}
												// defaultValue={`${selectedState}`}
												id={'state'}
												size={'lg'}
												borderColor={'gray.300'}
												placeholder={
													'Select state/province'
												}
												{...register('state_id', {
													required:
														stateList.length > 0,
													onChange: (e) =>
														setSelectedState(
															e.target.value
														),
												})}
											>
												{stateList &&
													stateList.map((state) => (
														<option
															key={state.id}
															value={state.id}
														>
															{state.name}
														</option>
													))}
											</Select>
											<Text fontSize="sm" color="red.500">
												{errors.state_id?.type ===
													'required' &&
													'State/Province is required'}
											</Text>
											{businessFormError?.state_id && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.state_id[0]
													}
												</Text>
											)}
										</FormControl>
										<FormControl>
											<FormLabel htmlFor="city">
												City
											</FormLabel>

											<Select
												isDisabled={
													!cityList.length > 0
												}
												// defaultValue={`${selectedCity || ''}`}
												id={'city'}
												size={'lg'}
												borderColor={'gray.300'}
												placeholder={'Select city'}
												{...register('city_id', {
													required:
														cityList.length > 0,
												})}
											>
												{cityList &&
													cityList.map((city) => (
														<option
															key={city.id}
															value={city.id}
														>
															{city.name}
														</option>
													))}
											</Select>
											<Text fontSize="sm" color="red.500">
												{errors.city_id?.type ===
													'required' &&
													'City is required'}
											</Text>
											{businessFormError?.city_id && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.city_id[0]
													}
												</Text>
											)}
										</FormControl>

										<FormControl>
											<FormLabel htmlFor="zipCode">
												Zip Code
											</FormLabel>
											<Input
												id="zipCode"
												type="text"
												placeholder={'Enter ZIP code'}
												borderColor={'gray.300'}
												size={'lg'}
												{...register('zip_code', {
													required: true,
												})}
											/>
											<Text fontSize="sm" color="red.500">
												{errors.zip_code?.type ===
													'required' &&
													'Zip Code is required'}
											</Text>
											{businessFormError?.zip_code && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.zip_code[0]
													}
												</Text>
											)}
										</FormControl>
									</SimpleGrid>

									<FormControl>
										<FormLabel htmlFor="phoneNumber">
											Phone Number
										</FormLabel>
										<Input
											as={PhoneInput}
											defaultCountry={'US'}
											_focus={{ outline: 'none' }}
											placeholder="Enter phone number"
											value={phoneNumber}
											size={'lg'}
											borderColor={'gray.300'}
											{...register('phone', {
												required: true,
											})}
											onChange={(val) =>
												setPhoneNumber(val)
											}
										/>
										<Text fontSize="sm" color="red.500">
											{errors.phone?.type ===
												'required' &&
												'Phone Number is required'}
										</Text>
										{businessFormError?.phone && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.phone[0]
													}
												</Text>
											)}
									</FormControl>

									<FormControl>
										<FormLabel htmlFor="emailAddress">
											Email
										</FormLabel>
										<Input
											id="emailAddress"
											type="email"
											placeholder={'Enter email address'}
											borderColor={'gray.300'}
											size={'lg'}
											{...register('email', {
												required: true,
											})}
										/>
										<Text fontSize="sm" color="red.500">
											{errors.email?.type ===
												'required' &&
												'Email is required'}
										</Text>
										{businessFormError?.email && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.email[0]
													}
												</Text>
											)}
									</FormControl>

									<SimpleGrid columns={2} spacing={4}>
										<FormControl>
											<FormLabel htmlFor="website">
												Website
											</FormLabel>
											<Input
												id="website"
												type="text"
												placeholder={
													'Enter business website'
												}
												borderColor={'gray.300'}
												size={'lg'}
												{...register('website')}
											/>
												{businessFormError?.website && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.website[0]
													}
												</Text>
											)}
										</FormControl>

										<FormControl>
											<FormLabel htmlFor="WhatsApp">
												WhatsApp
											</FormLabel>
											<Input
												id="WhatsApp"
												type="text"
												placeholder={'WhatsApp'}
												borderColor={'gray.300'}
												size={'lg'}
												{...register('whatsapp_no')}
											/>
											{businessFormError?.whatsapp_no && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.whatsapp_no[0]
													}
												</Text>
											)}
										</FormControl>

										<FormControl>
											<FormLabel htmlFor="facebook">
												Facebook
											</FormLabel>
											<Input
												id="facebook"
												type="text"
												placeholder={'Facebook'}
												borderColor={'gray.300'}
												size={'lg'}
												{...register('facebook')}
											/>
												{businessFormError?.facebook && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.facebook[0]
													}
												</Text>
											)}
										</FormControl>

										<FormControl>
											<FormLabel htmlFor="instagram">
												Instagram
											</FormLabel>
											<Input
												id="instagram"
												type="text"
												placeholder={'Instagram'}
												borderColor={'gray.300'}
												size={'lg'}
												{...register('instagram')}
											/>
											{businessFormError?.instagram && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.instagram[0]
													}
												</Text>
											)}
										</FormControl>

										<FormControl>
											<FormLabel htmlFor="twitter">
												Twitter
											</FormLabel>
											<Input
												id="twitter"
												type="text"
												placeholder={'Twitter'}
												borderColor={'gray.300'}
												size={'lg'}
												{...register('twitter')}
											/>
											{businessFormError?.twitter && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.twitter[0]
													}
												</Text>
											)}
										</FormControl>

										<FormControl>
											<FormLabel htmlFor="YouTube">
												YouTube
											</FormLabel>
											<Input
												id="YouTube"
												type="text"
												placeholder={'YouTube'}
												borderColor={'gray.300'}
												size={'lg'}
												{...register('youtube')}
											/>
											{businessFormError?.youtube && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.youtube[0]
													}
												</Text>
											)}
										</FormControl>

										<FormControl>
											<FormLabel htmlFor="Pinterest">
												Pinterest
											</FormLabel>
											<Input
												id="Pinterest"
												type="text"
												placeholder={'Pinterest'}
												borderColor={'gray.300'}
												size={'lg'}
												{...register('pinterest')}
											/>
											{businessFormError?.pinterest && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.pinterest[0]
													}
												</Text>
											)}
										</FormControl>

										<FormControl>
											<FormLabel htmlFor="LinkedIn">
												LinkedIn
											</FormLabel>
											<Input
												id="LinkedIn"
												type="text"
												placeholder={'LinkedIn'}
												borderColor={'gray.300'}
												size={'lg'}
												{...register('linkedin')}
											/>
												{businessFormError?.linkedin && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.linkedin[0]
													}
												</Text>
											)}
										</FormControl>

										<FormControl>
											<FormLabel htmlFor="Yelp">
												Yelp
											</FormLabel>
											<Input
												id="Yelp"
												type="text"
												placeholder={'Yelp'}
												borderColor={'gray.300'}
												size={'lg'}
												{...register('yelp')}
											/>
											{businessFormError?.yelp && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.yelp[0]
													}
												</Text>
											)}
										</FormControl>
									</SimpleGrid>

									<FormControl>
										<FormLabel htmlFor="mapLatitude">
											Google Map
										</FormLabel>
										<HStack gap={3}>
											<Input
												id="mapLatitude"
												type="text"
												placeholder={'Latitude'}
												borderColor={'gray.300'}
												size={'lg'}
												{...register('lat')}
											/>

											<Input
												id="mapLongitude"
												type="text"
												placeholder={'Longitude'}
												borderColor={'gray.300'}
												size={'lg'}
												{...register('long')}
											/>
										</HStack>
										{businessFormError?.lat && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.lat[0]
													}
												</Text>
											)}
											{businessFormError?.long && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.long[0]
													}
												</Text>
											)}
									</FormControl>

									<FormControl>
										<FormLabel>Product Category</FormLabel>
										<Controller
											control={control}
											name="product_categories"
											rules={{ required: true }}
											render={({
												field: {
													onChange,
													onBlur,
													value,
													ref,
												},
											}) => (
												<CreatableSelect
													isMulti
													size={'lg'}
													name="Product Category"
													defaultValue={value}
													onChange={onChange}
													onBlur={onBlur}
													ref={ref}
													options={
														productCategoryList
													}
													placeholder="Select Product Category"
													closeMenuOnSelect={false}
												/>
											)}
										/>
										<Text
											fontSize="sm"
											mt="2"
											color="red.500"
										>
											{errors.product_categories?.type ===
												'required' &&
												'Product Category is required'}
										</Text>
										{businessFormError?.product_categories && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.product_categories[0]
													}
												</Text>
											)}
									</FormControl>

									<FormControl>
										<FormLabel htmlFor="products">
											Products
										</FormLabel>
										<Controller
											control={control}
											name="products"
											rules={{ required: true }}
											render={({
												field: {
													onChange,
													onBlur,
													value,
													ref,
												},
											}) => (
												<CreatableSelect
													isMulti
													size={'lg'}
													name="Products"
													defaultValue={value}
													onChange={onChange}
													onBlur={onBlur}
													ref={ref}
													options={productList}
													placeholder="Select Product Category"
													closeMenuOnSelect={false}
												/>
											)}
										/>
										<Text
											fontSize="sm"
											mt="2"
											color="red.500"
										>
											{errors.products?.type ===
												'required' &&
												'Products  is required'}
										</Text>
										{businessFormError?.products && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.products[0]
													}
												</Text>
											)}
									</FormControl>

									<FormControl>
										<FormLabel>Current Status</FormLabel>

										<RadioGroup colorScheme={'red'}>
											<Stack direction="row" spacing={4}>
												<Radio
													{...register(
														'current_status',
														{ required: true }
													)}
													value="Open"
												>
													Open
												</Radio>
												<Radio
													{...register(
														'current_status',
														{ required: true }
													)}
													value="Temporarily Closed"
												>
													Temporarily Closed
												</Radio>
												<Radio
													{...register(
														'current_status',
														{ required: true }
													)}
													value="Closed"
												>
													Closed
												</Radio>
											</Stack>
										</RadioGroup>
										<Text
											fontSize="sm"
											mt="2"
											color="red.500"
										>
											{errors.current_status?.type ===
												'required' &&
												'Current Status  is required'}
										</Text>
										{businessFormError?.current_status && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.current_status[0]
													}
												</Text>
											)}
									</FormControl>

									<FormControl>
										<FormLabel>Current Offers</FormLabel>

										<CheckboxGroup colorScheme={'red'}>
											<Stack
												spacing={[1, 5]}
												direction={['column', 'row']}
											>
												<Checkbox
													{...register(
														'current_offers',
														{ required: true }
													)}
													value="Special Offers"
												>
													Special Offers
												</Checkbox>
												<Checkbox
													{...register(
														'current_offers',
														{ required: true }
													)}
													value="No Deposit"
												>
													No Deposit
												</Checkbox>
												<Checkbox
													{...register(
														'current_offers',
														{ required: true }
													)}
													value="Pick & Drop"
												>
													Pick &amp; Drop
												</Checkbox>
												<Checkbox
													{...register(
														'current_offers',
														{ required: true }
													)}
													value="Free delivery"
												>
													Free delivery
												</Checkbox>
											</Stack>
										</CheckboxGroup>
										<Text
											fontSize="sm"
											mt="2"
											color="red.500"
										>
											{errors.current_offers?.type ===
												'required' &&
												'Current Offers  is required'}
										</Text>
										{businessFormError?.current_offers && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.current_offers[0]
													}
												</Text>
											)}
									</FormControl>

									<FormControl>
										<FormLabel>Pricing</FormLabel>

										<RadioGroup
											name="pricing"
											colorScheme={'red'}
										>
											<Stack direction="row" spacing={4}>
												<Radio
													{...register('pricing', {
														required: true,
													})}
													value="1"
												>
													$
												</Radio>
												<Radio
													{...register('pricing', {
														required: true,
													})}
													value="2"
												>
													$$
												</Radio>
												<Radio
													{...register('pricing', {
														required: true,
													})}
													value="3"
												>
													$$$
												</Radio>
												<Radio
													{...register('pricing', {
														required: true,
													})}
													value="4"
												>
													$$$$
												</Radio>
											</Stack>
										</RadioGroup>
										<Text
											fontSize="sm"
											mt="2"
											color="red.500"
										>
											{errors.pricing?.type ===
												'required' &&
												'Pricing is required'}
										</Text>
										{businessFormError?.pricing && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.pricing[0]
													}
												</Text>
											)}
									</FormControl>

									<Box>
										<Text fontWeight={'500'} mb={'2'}>
											Operating Hours
										</Text>

										<Box>
										<Stack>
											{operatingHr.length > 0 &&
												operatingHr.map(
													(opHr, index) => (
														<SimpleGrid
															key={index}
															columns={4}
															spacing={'4'}
														>
															<FormControl>
																<Select
																	size={'sm'}
																	// value={operatingHr[index].day}
																	name={`day_${index}`}
																	placeholder={
																		'Select day'
																	}
																	// {...register('day', { required: true })}

																	onChange={(
																		e
																	) => {
																		const operatingHrCopy =
																			operatingHr
																		operatingHrCopy[
																			index
																		].day =
																			e.target.value
																		setOperatingHr(
																			operatingHrCopy
																		)
																	}}
																>
																	<option
																		value={
																			'Monday'
																		}
																	>
																		Monday{' '}
																	</option>
																	<option
																		value={
																			'Tuesday'
																		}
																	>
																		Tuesday
																	</option>
																	<option
																		value={
																			'Wednesday'
																		}
																	>
																		Wednesday
																	</option>
																	<option
																		value={
																			'Thursday'
																		}
																	>
																		Thursday
																	</option>
																	<option
																		value={
																			'Friday'
																		}
																	>
																		Friday
																	</option>
																	<option
																		value={
																			'Saturday'
																		}
																	>
																		Saturday
																	</option>
																	<option
																		value={
																			'Sunday'
																		}
																	>
																		Sunday
																	</option>
																</Select>
															</FormControl>

															<FormControl>
																<Input
																	size={'sm'}
																	name={`start_time_${index}`}
																	type={
																		'time'
																	}
																	onChange={(
																		e
																	) => {
																		operatingHr[
																			index
																		].start_time =
																			e.target.value
																	}}
																/>
															</FormControl>

															<FormControl>
																<Input
																	size={'sm'}
																	type={
																		'time'
																	}
																	name={`end_time_${index}`}
																	onChange={(
																		e
																	) => {
																		operatingHr[
																			index
																		].end_time =
																			e.target.value
																	}}
																/>
															</FormControl>

															{index !== 0 && (
																<Button
																	size="sm"
																	variant={
																		'outline'
																	}
																	colorScheme={
																		'red'
																	}
																	onClick={() =>
																		removeOperatingHr(
																			index
																		)
																	}
																>
																	Remove
																</Button>
															)}
														</SimpleGrid>
													)
												)}
										</Stack>
										{businessFormError?.operating_hours && (
												<Text
													fontSize="sm"
													mt="2"
													color="red.500"
												>
													{
														businessFormError
															?.operating_hours[0]
													}
												</Text>
											)}
										</Box>
										<Text
											mt={'2'}
											as={'button'}
											textColor={'brand.500'}
											textDecoration={'underline'}
											onClick={(e) => {
												e.preventDefault()
												if (operatingHr.length < 7) {
													setOperatingHr([
														...operatingHr,
														{
															day: '',
															start_time: '',
															end_time: '',
														},
													])
												}
											}}
										>
											{operatingHr.length < 7 &&
												'+ Add Hours'}
										</Text>
									</Box>
								</Stack>
							</GridItem>

							<GridItem>
								<Stack spacing={5}>
									<FormControl>
										<FormLabel>Logo</FormLabel>
										<Box
											display={'block'}
											as="label"
											borderWidth={'1px'}
											borderStyle={'1px'}
											borderColor={'gray.400'}
											htmlFor="browseLogo"
											pos={'relative'}
											w={'160px'}
											h={'160px'}
											style={{
												cursor: 'pointer',
												'& > .label': {
													display: 'none',
												},
												'&:hover > .label': {
													display: 'block',
												},
											}}
										>
											{logo && (
												<Image
													src={logo ? logo : logoLink}
													alt={''}
													w={'100%'}
													h={'100%'}
													objectFit={'contain'}
												/>
											)}
											<Input
												name="logo"
												zIndex={'-99999'}
												opacity={'0'}
												type={'file'}
												id={'browseLogo'}
												onChange={browseImage}
												accept={'image/*'}
												// {...register('logo', { required: true })}
											/>
											<Text
												as="span"
												className={'label'}
												pos={'absolute'}
												top={'50%'}
												left={'50%'}
												fontSize={'12px'}
												display={'block'}
												whiteSpace={'nowrap'}
												px={'2'}
												py={'1'}
												rounded={'full'}
												color={'white'}
												bg={'blue.500'}
												textAlign={'center'}
												textTransform={'uppercase'}
												transform={
													'translate(-50%, -50%)'
												}
											>
												Change Logo
											</Text>
										</Box>
									</FormControl>

									<FormControl>
										<FormLabel>Gallery Images</FormLabel>

										<SimpleGrid
											columns={3}
											spacing={2}
											mb={2}
										>
											{imageGallery.length > 0 &&
												imageGallery.map(
													(image, index) => (
														<Box
															key={index}
															pos={'relative'}
														>
															<AspectRatio
																w={'100%'}
																ratio={'1'}
															>
																<Image
																	src={image}
																	alt={''}
																	w={'100%'}
																	h={'100%'}
																	objectFit={
																		'contain'
																	}
																	border={
																		'1px'
																	}
																	borderColor={
																		'lightgray'
																	}
																/>
															</AspectRatio>
															<Icon
																pos={'absolute'}
																top={'-7px'}
																right={'-7px'}
																as={TiDelete}
																onClick={() =>
																	removePhoto(
																		index
																	)
																}
																cursor={
																	'pointer'
																}
																_hover={{
																	color: 'red',
																}}
															/>
														</Box>
													)
												)}
										</SimpleGrid>

										<Button
											leftIcon={<FiUpload />}
											as={'label'}
											htmlFor={'browseGallery'}
											variant={'outline'}
											colorScheme={'blue'}
											size={'lg'}
											w={'200px'}
										>
											<Input
												name="imageGallery"
												zIndex={'-99999'}
												opacity={'0'}
												p={'0'}
												type={'file'}
												accept={'image/*'}
												multiple
												id={'browseGallery'}
												onChange={handleGalleryImage}
												isRequired={!fData}
											/>
											Browse Images
										</Button>
									</FormControl>
								</Stack>
							</GridItem>
						</Grid>
						{/* </Container> */}
						<Text textAlign={'left'} mt={'3'}>
							By clicking SAVE, you agree to RentMy Directory
							Business Terms and acknowledge our Privacy Policy.
							We may email you about RentMy Products, services and
							local events. You can unsubscribe at any time
						</Text>

						<Box textAlign={'left'} mt={'3'}>
							<Button
								isLoading={isSaving}
								loadingText={'SAVING...'}
								type={'submit'}
								size={'lg'}
								variant={'brand'}
								leftIcon={<FiSave />}
							>
								SAVE &nbsp;
							</Button>
						</Box>
					</Box>
				</AdminBlockCard>
			</Box>
		</>
	)
}

export default CreateNewBusiness
