const styles = {
	global: {
		body: {
			color: 'gray.600',
			fontSize: '17px',
			letterSpacing: '0.03rem',
			lineHeight: 1.7,
			input: {
				outline: 'none !important',
				'&:focus': {
					outline: 'none !important',
				},
			},
		},
	},
}

export default styles
