import {
	Box,
	Center,
	Container,
	Flex,
	Heading,
	IconButton,
	SimpleGrid,
	Spinner,
	Text,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FiFilter } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import BusinessCard from '../components/BusinessCard'
import LeftSidebarFilters from '../components/LeftSidebarFilters'
import { queryParamsToObject } from '../helpers/utils'
import { fetchBusinessList, toggleBusinessFilter } from '../redux'
import { getRequest } from '../services/base.service'
import NotFound from './errors/NotFound'

const LocationDetails = () => {
	const [isNotFound, setIsNotFound] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [locationInfo, setLocationInfo] = useState(null)
	const [queryParams, setQueryParams] = useState(null)
	const business = useSelector((state) => state.business)
	const dispatch = useDispatch()
	const { type, slug } = useParams()

	useEffect(() => {
		const qParams = window.location.search
			? queryParamsToObject(window.location.search.substring(1))
			: {}
		setQueryParams(qParams)
		getRequest(`location/details/${slug}?location_type=${type}`).then((res) => {
			if (!res.result) {
				setIsNotFound(true)
			} else {
				setLocationInfo(res.result)
				dispatch(
					fetchBusinessList(type ? { ...qParams, [type]: slug } : qParams)
				)
			}
			setIsLoading(false)
		})
	}, [dispatch, slug, type])

	if (!(type === 'country' || type === 'state' || type === 'city')) {
		return <NotFound />
	}

	return (
		<>
			{isNotFound ? (
				<NotFound />
			) : (
				<Box py={6}>
					{!isLoading ? (
						<Container maxW={'container.xl'}>
							<Box
								bg={'gray.100'}
								bgImage={
									locationInfo?.banner ||
									'https://cdn.pixabay.com/photo/2017/08/16/00/59/panorama-2646143__480.jpg'
								}
								bgPos={'center'}
								bgSize={'cover'}
								minH={['200px', '200px', '360px', '360px']}
								py={5}
								rounded={15}
								_before={{
									pos: 'absolute',
									content: "''",
									w: '100%',
									left: 0,
									bottom: 0,
									zIndex: 0,
									h: '50%',
									roundedBottom: '15',
									bgGradient: 'linear(to-t, blackAlpha.700, blackAlpha.50)',
								}}
								pos={'relative'}
							>
								<Box
									pos={'absolute'}
									color="white"
									bottom={['15px', '15px', '20px', '30px']}
									w={'100%'}
									py={4}
									px={7}
									left={'0'}
									zIndex={'5'}
									textAlign={'center'}
									// _before={{
									// 	content: "''",
									// 	pos: 'absolute',
									// 	top: 0,
									// 	right: 0,
									// 	bottom: 0,
									// 	left: 0,
									// 	bg: 'blackAlpha.300',
									// 	zIndex: 0,
									// 	rounded: 10,
									// 	overflow: 'hidden',
									// 	border: '1px solid',
									// 	borderColor: 'blackAlpha.300',
									// 	backdropFilter: 'saturate(150%) blur(2px)',
									// }}
								>
									<Box pos={'relative'} zIndex={9}>
										<Heading
											fontSize={'2.4rem'}
											color={'white'}
											fontWeight={500}
										>
											{locationInfo?.name}
										</Heading>
										<Text opacity={'0.75'} mt={2}>
											{locationInfo?.businesses_count} Business
											{locationInfo?.businesses_count > 1 && 'es'}
										</Text>
									</Box>
								</Box>
							</Box>

							<Flex>
								<LeftSidebarFilters qParamsObj={queryParams} />

								<Box
									borderLeftWidth={[0, 0, 0, '1px']}
									borderLeftStyle={'solid'}
									borderLeftColor={'gray.300'}
									w={['100%', '100%', '100%', 'calc(100% - 320px)']}
									ps={[0, 0, 0, '6']}
									pb={'6'}
									pt={5}
								>
									<Box>
										<Flex
											justify={'space-between'}
											mb={'4'}
											alignItems={'center'}
										>
											<Heading fontSize={'1.3rem'}>All Result</Heading>

											<Box display={['block', 'block', 'block', 'none']}>
												<IconButton
													onClick={() => dispatch(toggleBusinessFilter())}
													icon={<FiFilter />}
												/>
											</Box>
										</Flex>

										{business?.isLoading ? (
											<Center h={'300px'}>
												<Box>
													<Spinner
														thickness="4px"
														speed="0.65s"
														emptyColor="gray.200"
														color="brand.500"
														size="xl"
													/>
													<Text textAlign={'center'}>Loading...</Text>
												</Box>
											</Center>
										) : (
											<>
												{business.businessList?.length > 0 ? (
													<SimpleGrid gap={[4, 5, 6, 6]} columns={[1, 1, 2, 2]}>
														{business.businessList.map((business, index) => (
															<BusinessCard key={index} data={business} />
														))}
													</SimpleGrid>
												) : (
													<Box>No business found</Box>
												)}
											</>
										)}
									</Box>
								</Box>
							</Flex>
						</Container>
					) : (
						<Container maxW={'container.xl'}>
							<Center minH={'420px'}>
								<Spinner
									thickness="4px"
									speed="0.65s"
									emptyColor="gray.200"
									color="brand.500"
									size="xl"
								/>
							</Center>
						</Container>
					)}
				</Box>
			)}
		</>
	)
}

export default LocationDetails
