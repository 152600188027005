import {
	BUSINESS_SEARCH_FAILED,
	BUSINESS_SEARCH_REQUEST,
	BUSINESS_SEARCH_SUCCESS,
} from './actionTypes'

const initialState = {
	isLoading: false,
	businessList: [],
	errors: null,
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		/**
		 * User login
		 */
		case BUSINESS_SEARCH_REQUEST:
			return {
				...state,
				isLoading: true,
			}
		case BUSINESS_SEARCH_SUCCESS:
			return {
				...state,
				isLoading: false,
				businessList: action.payload?.data || [],
			}
		case BUSINESS_SEARCH_FAILED:
			return {
				...state,
				errors: action.errors,
			}
		default:
			return state
	}
}

export default reducer
