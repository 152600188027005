import { Box, Heading } from '@chakra-ui/react'

const FilterWidget = ({ children, title }) => {
	return (
		<Box>
			{title && (
				<Heading as="h4" fontSize={'1.1rem'} mb="3">
					{title}
				</Heading>
			)}
			{children}
		</Box>
	)
}

export default FilterWidget
