import { Box, Container, Flex, Heading, Icon, Stack } from '@chakra-ui/react'
import AdminHeaderUser from '../../components/AdminHeaderUserMenu'
import { AiOutlineMenu } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { toggleAdminSidebar } from '../../redux/common/commonActions'

const AdminHeader = () => {
	const dispatch = useDispatch()
	const {currentPageHeading} = useSelector((state) => state.common)
	return (
		<Box
			pos={'fixed'}
			top={'0'}
			left={['0', '0', '300px']}
			w={['100%', '100%',  'calc(100% - 300px)']}
			bg="white"
			zIndex={'888'}
			px={3}
			borderBottomStyle={'solid'}
			borderBottomWidth={'1px'}
			borderBottomColor={'gray.300'}
		>
			<Container maxW="container.xl">
				<Flex
					h={'60px'}
					w="100%"
					justify={'space-between'}
					alignItems={'center'}
				>
					<Icon
						as={AiOutlineMenu}
						display={['inline-block', ' inline-block', 'none']}
						_hover={{cursor: 'pointer'}}
						onClick={()=>dispatch(toggleAdminSidebar())}
					/>
					<Heading as="h2" fontSize={'24px'}>
						{currentPageHeading}
					</Heading>
					<Stack direction={'row'}>
						<AdminHeaderUser />
					</Stack>
				</Flex>
			</Container>
		</Box>
	)
}

export default AdminHeader
