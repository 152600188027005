import { Box, Center, Flex, Image, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const AuthLayout = ({ children }) => {
	return (
		<Flex h="100vh">
			<Center
				w={['100%', '100%', '100%', '50%']}
				display={['none', 'none', 'none', 'flex']}
				bg={'brand.500'}
			>
				<Box align="center">
					<Link to={'/'}>
						<Image
							src="https://rentmy.co/wp-content/uploads/2019/11/logo-white.png"
							alt="Brand Logo"
						/>
					</Link>
					<Text color="white" fontSize="2xl">
						RentMy Business Directory
					</Text>
				</Box>
			</Center>
			<Box
				pt={12}
				pb={'5'}
				w={['100%', '100%', '100%', '50%']}
				px={3}
				overflowX={'hidden'}
			>
				{children}
			</Box>
		</Flex>
	)
}

export default AuthLayout
