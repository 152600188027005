import { Badge, Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const BusinessCard = ({ data }) => {
	return (
		<Flex
			as={Link}
			to={`/business/${data.slug}`}
			borderStyle={'solid'}
			borderWidth={'1px'}
			borderColor={'gray.300'}
			bg={'white'}
			rounded={10}
			_hover={{ bg: 'white', borderColor: 'brand.500' }}
			p={[3, 3, 3, 4]}
		>
			<Image
				src={data.logo || ''}
				h={['140px', '160px', '120px', '160px']}
				w={['140px', '160px', '120px', '160px']}
				p={3}
				objectFit={'contain'}
				bg={'gray.100'}
			/>
			<Box w="calc(100% - 160px)" ps={5}>
				<Badge
					colorScheme="green"
					fontSize={'.875rem'}
					textTransform={'uppercase'}
					mb="2"
				>
					{data.current_status}
				</Badge>
				<Heading as="h2" noOfLines={1} fontSize={'1.4rem'}>
					{data.name}
				</Heading>
				<Text mt="3" noOfLines={[2, 2, 2, 3]}>
					{data.bio}
				</Text>
			</Box>
		</Flex>
	)
}

export default BusinessCard
