const colors = {
	brand: {
		50: '#fbecea',
		100: '#f5c8c1',
		200: '#f2b6ac',
		300: '#eb9283',
		400: '#e56e5a',
		500: '#df4a31',
		600: '#c8422c',
		700: '#b23b27',
		800: '#9c3322',
		900: '#852c1d',
	},
}

export default colors
