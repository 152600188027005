import {
	AspectRatio,
	Badge,
	Box,
	Button,
	ButtonGroup,
	Container,
	Flex,
	Heading,
	IconButton,
	Image,
	SimpleGrid,
	Stack,
	Text,
	List,
	ListItem,
	ListIcon,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	PopoverBody,
	PopoverHeader,
	Spinner,
	Center,
	HStack,
} from '@chakra-ui/react'
import {
	FacebookShareButton,
	FacebookIcon,
	LinkedinShareButton,
	LinkedinIcon,
	EmailShareButton,
	EmailIcon,
	TwitterShareButton,
	TwitterIcon,
	WhatsappShareButton,
	WhatsappIcon,
} from 'react-share'
import PageSearchForm from '../components/PageSearchForm'
import { FiShare2 } from 'react-icons/fi'
import {
	FaFacebook,
	FaTwitter,
	FaLinkedin,
	FaYoutube,
	FaInstagram,
} from 'react-icons/fa'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getRequest } from '../services/base.service'

const BusinessDetails = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [business, setBusiness] = useState(null)

	const { slug } = useParams()

	useEffect(() => {
		getRequest(`business/details/${slug}`)
			.then((res) => {
				setBusiness(res.result)
				setIsLoading(false)
			})
			.catch((error) => {
				setIsLoading(false)
				console.log(error)
			})
	}, [slug])

	const objArrToStrArr = (objArr = [], keyName = 'name') => {
		return objArr.map((item) => item[keyName])
	}

	const showPricing = (qnt = 1) => {
		let dollarSigns = ''
		for (let i = 1; i <= qnt; i++) {
			dollarSigns = dollarSigns + '$'
		}
		return dollarSigns
	}

	return (
		<>
			<Box
				py={'8'}
				borderBottomWidth={'1px'}
				borderBottomStyle={'solid'}
				borderColor={'gray.300'}
			>
				<Container maxW={'container.xl'}>
					<PageSearchForm />
				</Container>
			</Box>

			{!isLoading ? (
				<Box>
					<Container py={5} maxW={'container.xl'}>
						{business.business_images.length > 0 && (
							<Box
								pb={5}
								display={['none', 'none', 'block', 'block']}
								borderBottomColor={'gray.300'}
								borderBottomStyle={'solid'}
								borderBottomWidth={'1px'}
							>
								<SimpleGrid gap={2} columns={5}>
									{business.business_images.map((img, index) => (
										<AspectRatio key={index}>
											<Image
												src={img.image}
												h="100%"
												w="100j%"
												objectFit={'cover'}
											/>
										</AspectRatio>
									))}
								</SimpleGrid>
							</Box>
						)}

						<Box pt={5}>
							<Box display={['block', 'block', 'flex', 'flex']}>
								<AspectRatio
									mx={['auto', 'auto', '0', '0']}
									w={'200px'}
									h={'200px'}
									ratio={1 / 1}
									bg={'gray.100'}
								>
									<Image
										w="100%"
										h="100%"
										objectFit={'contain'}
										p={3}
										src={business?.logo}
										alt={business?.name}
									/>
								</AspectRatio>

								<Box
									textAlign={['center', 'center', 'left', 'left']}
									w={[
										'100%',
										'100%',
										'calc(100% - 200px)',
										'calc(100% - 200px)',
									]}
									ps={[0, 0, 6, 6]}
								>
									<Box
										display={['block', 'block', 'flex', 'flex']}
										justifyContent={'space-between'}
										alignItems={'center'}
									>
										<Heading
											as={'h2'}
											mt={['2', '2', 0, 0]}
											textAlign={['center', 'center', 'left', 'left']}
											fontSize={'1.5rem'}
										>
											{business?.name}
											<Badge fontSize={'1rem'} ml={'3'} colorScheme={'green'}>
												{business?.current_status}
											</Badge>
										</Heading>

										<Box mt={['2', '2', 0, 0]}>
											<ButtonGroup
												isAttached
												variant="outline"
												colorScheme={'orange'}
											>
												<Button
													as={Link}
													to={`/claim-your-business/${business?.slug}`}
													mr="-px"
												>
													Claim Business
												</Button>

												<Popover placement={'bottom-end'}>
													<PopoverTrigger>
														<IconButton
															icon={<FiShare2 />}
															aria-label={'Share'}
														/>
													</PopoverTrigger>
													<PopoverContent>
														<PopoverArrow />
														<PopoverHeader>Share this business</PopoverHeader>
														<PopoverBody>
															<HStack spacing={2}>
																<FacebookShareButton
																	url={window.location.href}
																	quote={`${business?.name}`}
																	description={`${business?.bio}`}
																>
																	<FacebookIcon size={32} round />
																</FacebookShareButton>

																<EmailShareButton
																	url={window.location.href}
																	title={`${business?.name}`}
																	description={`${business?.bio}`}
																>
																	<EmailIcon size={32} round />
																</EmailShareButton>

																<LinkedinShareButton
																	url={window.location.href}
																	title={`${business?.name}`}
																	summary={`${business?.bio}`}
																>
																	<LinkedinIcon size={32} round />
																</LinkedinShareButton>

																<TwitterShareButton
																	url={window.location.href}
																	title={`${business?.name}`}
																	via={`${business?.name}`}
																>
																	<TwitterIcon size={32} round />
																</TwitterShareButton>

																<WhatsappShareButton
																	url={window.location.href}
																	title={`${business?.name}`}
																>
																	<WhatsappIcon size={32} round />
																</WhatsappShareButton>
															</HStack>
														</PopoverBody>
													</PopoverContent>
												</Popover>
											</ButtonGroup>
										</Box>
									</Box>

									<Stack
										mt="4"
										alignItems={['center', 'center', 'baseline', 'baseline']}
									>
										{business?.website && (
											<Flex>
												<Text fontWeight={'500'}>Website: </Text>
												<Text ml={'3'} as={'a'} href={business?.website}>
													{business?.website}
												</Text>
											</Flex>
										)}

										{business?.phone && (
											<Flex>
												<Text fontWeight={'500'}>Phone: </Text>
												<Text ml={'3'}>{business?.phone}</Text>
											</Flex>
										)}

										{business?.whatsapp_no && (
											<Flex>
												<Text fontWeight={'500'}>WhatsApp: </Text>
												<Text ml={'3'}>{business?.whatsapp_no}</Text>
											</Flex>
										)}

										{business?.email && (
											<Flex>
												<Text fontWeight={'500'}>Email: </Text>
												<Text ml={'3'}>{business?.email}</Text>
											</Flex>
										)}
									</Stack>
								</Box>
							</Box>

							{business?.bio && (
								<Box mt={6}>
									<Heading as={'h4'} fontSize={'1.1rem'}>
										Company Bio
									</Heading>
									<Text mt={4}>{business.bio}</Text>
								</Box>
							)}

							{business?.operating_hours?.length > 0 && (
								<Box mt={6}>
									<Heading as={'h4'} fontSize={'1.1rem'}>
										Opening Hours
									</Heading>

									<Stack spacing={2} mt={4}>
										{business?.operating_hours.map((item, index) => (
											<Text key={index} display={'flex'}>
												<Text as="span" w={'200px'} fontWeight={'500'} me={4}>
													{item.day}
												</Text>
												{item?.start_time} - {item?.end_time}
											</Text>
										))}
									</Stack>
								</Box>
							)}

							<SimpleGrid columns={3}>
								<Box mt={6}>
									<Heading as={'h4'} fontSize={'1.1rem'}>
										Product Categories
									</Heading>
									<Box mt={4}>
										{objArrToStrArr(business.product_categories).join(', ')}
									</Box>
								</Box>

								<Box mt={6}>
									<Heading as={'h4'} fontSize={'1.1rem'}>
										Products
									</Heading>
									<Box mt={4}>
										{objArrToStrArr(business.products).join(', ')}
									</Box>
								</Box>

								<Box mt={6}>
									<Heading as={'h4'} fontSize={'1.1rem'}>
										Current Status
									</Heading>
									<Box mt={4}>{business?.current_status}</Box>
								</Box>

								<Box mt={6}>
									<Heading as={'h4'} fontSize={'1.1rem'}>
										Current Offers
									</Heading>
									<Box mt={4}>
										{objArrToStrArr(business?.current_offers, 'offer').join(
											', '
										)}
									</Box>
								</Box>

								<Box mt={6}>
									<Heading as={'h4'} fontSize={'1.1rem'}>
										Pricing
									</Heading>
									<Box mt={4}>{showPricing(business?.pricing || 1)}</Box>
								</Box>
							</SimpleGrid>
							<Box mt={6}>
								<Heading as={'h4'} fontSize={'1.1rem'}>
									Address and GEO Location
								</Heading>

								<Box mt={4}>
									<SimpleGrid columns={2}>
										<Stack spacing={2}>
											{business.address1 && (
												<Flex>
													<Text fontWeight={'500'} as={'span'} me={4}>
														Address 1:
													</Text>
													{business?.address1}
												</Flex>
											)}
											{business?.address2 && (
												<Flex>
													<Text fontWeight={'500'} as={'span'} me={4}>
														Address 2:
													</Text>
													{business?.address2}
												</Flex>
											)}
											{business?.city && (
												<Flex>
													<Text fontWeight={'500'} as={'span'} me={4}>
														City:
													</Text>
													{business?.city?.name}
												</Flex>
											)}
											{business?.state && (
												<Flex>
													<Text fontWeight={'500'} as={'span'} me={4}>
														State/Province:
													</Text>
													{business?.state?.name}
												</Flex>
											)}

											{business?.zip_code && (
												<Flex>
													<Text fontWeight={'500'} as={'span'} me={4}>
														Zip Code:
													</Text>
													{business?.zip_code}
												</Flex>
											)}
											{business?.country && (
												<Flex>
													<Text fontWeight={'500'} as={'span'} me={4}>
														Country:
													</Text>
													{business?.country?.name}
												</Flex>
											)}
											<Flex>
												<Text fontWeight={'500'} as={'span'} me={4}>
													Latitude:
												</Text>
												{business?.lat}
											</Flex>
											<Flex>
												<Text fontWeight={'500'} as={'span'} me={4}>
													Longitude:
												</Text>
												{business?.long}
											</Flex>
										</Stack>

										{business?.lat && business?.long && (
											<AspectRatio ratio={16 / 7}>
												<iframe
													title={business?.name}
													style={{ width: '100%', height: '100%' }}
													frameBorder="0"
													scrolling="no"
													marginHeight="0"
													marginWidth="0"
													src={
														'https://maps.google.com/maps?q=' +
														business?.lat +
														',' +
														business?.long +
														'&t=&z=15&ie=UTF8&iwloc=&output=embed'
													}
												/>
											</AspectRatio>
										)}
									</SimpleGrid>
								</Box>
							</Box>

							<Box mt={6}>
								<Heading as={'h4'} fontSize={'1.1rem'}>
									Social
								</Heading>

								<List mt={'4'} spacing={3}>
									{business?.facebook && (
										<ListItem>
											<ListIcon as={FaFacebook} color="facebook.500" />
											{business?.facebook}
										</ListItem>
									)}

									{business?.twitter && (
										<ListItem>
											<ListIcon as={FaTwitter} color="twitter.500" />
											{business?.twitter}
										</ListItem>
									)}
									{business?.youtube && (
										<ListItem>
											<ListIcon as={FaYoutube} color="red.500" />
											{business?.youtube}
										</ListItem>
									)}
									{business?.instagram && (
										<ListItem>
											<ListIcon as={FaInstagram} color="purple.500" />
											{business?.instagram}
										</ListItem>
									)}
									{business?.linkedin && (
										<ListItem>
											<ListIcon as={FaLinkedin} color="linkedin.500" />
											{business?.linkedin}
										</ListItem>
									)}
								</List>
							</Box>
						</Box>
					</Container>
				</Box>
			) : (
				<Box>
					<Container py={5} maxW={'container.xl'}>
						<Center h={'300px'}>
							<Box>
								<Spinner
									thickness="4px"
									speed="0.65s"
									emptyColor="gray.200"
									color="brand.500"
									size="xl"
								/>
								<Text textAlign={'center'}>Loading...</Text>
							</Box>
						</Center>
					</Container>
				</Box>
			)}
		</>
	)
}

export default BusinessDetails
