import { extendTheme } from '@chakra-ui/react'
import styles from './styles'
import fonts from './fonts'
import colors from './colors'
import Button from './components/button'
import Heading from './components/heading'
import Input from './components/input'

const theme = extendTheme({
	styles,
	fonts,
	colors,
	components: {
		Button,
		Heading,
		Input,
	},
})

export default theme
