import { Box, Flex, Text, Stack, Icon, Container } from '@chakra-ui/react'

import { CgProfile } from 'react-icons/cg'
import { RiLockPasswordLine } from 'react-icons/ri'
import { Outlet, Link, useLocation } from 'react-router-dom'

const UserProfile = () => {
	const location = useLocation()
	return (
		<Box w={'100%'} h={'100vh'} mb={'100px'}>
			<Container maxW={'container.lg'}>
				<Flex w={'100%'}>
					<Box display={['none', 'none', 'flex', 'flex',]} pt={'7'} w={'300px'} h={'100vh'}>
						<Stack
							mt={6}
							gap={4}
							fontWeight={'500'}
							color={'gray.700'}
						>
							<Text
								ps={'4'}
								size={'12px'}
								color={'gray'}
								letterSpacing={'.08rem'}
							>
								Profile
							</Text>
							<Text
								as={Link}
								display={'flex'}
								// fontSize={
								// 	location.pathname ===
								// 	'/profile/edit-profile'
								// 		? '1.1rem'
								// 		: '1rem'
								// }
								// fontWeight={
								// 	location.pathname ===
								// 	'/profile/edit-profile'
								// 		? '600'
								// 		: '500'
								// }
								color={location.pathname ===
								'/profile/edit-profile'?'brand.500':'gray.600'}
								alignItems={'center'}
								ps={'4'}
								to={`/profile/edit-profile`}
							>
								<Icon
									as={CgProfile}
									mb={'2px'}
									me="3"
									opacity={
										location.pathname ===
										'/profile/edit-profile'
											? '1'
											: '0.8'
									}
								/>
								<Text as="span">Edit Profile</Text>
							</Text>

							<Text
								as={Link}
								display={'flex'}
								// fontSize={
								// 	location.pathname ===
								// 	'/profile/change-password'
								// 		? '1.1rem'
								// 		: '1rem'
								// }
								// fontWeight={
								// 	location.pathname ===
								// 	'/profile/change-password'
								// 		? '600'
								// 		: '500'
								// }
								color={location.pathname ===
									'/profile/change-password'?'brand.500':'gray.600'}
								alignItems={'center'}
								ps={'4'}
								to={`/profile/change-password`}
							>
								<Icon
									as={RiLockPasswordLine}
									mb={'2px'}
									me="3"
									opacity={
										location.pathname ===
										'/profile/change-password'
											? '1'
											: '0.8'
									}
								/>
								<Text as="span">Change Password</Text>
							</Text>
						</Stack>
					</Box>

					<Box p={10} w={'100%'}>
						<Outlet />
					</Box>
				</Flex>
			</Container>
		</Box>
	)
}

export default UserProfile
