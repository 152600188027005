import {
	Box,
	Button,
	Heading,
	Stack,
	Text,
	FormControl,
	Input,
	Flex,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
	Icon,
	FormLabel,
	useToast,
} from '@chakra-ui/react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'

import AuthLayout from '../../layouts/AuthLayout'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { postRequest } from '../../services/base.service'

const AuthForgetPassword = () => {
	const [doesEmailSent, setDoesEmailSent] = useState(false)
	const [serverErrors, setServerErrors] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	
	const { state } = useLocation()
	const [redirectPath] = useState(state?.from?.pathname || '/')
	const { user } = useSelector((state) => state.auth)
	const { loginErrors } = useSelector((state) => state.auth)
	const { loading } = useSelector((state) => state.auth)
	const navigate = useNavigate()
	const toast = useToast()
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()

	const handleOnSubmit = (data) => {
		setIsLoading(true)
		// dispatch(login(data, 'login', (message, status) => {}))
		postRequest('forget-password', { email: data.email })
			.then((res) => {
				setIsLoading(false)
				setDoesEmailSent(true)
				setServerErrors(null)
				console.log('RES: ', res)
				// navigate('/reset-password')
			})
			.catch((err) => {
				setIsLoading(false)
				setDoesEmailSent(false)
				console.log('ERR: ', err)
				setServerErrors(err.response?.data?.errors)
				
				toast({
					description: 'Something went wrong',
					status: 'error',
					duration: 5000,
					isClosable: true,
				})
			})
	}

	if (user) {
		if (user?.role !== 'admin') return <Navigate to={redirectPath} />
		return <Navigate to="/admin/dashboard" />
	}

	return (
		<AuthLayout>
			<Box mx={'auto'} maxW={'420px'}>
				<Text
					as={Link}
					to={'/'}
					mb={'3'}
					fontWeight={500}
					color={'gray.500'}
					display={'flex'}
					alignItems={'center'}
					textTransform={'uppercase'}
				>
					<Icon as={FaLongArrowAltLeft} me={3} /> Back to home
				</Text>
				<Heading as="h3" fontSize={'28px'}>
					Forget Password
				</Heading>
				<Flex height="70vh" align={'center'}>
					<Stack
						// mt={'100px'}
						gap={'3'}
						as="form"
						onSubmit={handleSubmit(handleOnSubmit)}
					>
						<FormControl>
							<Text fontSize={'sm'} fontWeight={'500'}>
								Please enter your registered email id.
							</Text>
							<Text fontSize={'sm'} as="em">
								We will send a verification code to your
								registered email id.
							</Text>
							{doesEmailSent && (
								<Alert status="success" mb={'5'}>
									<AlertIcon />
									<Box>
										<AlertTitle mr={2}>
											Email Sent
										</AlertTitle>
										<AlertDescription>
											An email has been sent to your email
											to reset password.
										</AlertDescription>
									</Box>
								</Alert>
							)}
							{serverErrors?.email[0] && (
								<Alert status="error" mb={'5'}>
									<AlertIcon />
									<Box>
										<AlertTitle mr={2}>
											Error
										</AlertTitle>
										<AlertDescription>
											{serverErrors?.email[0]}
										</AlertDescription>
									</Box>
								</Alert>
							)}
							<FormLabel mt={9} htmlFor="email">
								Email address
							</FormLabel>
							<Input
								size={'lg'}
								id="email"
								type="email"
								placeholder="Email Address"
								
								isInvalid={errors.email?.type === 'required' || serverErrors?.email[0]}
								{...register('email', {
									required: true,
									pattern: {
										value: /\S+@\S+\.\S+/,
										message:
											'Entered value does not match email format',
									},
								})}
							/>
							<Text color="red" fontSize="sm" mt="2">
								{errors.email?.type === 'required' &&
									'Email is required'}
							</Text>
							{errors.email?.message && (
								<Text color="red" fontSize={'sm'}>
									{errors.email?.message}
								</Text>
							)}
							{serverErrors?.email[0] && (
								<Text color="red" fontSize={'sm'}>
									{serverErrors?.email[0]}
								</Text>
							)}
							{/* <FormHelperText>We'll never share your email.</FormHelperText> */}
							{/* <FormErrorMessage>Hello world!</FormErrorMessage> */}
						</FormControl>

						<Button
							type="submit"
							w={'full'}
							size={'lg'}
							variant={'brand'}
							isLoading={isLoading}
							loadingText="Submitting..."
						>
							Submit
						</Button>

						<Button
							as={Link}
							to={'/signin'}
							w={'full'}
							size={'lg'}
							variant={'outline'}
							borderColor={'brand.500'}
							color={'brand.500'}
						>
							Go Back To Sign In
						</Button>

						{/* <Text>
							Already have an account.{' '}
							<Text as={Link} color={'brand.500'} to="/signin">
								Sign In
							</Text>
						</Text> */}
					</Stack>
				</Flex>
			</Box>
		</AuthLayout>
	)
}

export default AuthForgetPassword
