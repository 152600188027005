import { Center, Text } from '@chakra-ui/react'

const AppFooter = () => {
	return (
		<Center h={'40px'}>
			<Text fontSize={'16px'}>&copy; 2022 RBD. All rights reserved</Text>
		</Center>
	)
}

export default AppFooter
