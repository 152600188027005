import { AspectRatio, Box, Image, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const CityCard = ({ data }) => {
	return (
		<Box
			as={Link}
			to={`/city/${data?.slug}`}
			display={'block'}
			_hover={{
				'.locationInfo::before': {
					backdropFilter: 'saturate(120%) blur(5px)',
				},
				img: {
					transform: 'scale(1.1)',
					bg: 'blackAlpha.700',
				},
			}}
			pos={'relative'}
		>
			<AspectRatio overflow={'hidden'} rounded={10}>
				<Image
					rounded={10}
					h={'100%'}
					w={'100%'}
					transition={'all .2s'}
					objectFit={'cover'}
					src={
						data?.banner ||
						'https://media.istockphoto.com/photos/evening-view-of-skyscraper-in-dhaka-picture-id1181657227?k=20&m=1181657227&s=170667a&w=0&h=EL6S2Qm9FfLpvquslsYkDdvVyVqJUvpKoxS9ay7gSZs='
					}
				/>
			</AspectRatio>

			<Box
				className={'locationInfo'}
				pos={'absolute'}
				color="white"
				bottom={'10px'}
				py={2}
				px={3}
				w={'calc(100% - 20px)'}
				left={'10px'}
				_before={{
					content: "''",
					pos: 'absolute',
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
					bg: 'blackAlpha.400',
					transition: 'all .2s',
					zIndex: 0,
					rounded: 10,
					overflow: 'hidden',
					border: '1px solid',
					borderColor: 'blackAlpha.300',
					// backdropFilter: 'saturate(120%) blur(5px)',
				}}
			>
				<Box pos={'relative'} zIndex={9}>
					<Text
						fontSize={['1.15rem', '1.25rem', '1.35rem', '1.35rem']}
						noOfLines={1}
						fontWeight={500}
					>
						{data?.name}
					</Text>
					<Text opacity={'0.75'}>
						{data?.businesses_count} Business
						{data?.businesses_count > 1 && 'es'}
					</Text>
				</Box>
			</Box>
		</Box>
	)
}

export default CityCard
