import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	Heading,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	Text,
} from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiShow, BiHide } from 'react-icons/bi'
import { postRequest } from '../services/base.service'

const ChangePassword = () => {
	const [showPass, setShowPass] = useState({
		currentPass: false,
		newPass: false,
		confirmPass: false,
	})

	const [doesPassMatched, setDoesPassMatched] = useState(true)
	const [isPasswordChanged, setIsPasswordChanged] = useState(true)
	const [serverErrors, setServerErrors] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			current_password: '',
			new_password: '',
			confirm_password: '',
		},
	})
	const toast = useToast()

	const handleChangePassword = (data) => {
		setIsLoading(true)
		setDoesPassMatched(true)
		if (data.new_password === data.confirm_password) {
			postRequest('change-password', {
				current_password: data.current_password,
				new_password: data.new_password,
			})
				.then((res) => {
					setDoesPassMatched(true)
					setIsPasswordChanged(true)
					setServerErrors(null)

					toast({
						description: 'Successfully changed password.',
						status: 'success',
						duration: 5000,
						isClosable: true,
					})
					reset({
						current_password: '',
						new_password: '',
						confirm_password: '',
					})
					setIsLoading(false)
				})
				.catch((err) => {
					console.log('ERR: ', err)
					setIsPasswordChanged(false)

					setServerErrors(err.response?.data?.errors)
					toast({
						description: 'Something went wrong',
						status: 'error',
						duration: 5000,
						isClosable: true,
					})
					setIsLoading(false)
				})
		} else {
			setDoesPassMatched(false)
			setIsLoading(false)
		}
	}
	return (
		<Box>
			<Heading as="h3" fontWeight={'700'} fontSize={'20px'}>
				Change Password
			</Heading>
			<Grid
				templateColumns="repeat(5, 1fr)"
				mt={10}
				gap={'3'}
				as="form"
				onSubmit={handleSubmit(handleChangePassword)}
			>
				<GridItem colSpan={[5, 5, 3]}>
					<FormControl>
						<FormLabel htmlFor="currentPassword">
							Current Password
						</FormLabel>
						<InputGroup size={'lg'}>
							<Input
								size={'lg'}
								id="currentPassword"
								type={
									showPass.currentPass ? 'text' : 'password'
								}
								placeholder="Current Password"
								{...register('current_password', {
									required: true,
								})}
							/>
							<InputRightElement>
								<Icon
									_hover={{ cursor: 'pointer' }}
									as={showPass.currentPass ? BiHide : BiShow}
									onClick={() =>
										setShowPass({
											...showPass,
											currentPass: !showPass.currentPass,
										})
									}
								/>
							</InputRightElement>
						</InputGroup>
						<Text color="red" fontSize="sm" mt="2">
							{errors.current_password?.type === 'required' &&
								'This field is required'}
						</Text>
						{serverErrors?.current_password && (
							<Text fontSize="sm" mt="2" color="red.500">
								{serverErrors?.current_password}
							</Text>
						)}
					</FormControl>
				</GridItem>
				<GridItem colSpan={[5, 5, 3]}>
					<FormControl>
						<FormLabel htmlFor="newPassword">
							New Password
						</FormLabel>
						<InputGroup size={'lg'}>
							<Input
								size={'lg'}
								id="newPassword"
								type={showPass.newPass ? 'text' : 'password'}
								placeholder="New Password"
								{...register('new_password', {
									required: true,
								})}
							/>
							<InputRightElement>
								<Icon
									_hover={{ cursor: 'pointer' }}
									as={showPass.newPass ? BiHide : BiShow}
									onClick={() =>
										setShowPass({
											...showPass,
											newPass: !showPass.newPass,
										})
									}
								/>
							</InputRightElement>
						</InputGroup>

						<Text color="red" fontSize="sm" mt="2">
							{errors.new_password?.type === 'required' &&
								'This field is required'}
						</Text>

						{serverErrors?.new_password && (
							<Text fontSize="sm" mt="2" color="red.500">
								{serverErrors?.new_password}
							</Text>
						)}
					</FormControl>
				</GridItem>
				<GridItem colSpan={[5, 5, 3]}>
					<FormControl>
						<FormLabel htmlFor="confirmPass">
							Confirm Password
						</FormLabel>
						<InputGroup size={'lg'}>
							<Input
								size={'lg'}
								id="confirmPass"
								type={
									showPass.confirmPass ? 'text' : 'password'
								}
								placeholder="Confirm Password"
								{...register('confirm_password', {
									required: true,
								})}
							/>
							<InputRightElement>
								<Icon
									_hover={{ cursor: 'pointer' }}
									as={showPass.confirmPass ? BiHide : BiShow}
									onClick={() =>
										setShowPass({
											...showPass,
											confirmPass: !showPass.confirmPass,
										})
									}
								/>
							</InputRightElement>
						</InputGroup>

						<Text color="red" fontSize="sm" mt="2">
							{errors.confirm_password?.type === 'required'
								? 'This field is required'
								: !doesPassMatched
								? 'Password not matched with new password field'
								: ''}
						</Text>

					</FormControl>
				</GridItem>
				<GridItem colSpan={[5, 5, 3]} align="right">
					<Button
						mt={6}
						type={'submit'}
						variant="brand"
						isLoading={isLoading}
						loadingText={'SUBMITTING...'}
					>
						SUBMIT
					</Button>
				</GridItem>
			</Grid>
		</Box>
	)
}

export default ChangePassword
