import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ChakraProvider } from '@chakra-ui/react'
import { Provider } from 'react-redux'
import axios from 'axios'
import store from './redux/store'
import theme from './theme'

// Font family
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

axios.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error?.response?.status === 401) {
			localStorage.removeItem('user')
			window.location.reload()
		}

		return Promise.reject(error)
	}
)

ReactDOM.render(
	<Provider store={store}>
		<ChakraProvider theme={theme}>
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</ChakraProvider>
	</Provider>,
	document.getElementById('root')
)

reportWebVitals()
