import React, { Fragment, useEffect, useState } from 'react'
import {
	AspectRatio,
	Box,
	Button,
	Flex,
	Heading,
	Image,
	List,
	ListIcon,
	ListItem,
	Stack,
	Text,
	Spinner,
	Center,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	useDisclosure,
	Avatar,
	Divider,
	Popover,
	PopoverHeader,
	PopoverArrow,
	PopoverCloseButton,
	PopoverBody,
	PopoverFooter,
	ButtonGroup,
	PopoverTrigger,
	PopoverContent,
	ModalCloseButton,
	useToast,
	Badge,
} from '@chakra-ui/react'
import { FiFile } from 'react-icons/fi'
import AdminBreadcrumb from '../../components/AdminBreadcrumb'
import { getRequest, postRequest } from '../../services/base.service'
import { useParams, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setAdminSidebarFalseOnUrlChange, setCurrentPageHeading } from '../../redux/common/commonActions'

const AdminClaimedRequests = () => {
	const [claimList, setClaimList] = useState([])
	const [userInfo, setUserInfo] = useState(null)
	const [businessInfo, setBusinessInfo] = useState(null)
	const [spinner, setSpinner] = useState({ status: true })
	const toast = useToast()

	const { isOpen, onOpen, onClose } = useDisclosure()
	const params = useParams()
	const [searchParams] = useSearchParams()
	const dispatch = useDispatch()

	useEffect(() => {
		const { id } = params
		fetchBusinessClaims(id)
		console.log('dfjldk')
		dispatch(setCurrentPageHeading('Claimed Requests'))
		dispatch(setAdminSidebarFalseOnUrlChange())
	}, [])

	const fetchBusinessClaims = (id) => {
		getRequest(`admin/business/claims/${id}`)
			.then((res) => {
				setClaimList(res.result?.claims)
				setUserInfo(res.result?.user)
				setBusinessInfo({
					name: res.result?.name,
					email: res.result?.email,
					phone: res.result?.phone,
					website: res.result?.website,
					logo: res.result?.logo,
				})
				setSpinner({ status: false })
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const breadCrumb = [
		{ label: 'Dashboard', isCurrentPage: false, link: '/admin/dashboard' },
		{
			label: 'Claimed List',
			isCurrentPage: false,
			link: '/admin/claimed-list',
		},
		{
			label: businessInfo?.name,
			isCurrentPage: false,
			link: `/admin/claimed-list/details/${params?.id}`,
		},
		{
			label: 'Claimed Requests',
			isCurrentPage: true,
		},
	]

	const handleOwnershipChange = (status, claim_id) => {
		console.log(claim_id)
		const clonedClaimList = [...claimList]
		const claimObj = clonedClaimList.filter((obj) => obj.id === claim_id)
		const index = clonedClaimList.indexOf(claimObj[0])
		clonedClaimList.splice(index, 1)
		const decline_ids = clonedClaimList.map((item) => item.id)

		postRequest(`admin/claim/status/update`, {
			claim_id,
			status,
			decline_ids,
		})
			.then((res) => {
				toast({
					title: 'Status Changed.',
					description: res.message,
					status: 'success',
					duration: 5000,
					isClosable: true,
				})
				const { id } = params
				fetchBusinessClaims(id)
				onClose()
			})
			.catch((error) => {
				toast({
					title: 'Error',
					description: error.response?.data?.message || 'Something went wrong',
					status: 'error',
					duration: 5000,
					isClosable: true,
				})
			})
	}

	const statusColorScheme = (status) => {
		if (status === 'pending') {
			return 'yellow'
		} else if (status === 'declined') {
			return 'red'
		}
		return 'green'
	}

	return (
		<>
			<AdminBreadcrumb items={breadCrumb} />

			<Modal zIndex={'9999999'} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Change Ownership</ModalHeader>
					<ModalCloseButton />

					<ModalBody>
						<Stack spacing={'4'}>
							{claimList.map((item, index) => (
								<Fragment key={index}>
									<Flex>
										<Avatar w={'36px'} h={'36px'} />
										<Flex
											ps={3}
											justify={'space-between'}
											w={'calc(100% - 36px)'}
										>
											<Text
												fontSize={'1.125rem'}
												overflow={'hidden'}
												textOverflow={'ellipsis'}
												whiteSpace={'nowrap'}
												fontWeight={500}
												w={'calc(100% - 120px)'}
											>
												{item.user.name}{' '}
												{item?.user?.id === userInfo?.id && (
													<Badge ms="1" colorScheme={'blue'}>
														Owned
													</Badge>
												)}
											</Text>

											<Popover placement={'bottom-end'}>
												<PopoverTrigger>
													<Button
														size={'sm'}
														isDisabled={item?.user?.id === userInfo?.id}
														variant={'outline'}
														colorScheme={'green'}
													>
														MAKE OWNER
													</Button>
												</PopoverTrigger>

												<PopoverContent>
													<PopoverHeader fontWeight="semibold">
														Confirmation
													</PopoverHeader>
													<PopoverArrow />

													<PopoverBody>
														Are you sure you want to continue with your action?
													</PopoverBody>
													<PopoverFooter d="flex" justifyContent="flex-end">
														<ButtonGroup size="sm">
															<Button
																as={PopoverCloseButton}
																w={'60px'}
																mt={'-4px'}
															>
																No
															</Button>

															<Button
																onClick={() =>
																	handleOwnershipChange('approve', item?.id)
																}
																variant={'brand'}
																w={'60px'}
															>
																Yes
															</Button>
														</ButtonGroup>
													</PopoverFooter>
												</PopoverContent>
											</Popover>
										</Flex>
									</Flex>
									<Divider />
								</Fragment>
							))}
						</Stack>
					</ModalBody>
				</ModalContent>
			</Modal>

			<Box py={8}>
				<Flex
					pb={6}
					borderBottomWidth={'1px'}
					borderBottomStyle={'solid'}
					borderBottomColor={'gray.300'}
					flexDirection={['column', 'column', 'column', 'row']}
					align={'center'}
					textAlign={'center'}
				>
					<AspectRatio w={'200px'} h={'200px'} ratio={1 / 1} bg={'gray.100'}>
						<Image
							w="100%"
							h="100%"
							objectFit={'contain'}
							p={3}
							src={businessInfo?.logo}
							alt="abc"
						/>
					</AspectRatio>

					<Box
						// w={'calc(100% - 200px)'}
						ps={[0, 0, 0, 6]}
						textAlign="center"
					>
						<Flex
							justify={['center', 'center', 'center', 'start']}
							align={'center'}
						>
							<Heading as={'h2'} fontSize={'1.5rem'}>
								{businessInfo?.name}
							</Heading>
						</Flex>

						<Stack
							mt="3"
							justify={['center', 'center', 'center', 'space-between']}
							textAlign="center"
						>
							<Flex justify={['center', 'center', 'center', 'left']}>
								<Text fontWeight={'500'}>Owned By: </Text>
								<Flex ml={'3'}>
									{userInfo?.name}

									<Button
										display={['none', 'none', 'none', 'flex']}
										ms="4"
										variant={'outline'}
										size={'xs'}
										onClick={onOpen}
										colorScheme={'red'}
									>
										Change Ownership
									</Button>
								</Flex>
							</Flex>
							<Flex justify={['center', 'center', 'center', 'left']}>
								<Text fontWeight={'500'}>Website: </Text>
								<Text
									ml={'3'}
									as={'a'}
									target={'_blank'}
									href={businessInfo?.website}
								>
									{businessInfo?.website}
								</Text>
							</Flex>

							<Flex justify={['center', 'center', 'center', 'left']}>
								<Text fontWeight={'500'}>Phone: </Text>
								<Text ml={'3'}>{businessInfo?.phone}</Text>
							</Flex>

							<Flex justify={['center', 'center', 'center', 'left']}>
								<Text fontWeight={'500'}>Email: </Text>
								<Text ml={'3'}>{businessInfo?.email}</Text>
							</Flex>
						</Stack>
					</Box>
					<Button
					display={['flex', 'flex', 'flex', 'none']}
					justify="center"
					textAlign={'center'}
					mt={'3'}
					variant={'outline'}
					size={'xs'}
					onClick={onOpen}
					colorScheme={'red'}
				>
					Change Ownership
				</Button>
				</Flex>
				
				<Box mt={6}>
					<Heading as={'h4'} fontSize={'1.3rem'}>
						Claimed Request List
					</Heading>

					<Stack mt="6" spacing={6}>
						{spinner.status ? (
							<Center>
								<Spinner color="red.500" size="xl" speed="0.6s" />
							</Center>
						) : (
							claimList?.map((claim, index) => (
								<Box
									p={6}
									borderWidth={'1px'}
									borderColor={
										searchParams.get('cId') !== claim.id.toString()
											? 'gray.300'
											: 'brand.300'
									}
									bg={
										searchParams.get('cId') !== claim.id.toString()
											? 'white'
											: 'brand.50'
									}
									borderStyle={'solid'}
									key={index}
								>
									<Heading fontSize={'1.1rem'} as="h5">
										{claim?.user?.name}{' '}
										<Badge
											colorScheme={statusColorScheme(claim?.status)}
											ms={'4'}
										>
											{claim?.status}
										</Badge>
									</Heading>
									<Stack mt={'3'} spacing={1}>
										<Flex>
											<Text fontWeight={'500'} as={'span'} me={4}>
												Email:
											</Text>
											{claim?.email}
										</Flex>
										<Flex>
											<Text fontWeight={'500'} as={'span'} me={4}>
												Phone:
											</Text>
											{claim?.phone}
										</Flex>
										<Flex>
											<Text fontWeight={'500'} as={'span'} me={4}>
												WhatsApp:
											</Text>
											{claim?.whatsapp_no}
										</Flex>

										<Box>
											<Text fontWeight={'500'} as={'span'} me={4}>
												Supportive Documents
											</Text>

											<List spacing={1} mt={2}>
												{claim?.documents?.map((doc, index) => (
													<ListItem
														fontSize={'0.9rem'}
														key={index}
														as={'a'}
														href={doc?.file}
														display={'block'}
														target={'_blank'}
													>
														<ListIcon as={FiFile} color="brand.500" />
														{doc?.document_name}
													</ListItem>
												))}
											</List>
										</Box>
									</Stack>
								</Box>
							))
						)}
					</Stack>
				</Box>
			</Box>
		</>
	)
}

export default AdminClaimedRequests
