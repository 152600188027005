import React, { useState } from 'react'
import {
	Box,
	Flex,
	Grid,
	GridItem,
	Heading,
	Stack,
	Text,
	Spinner,
	Center,
	Alert,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import AdminBlockCard from '../../components/AdminBlockCard'
import AdminBusinessCard from '../../components/AdminBusinessCard'
import AdminStatsCard from '../../components/AdminStatsCard'
import { BsArrowRight } from 'react-icons/bs'
import 'react-phone-number-input/style.css'
import { getRequest } from '../../services/base.service'
import { useDispatch } from 'react-redux'
import { setAdminSidebarFalseOnUrlChange, setCurrentPageHeading } from '../../redux/common/commonActions'

const AdminDashboard = () => {
	const [dashboardData, setDashboardData] = useState({
		signup_today: 0,
		pending_approval: 0,
		pending_claim: 0,
	})
	const [businessList, setBusinessList] = useState([])
	const [spinner, setSpinner] = useState({ status: true })
	const dispatch = useDispatch()
	
	React.useEffect(() => {
		dispatch(setCurrentPageHeading('Dashboard'))
		dispatch(setAdminSidebarFalseOnUrlChange())
		getRequest('admin/dashboard')
			.then((res) => {
				setDashboardData(res.result)
			})
			.then((data) => {})
		getRequest('admin/businesses', { params: { status: 'pending', limit: 10 } })
			.then((res) => {
				setBusinessList(res.result?.data)
				setSpinner({ status: false })
			})
			.then((data) => {})
	}, [])

	const CardAction = () => (
		<Box color={'red.500'}>
			<Text as={Link} to="/admin/business-list" fontSize={'sm'}>
				<Flex align={'center'}>
					View All
					<BsArrowRight />
				</Flex>
			</Text>
		</Box>
	)

	return (
		<Box py={'7'}>
			<Heading fontSize="xl">Quick Stats</Heading>

			<Grid
				templateColumns={[
					'repeat(1, 1fr)',
					'repeat(3, 1fr)',
					'repeat(2, 1fr)',
					'repeat(3, 1fr)',
				]}
				gap={4}
				mt={2}
			>
				<GridItem w="100%">
					<AdminStatsCard
						title={'Sign Ups Today'}
						value={dashboardData?.signup_today}
						mb={'10'}
					/>
				</GridItem>
				<GridItem>
					<AdminStatsCard
						title={'Pending Approvals'}
						value={dashboardData?.pending_approval}
						mb={'10'}
					/>
				</GridItem>
				<GridItem>
					<AdminStatsCard
						title={'Pending Claims'}
						value={dashboardData?.pending_claim}
						mb={'10'}
					/>
				</GridItem>
			</Grid>

			<Box mt="10">
				<AdminBlockCard title={'Pending Requests'} actions={<CardAction />}>
					{spinner.status && (
						<Center>
							<Spinner color="red.500" size="xl" speed="0.6s" />
						</Center>
					)}
					{businessList.length > 0 ? (
						<Stack gap={2}>
							{businessList?.map((business, index) => (
								<AdminBusinessCard
									key={index}
									companyName={business?.name}
									data={business}
								/>
							))}
						</Stack>
					) : (
						<Alert status="error">
							<Center h={'100%'} w={'100%'}>
								NO DATA FOUND.
							</Center>
						</Alert>
					)}
				</AdminBlockCard>
			</Box>
		</Box>
	)
}

export default AdminDashboard
