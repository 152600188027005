import {
	Box,
	Center,
	Container,
	Grid,
	GridItem,
	Heading,
	Icon,
	Text,
} from '@chakra-ui/react'
import { FiAirplay } from 'react-icons/fi'
import HomeSearchForm from '../components/HomeSearchForm'
import TopBusinessInLocation from '../components/TopBusinessInLocation'
import TopLocations from '../components/TopLocations'

const AppHome = () => {
	return (
		<>
			<Box
				bgImg={'/hero_bg.jpg'}
				py={['40px', '60px', '80px', '100px']}
				bgRepeat={'no-repeat'}
				bgSize={'cover'}
				bgPos={'center'}
			>
				<Container maxW={'container.xl'}>
					<Center justifyContent={'center'}>
						<Box maxW={'800px'} w="100%">
							<Box
								p={6}
								rounded={10}
								pos={'relative'}
								_before={{
									content: "''",
									pos: 'absolute',
									top: 0,
									right: 0,
									bottom: 0,
									left: 0,
									bg: 'whiteAlpha.900',
									zIndex: 0,
									rounded: 10,
									overflow: 'hidden',
									backdropFilter: 'saturate(180%) blur(20px)',
								}}
							>
								<Box pos={'relative'} zIndex={'10'}>
									<Box textAlign={['center', 'center', 'left', 'left']}>
										<Heading
											lineHeight={1.35}
											fontSize={['1.5rem', '1.5rem', '1.8rem', '2rem']}
											as={'h1'}
										>
											Search Any Business Anytime, Anywhere in the World
										</Heading>
										<Text mt={4} mb={5}>
											In publishing and graphic design, Lorem ipsum is a
											placeholder text commonly used to demonstrate the visual
										</Text>
									</Box>
									<HomeSearchForm />
								</Box>
							</Box>
						</Box>
					</Center>
				</Container>
			</Box>

			{/* Top Business in Location */}
			<TopBusinessInLocation />

			{/* Application Feature */}
			<Box>
				<Container maxW={'container.xl'}>
					<Box
						rounded={10}
						color={'brand.50'}
						bg={'brand.500'}
						py={'60px'}
						px={8}
						minH={'420px'}
					>
						<Box mb={10} textAlign={['center', 'center', 'left', 'left']}>
							<Heading color={'white'} fontSize={'1.75rem'} as={'h3'}>
								Why You Add Your Business
							</Heading>
							<Text mt={'3'}>
								Lorem ipsum is a placeholder text commonly used
							</Text>
						</Box>

						<Grid
							templateColumns={[
								'repeat(1, 1fr)',
								'repeat(1, 1fr)',
								'repeat(2, 1fr)',
								'repeat(3, 1fr)',
							]}
							gap={8}
						>
							<GridItem>
								<Box textAlign={['center', 'center', 'left', 'left']}>
									<Icon
										fontSize={'2.5rem'}
										color={'brand.200'}
										as={FiAirplay}
									/>
									<Text size={'1.35rem'} my={3} fontWeight={500} color="white">
										Feature one
									</Text>
									<Text>
										In some layouts, you may need certain grid items to span
										specific amount of columns or rows instead of an even
										distribution.
									</Text>
								</Box>
							</GridItem>

							<GridItem>
								<Box textAlign={['center', 'center', 'left', 'left']}>
									<Icon
										fontSize={'2.5rem'}
										color={'brand.200'}
										as={FiAirplay}
									/>
									<Text size={'1.35rem'} my={3} fontWeight={500} color="white">
										Feature one
									</Text>
									<Text>
										In some layouts, you may need certain grid items to span
										specific amount of columns or rows instead of an even
										distribution.
									</Text>
								</Box>
							</GridItem>

							<GridItem>
								<Box textAlign={['center', 'center', 'left', 'left']}>
									<Icon
										fontSize={'2.5rem'}
										color={'brand.200'}
										as={FiAirplay}
									/>
									<Text size={'1.35rem'} my={3} fontWeight={500} color="white">
										Feature one
									</Text>
									<Text>
										In some layouts, you may need certain grid items to span
										specific amount of columns or rows instead of an even
										distribution.
									</Text>
								</Box>
							</GridItem>
						</Grid>
					</Box>
				</Container>
			</Box>

			{/* Worldwide Top Locations */}
			<TopLocations />

			{/* <Center h={'calc(100vh - 110px)'}>
				<Container maxW={'container.md'}>
					<Center mb="8">
						<Center bg={'brand.500'} w={'120px'} h={'120px'}>
							<Image
								w={'90px'}
								src={
									'https://rentmy.co/wp-content/uploads/2019/11/logo-white.png'
								}
							/>
						</Center>
					</Center>
					<HomeSearchForm />
				</Container>
			</Center> */}
		</>
	)
}

export default AppHome
