import { Box, Flex } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import BottomNavigation from '../components/BottomNavigation'
import AppFooter from './AppFooter'
import AppHeader from './AppHeader'

const AppLayout = () => {
	return (
		<Flex minH={'100vh'} direction={'column'} pt={'70px'} bg={'white'}>
			<AppHeader />
			<Box minH={'calc(100vh - 110px)'}>
				<Outlet />
			</Box>
			<AppFooter />
			<BottomNavigation />
		</Flex>
	)
}

export default AppLayout
