import {
	Box,
	Button,
	Heading,
	Stack,
	Text,
	FormControl,
	// FormLabel,
	// FormErrorMessage,
	// FormHelperText,
	Input,
	Flex,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
	Icon,
	InputGroup,
	InputRightElement,
} from '@chakra-ui/react'
import { Link, Navigate, useLocation } from 'react-router-dom'
import LabelDivider from '../../components/LabelDivider'
import AuthLayout from '../../layouts/AuthLayout'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../redux/auth/authActions'
import { useState } from 'react'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { BiShow, BiHide } from 'react-icons/bi'

import SocialAuthenticationCard from '../../components/SocialAuthenticationCard'
const AuthSignin = () => {
	const { state } = useLocation()
	const [redirectPath] = useState(state?.from?.pathname || '/')
	const dispatch = useDispatch()
	const { user } = useSelector((state) => state.auth)
	const { loginErrors } = useSelector((state) => state.auth)
	const { loading } = useSelector((state) => state.auth)
	const [showPass, setShowPass] = useState(false)

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()

	const handleSignin = (data) => {
		dispatch(login(data, 'login', (message, status) => {}))
	}

	if (user) {
		if (user?.role !== 'admin') return <Navigate to={redirectPath} />
		return <Navigate to="/admin/dashboard" />
	}

	return (
		<AuthLayout>
			<Box mx={'auto'} maxW={'420px'}>
				<Text
					as={Link}
					to={'/'}
					mb={'3'}
					fontWeight={500}
					color={'gray.500'}
					display={'flex'}
					alignItems={'center'}
					textTransform={'uppercase'}
				>
					<Icon as={FaLongArrowAltLeft} me={3} /> Back to home
				</Text>
				<Heading as="h3" fontSize={'28px'}>
					Sign In
				</Heading>
				<Text mt="2">Welcome back! Please sign in to continue.</Text>

				<SocialAuthenticationCard />

				<LabelDivider mt={'5'} mb={'5'} text={'or'} />
				{loginErrors?.email[0] && (
					<Alert status="error" mb={'5'}>
						<AlertIcon />
						<Box>
							<AlertTitle mr={2}>Login Error</AlertTitle>
							<AlertDescription>{loginErrors?.email[0]}</AlertDescription>
						</Box>
					</Alert>
				)}
				<Stack gap={'3'} as="form" onSubmit={handleSubmit(handleSignin)}>
					<FormControl>
						{/* <FormLabel htmlFor="email">Email address</FormLabel> */}
						<Input
							size={'lg'}
							id="email"
							type="email"
							placeholder="Email Address"
							isInvalid={errors.email?.type === 'required'}
							{...register('email', {
								required: true,
								pattern: {
									value: /\S+@\S+\.\S+/,
									message: 'Entered value does not match email format',
								},
							})}
						/>
						<Text color="red">
							{errors.email?.type === 'required' && 'Email is required'}
						</Text>
						{errors.email?.message && (
							<Text color="red">{errors.email?.message}</Text>
						)}
						{/* <FormHelperText>We'll never share your email.</FormHelperText> */}
						{/* <FormErrorMessage>Hello world!</FormErrorMessage> */}
					</FormControl>
					<FormControl>
						<Flex alignItems={'center'} justify={'space-between'}>
							{/* <FormLabel htmlFor="password">Password</FormLabel> */}
							{/* <Text
								as="button"
								mb="2"
								color="brand.500"
								textDecoration={'underline'}
							>
								Forget password?
							</Text> */}
						</Flex>
						<InputGroup size={'lg'}>
							<Input
								size={'lg'}
								id="password"
								type={showPass ? 'text' : 'password'}
								placeholder="Password"
								isInvalid={errors.password?.type === 'required'}
								{...register('password', { required: true })}
							/>
							<InputRightElement>
								<Icon
									_hover={{ cursor: 'pointer' }}
									as={showPass ? BiHide : BiShow}
									onClick={() => setShowPass(!showPass)}
								/>
							</InputRightElement>
						</InputGroup>
						<Text color="red">
							{errors.password?.type === 'required' && 'Password is required'}
						</Text>
						<Text
							as={Link}
							to={'/forget-password'}
							mt="2"
							color="brand.500"
							textDecoration={'underline'}
						>
							Forget password?
						</Text>
					</FormControl>

					<Text fontSize={'sm'} as="em" color={'gray.500'}>
						By proceeding, you agree to trems of Services and Police of the
						RentMy platform
					</Text>

					<Button
						type="submit"
						w={'full'}
						size={'lg'}
						variant={'brand'}
						isLoading={loading}
						loadingText="Signing in..."
					>
						Sign In
					</Button>

					<Text>
						Don't have an account?{' '}
						<Text as={Link} color={'brand.500'} to="/register">
							Create an account
						</Text>
					</Text>
				</Stack>
			</Box>
		</AuthLayout>
	)
}

export default AuthSignin
