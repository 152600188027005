import {
	CLEAR_ERR_MSG,
	LOGIN_ERROR,
	LOGIN_REQUESTING,
	LOGIN_SUCCESS,
	LOGOUT_ERROR,
	LOGOUT_REQUESTING,
	LOGOUT_SUCCESS,
	PROFILE_UPDATE,
	USER_REGISTER_ERROR,
	USER_REGISTER_REQUESTING,
	USER_REGISTER_SUCCESS,
} from './actionTypes'

const initialState = {
	loading: false,
	successMsg: null,
	errMsg: null,
	user: localStorage.getItem('user')
		? JSON.parse(localStorage.getItem('user'))
		: null,
	regErrors: null,
	loginErrors: null,
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		/**
		 * User login
		 */
		case LOGIN_REQUESTING:
			return {
				...state,
				loading: true,
			}
		case LOGIN_SUCCESS:
			return {
				...state,
				loading: false,
				successMsg: action.message,
				user: action.payload,
				errMsg: null,
			}
		case LOGIN_ERROR:
			return {
				...state,
				loading: false,
				successMsg: null,
				loginErrors: action.errors,
				
			}

		/**
		 * User register
		 */
		case USER_REGISTER_REQUESTING:
			return {
				...state,
				loading: true,
			}
		case USER_REGISTER_SUCCESS:
			return {
				...state,
				loading: false,
				successMsg: action.message,
				user: action.payload,
				errMsg: null,
			}
		case USER_REGISTER_ERROR:
			return {
				...state,
				loading: false,
				successMsg: null,
				regErrors: action.errors,
			}

		/**
		 * User logout
		 */
		case LOGOUT_REQUESTING:
			return {
				...state,
				loading: true,
			}
		case LOGOUT_SUCCESS:
			return {
				...state,
				loading: false,
				successMsg: action.payload,
				user: null,
				errMsg: null,
			}
		case LOGOUT_ERROR:
			return {
				...state,
				loading: false,
				successMsg: null,
				errMsg: action.payload,
			}
		case CLEAR_ERR_MSG:
			return {
				...state,
				errMsg: null,
			}
		case PROFILE_UPDATE:
			console.log({PROFILE_UPDATE});
			console.log({payload: action.payload});
			return {
				...state,
				user: action.payload,
			}
		default:
			return state
	}
}

export default reducer
