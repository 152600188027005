import { Box, Container, Grid, Heading, Text } from '@chakra-ui/react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { getRequest } from '../services/base.service'
import BusinessCard from './BusinessCard'

const TopBusinessInLocation = () => {
	const [currentCity, setCurrentCity] = useState(null)
	const [topBusinessesInLocation, setTopBusinessesInLocation] = useState([])

	useEffect(() => {
		navigator.geolocation.getCurrentPosition(
			(pos) => {
				const { latitude, longitude } = pos.coords
				axios
					.get(
						`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
					)
					.then((res) => {
						const { administrative } = res.data.localityInfo
						const city = administrative[2].isoName

						setCurrentCity(city)
						fetchTopBusiness(city.toLowerCase().split(' ').join('-'))
					})
			},
			(error) => {
				console.error(error)
				fetchTopBusiness('new-york')
			}
		)
	}, [])

	const fetchTopBusiness = (location) => {
		getRequest(`business/locationwise/${location}`, {
			params: { limit: 6 },
		}).then((res) => {
			setTopBusinessesInLocation(res.result)
		})
	}

	return (
		<Box py="80px">
			<Container maxW={'container.xl'}>
				<Box textAlign={'center'} mb={10}>
					<Heading fontSize={'2rem'} as={'h3'}>
						Top Businesses {currentCity && ` in ${currentCity}`}
					</Heading>
					<Text mt={'3'}>Lorem ipsum is a placeholder text commonly used</Text>
				</Box>

				<Grid
					templateColumns={[
						'repeat(1, 1fr)',
						'repeat(1, 1fr)',
						'repeat(2, 1fr)',
						'repeat(3, 1fr)',
					]}
					// templateRows={'repeat(2, 1fr)'}
					gap={6}
				>
					{topBusinessesInLocation.map(
						(business, index) =>
							index < 6 && <BusinessCard key={index} data={business} />
					)}
				</Grid>
			</Container>
		</Box>
	)
}

export default TopBusinessInLocation
