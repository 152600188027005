import { Center, Divider, Text } from '@chakra-ui/react'

const LabelDivider = ({ text, ...rest }) => {
	return (
		<Center {...rest} pos={'relative'} h={'40px'}>
			{text && (
				<Text
					pos={'absolute'}
					top={'50%'}
					left={'50%'}
					transform={'translate(-50%, -50%)'}
					zIndex={'9'}
					borderColor={'gray.400'}
					borderStyle={'solid'}
					borderWidth={'1px'}
					w={'30px'}
					h={'30px'}
					align={'center'}
					bg={'white'}
					rounded={'full'}
					textTransform={'uppercase'}
					fontSize={'12px'}
					lineHeight={'28px'}
				>
					{text}
				</Text>
			)}
			<Divider borderColor="gray.400" />
		</Center>
	)
}

export default LabelDivider
