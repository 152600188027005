import React, { useEffect, useState } from 'react'
import {
	Box,
	Stack,
	Input,
	HStack,
	IconButton,
	Spinner,
	Center,
	Text,
	Alert,
	Table,
	TableCaption,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	Button,
	Select,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	useToast,
	Icon,
	Collapse,
} from '@chakra-ui/react'
import {
	Pagination,
	usePagination,
	PaginationNext,
	PaginationPage,
	PaginationPrevious,
	PaginationContainer,
	PaginationPageGroup,
	PaginationSeparator,
} from '@ajna/pagination'
import AdminBlockCard from '../../components/AdminBlockCard'
import AdminBreadcrumb from '../../components/AdminBreadcrumb'
import AdminBusinessCard from '../../components/AdminBusinessCard'
import { GoSearch } from 'react-icons/go'
import { deleteRequest, getRequest } from '../../services/base.service'
import { IoIosArrowBack } from 'react-icons/io'
import { IoIosArrowForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { FiFilter } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { setAdminSidebarFalseOnUrlChange, setCurrentPageHeading } from '../../redux/common/commonActions'

const breadCrumb = [
	{ label: 'Dashboard', isCurrentPage: false, link: '/admin/dashboard' },
	{ label: 'Location', isCurrentPage: false, link: '/admin/location' },
	{ label: 'City', isCurrentPage: true },
]

const LocationCity = () => {
	const navigate = useNavigate()

	const [cityList, setCityList] = useState([])
	const [stateList, setStateList] = useState([])
	const [countryList, setCountryList] = useState([])
	const [selectedCountry, setSelectedCountry] = useState(null)
	const [showMobileActions, setShowMobileActions] = useState(false)
	const [paginationProperty, setPaginationProperty] = useState({
		total: 1,
		currentPage: 1,
		pageSize: 1,
	})
	const [spinner, setSpinner] = useState({ status: true })
	const [queryParams, setQueryParams] = useState({ keyword: '' })
	const {
		pages,
		pagesCount,
		currentPage,
		setCurrentPage,
		isDisabled,
		setPageSize,
	} = usePagination({
		total: paginationProperty.total,
		limits: {
			outer: 1,
			inner: 2,
		},
		initialState: {
			pageSize: paginationProperty.pageSize,
			isDisabled: false,
			currentPage: 1,
		},
	})
	const [isLoading, setIsLoading] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [cityId, setCityId] = useState(null)
	const cancelRef = React.useRef()
	const toast = useToast()
	const dispatch = useDispatch()

	const onClose = () => {
		setIsOpen(false)
		setCityId(null)
	}

	const fetchCountryList = () => {
		getRequest(`countries`)
			.then((res) => {
				setCountryList(res.result)
			})
			.catch((err) => console.log('ERR: ', err))
	}
	const fetchStateList = (id) => {
		getRequest(`states/${id}`)
			.then((res) => setStateList(res.result?.states))
			.catch((err) => console.log('ERR: ', err))
	}
	useEffect(() => {
		fetchCountryList()
		dispatch(setCurrentPageHeading('City List'))
		dispatch(setAdminSidebarFalseOnUrlChange())
	}, [])

	useEffect(() => {
		if (selectedCountry) {
			fetchStateList(selectedCountry)
		}
	}, [selectedCountry])

	useEffect(() => {
		fetchCityList({ ...queryParams, page: currentPage })
	}, [currentPage])

	const fetchCityList = (params = {}) => {
		setSpinner({ status: true })
		getRequest('admin/cities', { params })
			.then((res) => {
				setCityList(res.result?.data)
				setPaginationProperty({
					total: res.result.total,
					currentPage: res.result.current_page,
					pageSize: res.result.per_page,
				})
				setPageSize(res.result.per_page)
				setSpinner({ status: false })
			})
			.catch((err) => {
				console.log('ERR: ', err)
				setSpinner({ status: false })
			})
	}

	const handleDelete = () => {
		setIsLoading(true)
		deleteRequest(`admin/cities/${cityId}`)
			.then((res) => {
				setCityId(null)
				setIsLoading(false)
				toast({
					title: 'Successfully Deleted.',
					status: 'success',
					duration: 5000,
					isClosable: true,
				})
				onClose()
				fetchCityList({ ...queryParams, page: currentPage })
			})
			.catch((err) => {
				setCityId(null)
				setIsLoading(false)
				toast({
					title: 'Something Went Wrong.',
					status: 'error',
					duration: 5000,
					isClosable: true,
				})
				console.log('ERR: ', err)
				onClose()
			})
	}

	const handlePageChange = (nextPage) => {
		setCurrentPage(nextPage)
	}

	const handleSearch = (e) => {
		e.preventDefault()
		setCurrentPage(1)
		fetchCityList({ ...queryParams, page: 1 })
	}

	return (
		<>
			<AdminBreadcrumb items={breadCrumb} />

			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete City
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<Button
								isLoading={isLoading}
								loadingText={'DELETING...'}
								colorScheme="red"
								onClick={handleDelete}
								ml={3}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

			<Box py={'7'}>
				<AdminBlockCard
					title={'City List'}
					actions={
						<HStack as={'form'}>
							<Select
								w={'130px'}
								size={'sm'}
								display={['none', 'none', 'none', 'flex']}
								borderColor={'gray.300'}
								placeholder="Select Country"
								onChange={(e) => {
									setQueryParams({ ...queryParams, country: e.target.value })
									setSelectedCountry(e.target.value)
								}}
							>
								{countryList.length > 0 &&
									countryList.map((country) => (
										<option key={country.id} value={country.id}>
											{country.name}
										</option>
									))}
							</Select>
							<Select
								w={'130px'}
								size={'sm'}
								display={['none', 'none', 'none', 'flex']}
								borderColor={'gray.300'}
								placeholder="Select State/Province"
								onChange={(e) => {
									setQueryParams({ ...queryParams, state: e.target.value })
								}}
							>
								{stateList.length > 0 &&
									stateList.map((state) => (
										<option key={state.id} value={state.id}>
											{state.name}
										</option>
									))}
							</Select>
							<HStack>
								<Input
									placeholder="Search..."
									w={['100%', '100%', '100%', '130px']}
									size={'sm'}
									borderColor={'gray.300'}
									onChange={(e) => {
										setQueryParams({ ...queryParams, keyword: e.target.value })
									}}
								/>

								<Box>
									<IconButton
										aria-label="Search"
										size={'sm'}
										icon={<GoSearch />}
										variant={'brand'}
										type="submit"
										onClick={handleSearch}
									/>
								</Box>
							</HStack>
							<Icon
								as={FiFilter}
								display={[
									'inline-block',
									'inline-block',
									'inline-block',
									'none',
								]}
								onClick={() => {
									setShowMobileActions(!showMobileActions)
								}}
								_hover={{ cursor: 'pointer' }}
							/>
						</HStack>
					}
					mobileActions={
						<Collapse
							in={showMobileActions}
							unmountOnExit={true}
							animateOpacity
						>
							<HStack
								display={[
									`${showMobileActions ? 'flex' : 'none'}`,
									`${showMobileActions ? 'flex' : 'none'}`,
									`${showMobileActions ? 'flex' : 'none'}`,
									'none',
								]}
							>
								<Select
									// w={'130px'}
									size={'sm'}
									borderColor={'gray.300'}
									placeholder="Select Country"
									onChange={(e) => {
										setQueryParams({ ...queryParams, country: e.target.value })
										setSelectedCountry(e.target.value)
										fetchCityList({ ...queryParams, country: e.target.value })
									}}
								>
									{countryList.length > 0 &&
										countryList.map((country) => (
											<option key={country.id} value={country.id}>
												{country.name}
											</option>
										))}
								</Select>
								<Select
									// w={'130px'}
									size={'sm'}
									borderColor={'gray.300'}
									placeholder="Select State/Province"
									onChange={(e) => {
										setQueryParams({ ...queryParams, state: e.target.value })
										fetchCityList({ ...queryParams, state: e.target.value })
									}}
								>
									{stateList.length > 0 &&
										stateList.map((state) => (
											<option key={state.id} value={state.id}>
												{state.name}
											</option>
										))}
								</Select>
							</HStack>
						</Collapse>
					}
				>
					{spinner.status ? (
						<Center>
							<Spinner color="red.500" size="xl" speed="0.6s" />
						</Center>
					) : cityList.length > 0 ? (
						<>
							<Stack gap={2} overflowX={'scroll'}>
								<Table
									variant="blackAlpha"
									colorScheme={'whiteAlpha'}
									w={'100%'}
									
								>
									<Thead>
										<Tr>
											<Th>#</Th>
											<Th>City</Th>
											<Th>State/Province</Th>
											<Th>Country</Th>
											<Th isNumeric>Quantity Business</Th>
											<Th textAlign="right">Actions</Th>
										</Tr>
									</Thead>
									<Tbody>
										{cityList.map((city, index) => (
											<Tr key={city.id}>
												<Td>{25 * (currentPage - 1) + (index + 1)}</Td>
												<Td>{city.name}</Td>
												<Td>{city.state.name}</Td>
												<Td>{city.country.name}</Td>
												<Td isNumeric>{city.businesses_count}</Td>
												<Td key={city.id}>
													<HStack
														textAlign="right"
														align="right"
														justify="right"
													>
														<Button
															size="sm"
															colorScheme="teal"
															variant="outline"
															onClick={() => {
																navigate(
																	`/admin/location/edit/${'city'}/${city.id}`
																)
															}}
														>
															EDIT
														</Button>
														<Button
															size="sm"
															colorScheme="pink"
															variant="outline"
															onClick={() => {
																setCityId(city.id)
																setIsOpen(true)
															}}
														>
															DELETE
														</Button>
													</HStack>
												</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							</Stack>
							<Pagination
								pagesCount={pagesCount}
								currentPage={currentPage}
								isDisabled={isDisabled}
								onPageChange={handlePageChange}
							>
								<PaginationContainer align="center" justify="center" p={4}>
									<PaginationPrevious
										w={7}
										h={7}
										_hover={{
											bg: 'gray.100',
										}}
										bg="white"
										color="gray.700"
										_disable={{ bg: 'white' }}
									>
										<Text>
											<IoIosArrowBack />
										</Text>
									</PaginationPrevious>
									<PaginationPageGroup
										isInline
										align={'center'}
										mx={'1'}
										separator={
											<PaginationSeparator fontSize="sm" w={7} jumpSize={11} />
										}
									>
										{pages.map((page) => (
											<PaginationPage
												px={'3'}
												py={'1'}
												bg={'gray.200'}
												border={'1px'}
												borderColor={'gray.300'}
												key={`pagination_page_${page}`}
												page={page}
												color={'black.500'}
												fontSize={'sm'}
												_hover={{
													bg: 'brand.600',
													color: 'white',
												}}
												_current={{
													bg: 'brand.500',
													fontSize: 'sm',
													color: 'white',
													borderColor: 'brand.500',
												}}
											/>
										))}
									</PaginationPageGroup>
									<PaginationNext
										w={7}
										h={7}
										_hover={{
											bg: 'gray.100',
										}}
										bg="white"
										color="gray.700"
										_disable={{ bg: 'white' }}
									>
										<Text>
											<IoIosArrowForward />
										</Text>
									</PaginationNext>
								</PaginationContainer>
							</Pagination>
						</>
					) : (
						<Alert status="error">
							<Center h={'100%'} w={'100%'}>
								NO DATA FOUND.
							</Center>
						</Alert>
					)}
				</AdminBlockCard>
			</Box>
		</>
	)
}

export default LocationCity
