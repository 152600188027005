import {
	AspectRatio,
	Box,
	Flex,
	Heading,
	Image,
	List,
	ListIcon,
	ListItem,
	Stack,
	HStack,
	Button,
	Text,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	useToast,
	Badge,
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import {
	FaFacebook,
	FaInstagram,
	FaLinkedin,
	FaTwitter,
	FaYoutube,
} from 'react-icons/fa'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import AdminBreadcrumb from '../../components/AdminBreadcrumb'
import { getRequest, postRequest } from '../../services/base.service'
import { useDispatch } from 'react-redux'
import { setAdminSidebarFalseOnUrlChange, setCurrentPageHeading } from '../../redux/common/commonActions'

const AdminBusinessDetails = ({ usedAs }) => {
	const [breadCrumb] = useState([
		{ label: 'Dashboard', isCurrentPage: false, link: '/admin/dashboard' },
		{
			label: usedAs !== 'claim' ? 'Business List' : 'Claim List',
			isCurrentPage: false,
			link: `/admin/${usedAs !== 'claim' ? 'business-list' : 'claimed-list'}`,
		},
	])
	const [isLoading, setIsLoading] = useState(true)
	const [business, setBusiness] = useState(null)
	const [alertDialogIsOpen, setAlertDialogIsOpen] = useState(false)
	const [actionStatus, setActionStatus] = useState(null)
	const cancelRef = useRef()

	const params = useParams()
	const dispatch = useDispatch()
	const [searchParams] = useSearchParams()
	const toast = useToast()

	useEffect(() => {
		const { id } = params
		console.log(searchParams)
		fetchBusinessDetails(id)
		dispatch(setCurrentPageHeading('Business Details'))
		dispatch(setAdminSidebarFalseOnUrlChange())
	}, [params, breadCrumb])

	const fetchBusinessDetails = (id) => {
		getRequest(`admin/businesses/${id}`)
			.then((res) => {
				setBusiness(res.result)
				setIsLoading(false)
			})
			.catch((error) => {
				setIsLoading(false)
				console.log(error)
			})
	}

	const objArrToStrArr = (objArr = [], keyName = 'name') => {
		return objArr.map((item) => item[keyName])
	}

	const showPricing = (qnt = 1) => {
		let dollarSigns = ''
		for (let i = 1; i <= qnt; i++) {
			dollarSigns = dollarSigns + '$'
		}
		return dollarSigns
	}

	const openConfirmDialog = (status) => {
		setActionStatus(status)
		setAlertDialogIsOpen(true)
	}

	const hideConfirmDialog = () => {
		setActionStatus(null)
		setAlertDialogIsOpen(false)
	}

	const handleConfirm = (status, business_id) => {
		postRequest(`admin/business/status/update`, { status, business_id })
			.then((res) => {
				//res.message
				hideConfirmDialog()
				toast({
					title: 'Status Changed.',
					description: res.message,
					status: 'success',
					duration: 5000,
					isClosable: true,
				})
				const { id } = params
				fetchBusinessDetails(id)
			})
			.catch((error) => {
				toast({
					title: 'Error',
					description: error.response.data.message || 'Something went wrong',
					status: 'error',
					duration: 5000,
					isClosable: true,
				})
			})
	}

	const setBadgeColorScheme = (status) => {
		let colorScheme

		if (status === 'approved') {
			colorScheme = 'green'
		} else if (status === 'pending') {
			colorScheme = 'yellow'
		} else {
			colorScheme = 'red'
		}

		return colorScheme
	}

	return (
		<>
			{!isLoading && (
				<AdminBreadcrumb
					items={[
						...breadCrumb,
						{ label: business?.name, isCurrentPage: true },
					]}
				/>
			)}

			<Box py={8}>
				<AlertDialog
					isOpen={alertDialogIsOpen}
					leastDestructiveRef={cancelRef}
					onClose={hideConfirmDialog}
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								Confirmation
							</AlertDialogHeader>

							<AlertDialogBody>
								Are you sure? You can't undo this action afterwards.
							</AlertDialogBody>

							<AlertDialogFooter>
								<Button ref={cancelRef} onClick={hideConfirmDialog}>
									Cancel
								</Button>
								<Button
									colorScheme={actionStatus === 'approve' ? 'green' : 'red'}
									onClick={() => handleConfirm(actionStatus, business.id)}
									ml={3}
								>
									Confirm
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>

				<Flex
					pb={6}
					borderBottomWidth={'1px'}
					borderBottomStyle={'solid'}
					borderBottomColor={'gray.300'}
					flexDirection={['column', 'column', 'column', 'row']}
					align={'center'}
					textAlign={'center'}
				>
					<AspectRatio w={'200px'} h={'200px'} ratio={1 / 1} bg={'gray.100'}>
						<Image
							w="100%"
							h="100%"
							objectFit={'contain'}
							p={3}
							src={business?.logo}
							alt={business?.name}
						/>
					</AspectRatio>

					<Box w={''} ps={[0, 0, 0, 6]}>
						<Flex
							justify={['center', 'center', 'center', 'space-between']}
							flexDirection={['column', 'column', 'column', 'row']}
							align={'center'}
						>
							<Heading as={'h2'} fontSize={'1.5rem'}>
								{business?.name}{' '}
								<Badge
									fontSize={'1rem'}
									py={'2px'}
									ms={4}
									colorScheme={setBadgeColorScheme(business?.status)}
								>
									{business?.status}
								</Badge>
							</Heading>

							<Box
								ps={'6'}
								textAlign="right"
								display={['none', 'none', 'none', 'inline-block']}
							>
								{usedAs === 'claim' ? (
									<HStack>
										<Button
											size={'sm'}
											as={Link}
											to={`./requests?cId=${searchParams.get('cId')}`}
											colorScheme={'green'}
										>
											GO TO CLAIMED REQUESTS
										</Button>
									</HStack>
								) : (
									<>
										{business?.status === 'pending' ||
										business?.status === 'declined' ? (
											<HStack>
												<Button
													as={Link}
													to={`/admin/business/edit/${business?.id}`}
													size={'sm'}
													colorScheme={'blue'}
												>
													EDIT
												</Button>
												<Button
													size={'sm'}
													onClick={() => openConfirmDialog('approve')}
													colorScheme={'green'}
												>
													APPROVE
												</Button>

												{business?.status !== 'declined' && (
													<Button
														size={'sm'}
														onClick={() => openConfirmDialog('decline')}
														colorScheme={'red'}
													>
														DECLINE
													</Button>
												)}
											</HStack>
										) : (
											<HStack>
												<Button
													as={Link}
													to={`/admin/business/edit/${business?.id}`}
													size={'sm'}
													colorScheme={'blue'}
												>
													EDIT
												</Button>
												<Button
													size={'sm'}
													onClick={() => openConfirmDialog('block')}
													colorScheme={'red'}
												>
													&nbsp; BLOCK &nbsp;
												</Button>
											</HStack>
										)}
									</>
								)}
							</Box>
						</Flex>

						<Stack mt="3" align={['center', 'center', 'center', 'start']}>
							{business?.website && (
								<Flex>
									<Text fontWeight={'500'}>Website: </Text>
									<Text ml={'3'} as={'a'} href={business?.website}>
										{business?.website}
									</Text>
								</Flex>
							)}

							{business?.phone && (
								<Flex>
									<Text fontWeight={'500'}>Phone: </Text>
									<Text ml={'3'}>{business?.phone}</Text>
								</Flex>
							)}

							{business?.whatsapp_no && (
								<Flex>
									<Text fontWeight={'500'}>WhatsApp: </Text>
									<Text ml={'3'}>{business?.whatsapp_no}</Text>
								</Flex>
							)}

							{business?.email && (
								<Flex>
									<Text fontWeight={'500'}>Email: </Text>
									<Text ml={'3'}>{business?.email}</Text>
								</Flex>
							)}
						</Stack>
						<Box>
							<Flex
								// ps={'6'}
								display={['flex', 'flex', 'flex', 'none']}
								justify={'center'}
								mt={'3'}
							>
								{usedAs === 'claim' ? (
									<HStack>
										<Button
											size={'sm'}
											as={Link}
											to={`./requests?cId=${searchParams.get('cId')}`}
											colorScheme={'green'}
										>
											GO TO CLAIMED REQUESTS
										</Button>
									</HStack>
								) : (
									<>
										{business?.status === 'pending' ||
										business?.status === 'declined' ? (
											<HStack>
												<Button
													as={Link}
													to={`/admin/business/edit/${business?.id}`}
													size={'sm'}
													colorScheme={'blue'}
												>
													EDIT
												</Button>
												<Button
													size={'sm'}
													onClick={() => openConfirmDialog('approve')}
													colorScheme={'green'}
												>
													APPROVE
												</Button>

												{business?.status !== 'declined' && (
													<Button
														size={'sm'}
														onClick={() => openConfirmDialog('decline')}
														colorScheme={'red'}
													>
														DECLINE
													</Button>
												)}
											</HStack>
										) : (
											<HStack justify={'center'} w={'100%'}>
												<Button
													as={Link}
													to={`/admin/business/edit/${business?.id}`}
													size={'sm'}
													colorScheme={'blue'}
												>
													EDIT
												</Button>
												<Button
													size={'sm'}
													onClick={() => openConfirmDialog('block')}
													colorScheme={'red'}
												>
													&nbsp; BLOCK &nbsp;
												</Button>
											</HStack>
										)}
									</>
								)}
							</Flex>
						</Box>
					</Box>
				</Flex>

				{business?.bio && (
					<Box mt={6}>
						<Heading as={'h4'} fontSize={'1.1rem'}>
							Company Bio
						</Heading>
						<Text mt={4}>{business.bio}</Text>
					</Box>
				)}

				{business?.operating_hours?.length > 0 && (
					<Box mt={6}>
						<Heading as={'h4'} fontSize={'1.1rem'}>
							Opening Hours
						</Heading>

						<Stack spacing={2} mt={4}>
							{business?.operating_hours.map((item, index) => (
								<Text key={index} display={'flex'}>
									<Text as="span" w={'200px'} fontWeight={'500'} me={4}>
										{item.day}
									</Text>
									{item?.start_time} - {item?.end_time}
								</Text>
							))}
						</Stack>
					</Box>
				)}

				<Box mt={6}>
					<Heading as={'h4'} fontSize={'1.1rem'}>
						Product Categories
					</Heading>
					<Box mt={4}>
						{objArrToStrArr(business?.product_categories).join(', ')}
					</Box>
				</Box>

				<Box mt={6}>
					<Heading as={'h4'} fontSize={'1.1rem'}>
						Products
					</Heading>
					<Box mt={4}>{objArrToStrArr(business?.products).join(', ')}</Box>
				</Box>

				<Box mt={6}>
					<Heading as={'h4'} fontSize={'1.1rem'}>
						Current Status
					</Heading>
					<Box mt={4}>{business?.current_status}</Box>
				</Box>

				<Box mt={6}>
					<Heading as={'h4'} fontSize={'1.1rem'}>
						Current Offers
					</Heading>
					<Box mt={4}>
						{objArrToStrArr(business?.current_offers, 'offer').join(', ')}
					</Box>
				</Box>

				<Box mt={6}>
					<Heading as={'h4'} fontSize={'1.1rem'}>
						Pricing
					</Heading>
					<Box mt={4}>{showPricing(business?.pricing || 1)}</Box>
				</Box>

				<Box mt={6}>
					<Heading as={'h4'} fontSize={'1.1rem'}>
						Address and GEO Location
					</Heading>

					<Box mt={4}>
						<Stack spacing={2}>
							{business?.address1 && (
								<Flex>
									<Text fontWeight={'500'} as={'span'} me={4}>
										Address 1:
									</Text>
									{business?.address1}
								</Flex>
							)}
							{business?.address2 && (
								<Flex>
									<Text fontWeight={'500'} as={'span'} me={4}>
										Address 2:
									</Text>
									{business?.address2}
								</Flex>
							)}
							{business?.city && (
								<Flex>
									<Text fontWeight={'500'} as={'span'} me={4}>
										City:
									</Text>
									{business?.city?.name}
								</Flex>
							)}
							{business?.state && (
								<Flex>
									<Text fontWeight={'500'} as={'span'} me={4}>
										State/Province:
									</Text>
									{business?.state?.name}
								</Flex>
							)}

							{business?.zip_code && (
								<Flex>
									<Text fontWeight={'500'} as={'span'} me={4}>
										Zip Code:
									</Text>
									{business?.zip_code}
								</Flex>
							)}
							{business?.country && (
								<Flex>
									<Text fontWeight={'500'} as={'span'} me={4}>
										Country:
									</Text>
									{business?.country?.name}
								</Flex>
							)}
							<Flex>
								<Text fontWeight={'500'} as={'span'} me={4}>
									Latitude:
								</Text>
								{business?.lat}
							</Flex>
							<Flex>
								<Text fontWeight={'500'} as={'span'} me={4}>
									Longitude:
								</Text>
								{business?.long}
							</Flex>
						</Stack>
					</Box>
				</Box>

				<Box mt={6}>
					<Heading as={'h4'} fontSize={'1.1rem'}>
						Social
					</Heading>

					<List mt={'4'} spacing={3}>
						{business?.facebook && (
							<ListItem>
								<ListIcon as={FaFacebook} color="facebook.500" />
								{business?.facebook}
							</ListItem>
						)}

						{business?.twitter && (
							<ListItem>
								<ListIcon as={FaTwitter} color="twitter.500" />
								{business?.twitter}
							</ListItem>
						)}
						{business?.youtube && (
							<ListItem>
								<ListIcon as={FaYoutube} color="red.500" />
								{business?.youtube}
							</ListItem>
						)}
						{business?.instagram && (
							<ListItem>
								<ListIcon as={FaInstagram} color="purple.500" />
								{business?.instagram}
							</ListItem>
						)}
						{business?.linkedin && (
							<ListItem>
								<ListIcon as={FaLinkedin} color="linkedin.500" />
								{business?.linkedin}
							</ListItem>
						)}
					</List>
				</Box>
			</Box>
		</>
	)
}

export default AdminBusinessDetails
