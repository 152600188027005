import { Box, Input, RadioGroup, SimpleGrid, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

const PricingGroup = ({ onChange, defaultValue }) => {
	const [checkedItem, setCheckedItem] = useState('')

	useEffect(() => {
		setCheckedItem(defaultValue)
	}, [defaultValue])

	const handleInputOnChange = (e) => {
		setCheckedItem(e.target.value)
		onChange('pricing', e.target.value)
	}

	return (
		<RadioGroup defaultValue={2}>
			<SimpleGrid
				rounded={'full'}
				textAlign={'center'}
				columns={4}
				borderWidth={'1px'}
				borderColor={'brand.500'}
				borderStyle={'solid'}
				className={'pricing-group'}
				gap={0}
			>
				<Box
					as={'label'}
					className={'pricing-group-item'}
					borderRightWidth={'1px'}
					borderStyle={'solid'}
					borderColor={'brand.500'}
					htmlFor={'dOne'}
				>
					<Input
						display={'none'}
						type={'radio'}
						id={'dOne'}
						onChange={handleInputOnChange}
						value={'1'}
						name="pricing"
					/>
					<Text
						roundedStart={'full'}
						as="span"
						display={'block'}
						cursor={'pointer'}
						bg={checkedItem === '1' ? 'brand.500' : 'transparent'}
						color={checkedItem === '1' ? 'brand.50' : 'gray.700'}
						p={1}
					>
						$
					</Text>
				</Box>
				<Box
					as={'label'}
					className={'pricing-group-item'}
					borderRightWidth={'1px'}
					borderStyle={'solid'}
					borderColor={'brand.500'}
					htmlFor={'dTwo'}
				>
					<Input
						display={'none'}
						type={'radio'}
						onChange={handleInputOnChange}
						id={'dTwo'}
						value={'2'}
						name="pricing"
					/>
					<Text
						as={'span'}
						display={'block'}
						cursor={'pointer'}
						bg={checkedItem === '2' ? 'brand.500' : 'transparent'}
						color={checkedItem === '2' ? 'brand.50' : 'gray.700'}
						p={1}
					>
						$$
					</Text>
				</Box>
				<Box
					as={'label'}
					className={'pricing-group-item'}
					borderRightWidth={'1px'}
					borderStyle={'solid'}
					borderColor={'brand.500'}
					htmlFor={'dThree'}
				>
					<Input
						display={'none'}
						type={'radio'}
						onChange={handleInputOnChange}
						id={'dThree'}
						value={'3'}
						name="pricing"
					/>
					<Text
						as={'span'}
						display={'block'}
						cursor={'pointer'}
						bg={checkedItem === '3' ? 'brand.500' : 'transparent'}
						color={checkedItem === '3' ? 'brand.50' : 'gray.700'}
						p={1}
					>
						$$$
					</Text>
				</Box>
				<Box as={'label'} className={'pricing-group-item'} htmlFor={'dFour'}>
					<Input
						display={'none'}
						type={'radio'}
						onChange={handleInputOnChange}
						id={'dFour'}
						value={'4'}
						name="pricing"
					/>
					<Text
						display={'block'}
						cursor={'pointer'}
						roundedEnd={'full'}
						bg={checkedItem === '4' ? 'brand.500' : 'transparent'}
						color={checkedItem === '4' ? 'brand.50' : 'gray.700'}
						p={1}
						as={'span'}
					>
						$$$$
					</Text>
				</Box>
			</SimpleGrid>
		</RadioGroup>
	)
}

export default PricingGroup
