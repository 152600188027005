import {
	Avatar,
	Box,
	Button,
	Center,
	FormControl,
	FormLabel,
	Grid,
	GridItem,
	Heading,
	Icon,
	Input,
	Select,
	SimpleGrid,
	Spinner,
	Text,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { MdEdit } from 'react-icons/md'
import { useEffect, useState } from 'react'
import { serialize } from 'object-to-formdata'
import { postRequest } from '../services/base.service'
import { useToast } from '@chakra-ui/react'
import { profileUpdate } from '../redux/auth/authActions'

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]

const EditProfile = () => {
	const { user } = useSelector((state) => state.auth)
	const [logoFile, setLogoFile] = useState(null)
	const [logo, setLogo] = useState(null)
	const [serverErrors, setServerErrors] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	const dispatch = useDispatch()
	const toast = useToast()
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm()

	useEffect(() => {
		setLogo(user?.logo)
		setValue('email', user?.email)
		setValue('name', user?.name)
		setValue('zipcode', user?.zipcode)
		setValue('day', new Date(user.birthday).getDate())
		setValue('month', new Date(user.birthday).getMonth())
		setValue('year', new Date(user.birthday).getFullYear())
	}, [user])

	const objectToFormData = (data) => {
		const object = data
		const formData = serialize(object, { indices: true })
		return formData
	}

	const browseImage = (e) => {
		if (e.target.files.length > 0) {
			setLogoFile(e.target.files[0])
			setLogo(URL.createObjectURL(e.target.files[0]))
		}
	}

	const handleUpdate = (data) => {
		setIsLoading(true)
		let birthday = `${data.year}-${parseInt(data.month) + 1}-${data.day}`
		postRequest(
			'profile-update',
			objectToFormData({
				name: data.name,
				email: data.email,
				zipcode: data.zipcode,
				birthday,
				logo: logoFile,
			})
		)
			.then((res) => {
				setIsLoading(false)
				toast({
					title: 'Successfully Updated.',
					status: 'success',
					duration: 5000,
					isClosable: true,
				})
				dispatch(profileUpdate({ ...user, ...res.result }))
			})
			.catch((err) => {
				console.log('ERR: ', err)
				setServerErrors(err.response?.data?.errors)
				setIsLoading(false)
				toast({
					title: 'Something Went Wrong.',
					status: 'error',
					duration: 5000,
					isClosable: true,
				})
			})
	}

	return (
		<Box w={'100%'}>
			<Heading as="h3" fontWeight={'700'} fontSize={'20px'}>
				Edit Profile
			</Heading>

			{!user ? (
				<Center h={'320px'}>
					<Spinner
						thickness="4px"
						speed="0.65s"
						emptyColor="gray.200"
						color="brand.500"
						size="xl"
					/>
				</Center>
			) : (
				<Grid
					w={'100%'}
					templateColumns="repeat(5, 1fr)"
					mt={10}
					gap={'3'}
					as="form"
					onSubmit={handleSubmit(handleUpdate)}
				>
					<GridItem colSpan={[5, 5, 3, 3]} pos={'relative'}>
						<Avatar
							size="2xl"
							src={logo ? logo : ''}
							as={'label'}
							title="Change profile picture"
						>
							<Input
								pos={'absolute'}
								opacity={'0'}
								zIndex={'-999'}
								type="file"
								accept="image/*"
								onChange={browseImage}
							/>
							<Icon
								as={MdEdit}
								fontSize="2xl"
								bg={'brand.400'}
								color={'white'}
								borderRadius={'50%'}
								p={'1'}
								pos={'absolute'}
								left={'100px'}
								bottom={'15px'}
								title="Change profile picture"
							/>
						</Avatar>
						{serverErrors?.logo && (
							<Text
								display={'block'}
								fontSize="sm"
								mt="2"
								color="red.500"
							>
								{serverErrors?.logo[0]}
							</Text>
						)}
					</GridItem>
					<GridItem colSpan={[5, 5, 3, 3]}>
						<FormControl>
							<FormLabel htmlFor="name">Name</FormLabel>
							<Input
								size={'lg'}
								id="name"
								type="text"
								placeholder="Name"
								isInvalid={errors.name?.type === 'required'}
								{...register('name', {
									required: true,
								})}
							/>
							<Text color="red">
								{errors.name?.type === 'required' &&
									'Name is required'}
							</Text>
							{serverErrors?.name && (
								<Text fontSize="sm" mt="2" color="red.500">
									{serverErrors?.name[0]}
								</Text>
							)}
						</FormControl>
					</GridItem>
					<GridItem colSpan={[5, 5, 3, 3]}>
						<FormControl>
							<FormLabel htmlFor="email">Email address</FormLabel>
							<Input
								size={'lg'}
								id="email"
								type="email"
								placeholder="Email Address"
								isInvalid={errors.email?.type === 'required'}
								{...register('email', {
									required: true,
									pattern: {
										value: /\S+@\S+\.\S+/,
										message:
											'Entered value does not match email format',
									},
								})}
							/>
							<Text color="red">
								{errors.email?.type === 'required' &&
									'Email is required'}
							</Text>
							{errors.email?.message && (
								<Text color="red">{errors.email?.message}</Text>
							)}
							{serverErrors?.email && (
								<Text fontSize="sm" mt="2" color="red.500">
									{serverErrors?.email[0]}
								</Text>
							)}
						</FormControl>
					</GridItem>
					<GridItem colSpan={[5, 5, 3, 3]}>
						<FormControl>
							<FormLabel htmlFor="zipcode">Zip Code</FormLabel>
							<Input
								size={'lg'}
								id="zipcode"
								type="text"
								placeholder="Zip Code"
								isInvalid={errors.zipcode?.type === 'required'}
								{...register('zipcode', {
									required: true,
								})}
							/>
							<Text color="red">
								{errors.zipcode?.type === 'required' &&
									'Zip Code is required'}
							</Text>
							{serverErrors?.zipcode && (
								<Text fontSize="sm" mt="2" color="red.500">
									{serverErrors?.zipcode[0]}
								</Text>
							)}
						</FormControl>
					</GridItem>
					<GridItem colSpan={[5, 5, 3, 3]}>
						<SimpleGrid columns={3} spacing={4}>
							<FormControl>
								<FormLabel htmlFor="day">Day</FormLabel>
								<Select
									id="day"
									size={'lg'}
									placeholder="Day"
									isInvalid={errors.day?.type === 'required'}
									{...register('day', { required: true })}
								>
									{[...Array(31).keys()].map(
										(date, index) => (
											<option
												key={date + 1}
												value={date + 1}
											>
												{date + 1}
											</option>
										)
									)}
								</Select>
								<Text fontSize="sm" color="red.500">
									{errors.day?.type === 'required' &&
										'Day is required'}
								</Text>
							</FormControl>
							<FormControl>
								<FormLabel htmlFor="month">Month</FormLabel>
								<Select
									id="month"
									size={'lg'}
									placeholder="Month"
									isInvalid={
										errors.month?.type === 'required'
									}
									{...register('month', { required: true })}
								>
									{[...Array(12).keys()].map((month) => (
										<option key={month + 1} value={month}>
											{months[month]}
										</option>
									))}
								</Select>
								<Text fontSize="sm" color="red.500">
									{errors.month?.type === 'required' &&
										'Month is required'}
								</Text>
							</FormControl>

							<FormControl>
								<FormLabel htmlFor="year">Year</FormLabel>
								<Select
									id="year"
									size={'lg'}
									placeholder="Year"
									isInvalid={errors.year?.type === 'required'}
									{...register('year', { required: true })}
								>
									{Array.from(
										{ length: 120 },
										(_, i) =>
											new Date().getFullYear() - 120 + i
									)
										.reverse()
										.map((year) => (
											<option key={year} value={year}>
												{year}
											</option>
										))}
								</Select>
								<Text fontSize="sm" color="red.500">
									{errors.year?.type === 'required' &&
										'Year is required'}
								</Text>
							</FormControl>
						</SimpleGrid>
					</GridItem>
					{serverErrors?.zipcode && (
						<Text fontSize="sm" mt="2" color="red.500">
							{serverErrors?.zipcode[0]}
						</Text>
					)}
					<GridItem align={'right'} colSpan={[5, 5, 3, 3]}>
						<Button
							isLoading={isLoading}
							my={4}
							variant={'brand'}
							type={'submit'}
							loadingText={'UPDATING...'}
						>
							UPDATE
						</Button>
					</GridItem>
				</Grid>
			)}
		</Box>
	)
}

export default EditProfile
