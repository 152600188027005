import React, { useEffect, useState } from 'react'
import {
	Box,
	Input,
	Spinner,
	Center,
	Text,
	Alert,
	FormControl,
	FormLabel,
	Image,
	Select,
	Grid,
	GridItem,
	Button,
	AspectRatio,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import AdminBlockCard from '../../components/AdminBlockCard'
import AdminBreadcrumb from '../../components/AdminBreadcrumb'
import { getRequest, postRequest } from '../../services/base.service'
import { serialize } from 'object-to-formdata'
import { useToast } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { setAdminSidebarFalseOnUrlChange, setCurrentPageHeading } from '../../redux/common/commonActions'

const breadCrumb = [
	{ label: 'Dashboard', isCurrentPage: false, link: '/admin/dashboard' },
	{ label: 'Location', isCurrentPage: true, link: '/admin/location' },
	{ label: 'Add Location', isCurrentPage: true },
]

const AddLocation = () => {
	const [locationType, setLocationType] = useState('country')
	const [countryList, setCountryList] = useState([])
	const [stateList, setStateList] = useState([])
	const [serverErrors, setServerErrors] = useState(null)
	const [coverPhotoFile, setCoverPhotoFile] = useState(null)
	const [coverPhoto, setCoverPhoto] = useState(null)
	const [spinner, setSpinner] = useState({ status: false })
	const [isLoading, setIsLoading] = useState(false)
	const toast = useToast()
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues: {
			locationType: 'country',
		},
	})

	const dispatch = useDispatch()

	const fetchCountryList = () => {
		getRequest(`countries`)
			.then((res) => {
				setCountryList(res.result)
			})
			.catch((err) => {
				console.log('ERR: ', err)
			})
	}

	useEffect(() => {
		fetchCountryList()
		dispatch(setCurrentPageHeading('Add Location'))
		dispatch(setAdminSidebarFalseOnUrlChange())
	}, [])

	const fetchStateList = (id) => {
		getRequest(`states/${id}`)
			.then((res) => {
				setStateList(res.result.states)
				console.log('state===', res.result)
				setSpinner({ status: false })
			})
			.catch((err) => {
				console.log('ERR: ', err)
				setSpinner({ status: false })
			})
	}

	const browseImage = (e) => {
		if (e.target.files.length > 0) {
			setCoverPhotoFile(e.target.files[0])
			setCoverPhoto(URL.createObjectURL(e.target.files[0]))
		}
	}

	const getLocationType = () => {
		return locationType === 'country'
			? 'countries'
			: locationType === 'state'
			? 'states'
			: 'cities'
	}
	const objectToFormData = (data) => {
		const object = data
		const formData = serialize(object, { indices: true })
		return formData
	}

	const handleSubmitFormData = (data) => {
		setIsLoading(true)
		postRequest(
			`admin/${getLocationType()}`,
			objectToFormData({ ...data, banner: coverPhotoFile })
		)
			.then((res) => {
				toast({
					title: 'Successfully Added.',
					status: 'success',
					duration: 5000,
					isClosable: true,
				})
				setIsLoading(false)
			})
			.catch((err) => {
				setServerErrors(err.response?.data?.errors)
				console.log('ERR :', err)
				toast({
					title: 'Something Went Wrong.',
					status: 'error',
					duration: 5000,
					isClosable: true,
				})
				setIsLoading(false)
			})
		console.log(data)
	}

	return (
		<>
			<AdminBreadcrumb items={breadCrumb} />
			<Box py={'7'}>
				{
					<AdminBlockCard title={'ADD Location'}>
						{spinner.status ? (
							<Center>
								<Spinner color="red.500" size="xl" speed="0.6s" />
							</Center>
						) : (
							<Box as="form" onSubmit={handleSubmit(handleSubmitFormData)}>
								<Grid
									templateColumns={[
										'repeat(1, 1fr)',
										'repeat(1, 1fr)',
										'repeat(1, 1fr)',
										'repeat(5, 1fr)',
									]}
									gap={5}
								>
									<GridItem colSpan={['1', '1', '1', '3']}>
										<FormControl mb={3}>
											<FormLabel htmlFor="locationType">
												Location Type
											</FormLabel>

											<Select
												size={'lg'}
												placeholder="Select Location Type"
												borderColor={'gray.300'}
												value={locationType}
												{...register('locationType', { required: true })}
												onChange={(e) => {
													setLocationType(e.target.value)
													setValue('name', null)
													setValue('county_id', null)
													setValue('state_id', null)
												}}
											>
												<option value="country">Country</option>
												<option value="state">State/Province</option>
												<option value="city">City</option>
											</Select>
										</FormControl>
										<FormControl mb={3}>
											{locationType && (
												<FormLabel htmlFor="country">Country</FormLabel>
											)}
											{locationType && locationType === 'country' && (
												<>
													<Input
														id="country"
														type="text"
														placeholder={'Type To Change Country'}
														borderColor={'gray.300'}
														{...register('name', {
															required: true,
														})}
														size={'lg'}
													/>
													<Text fontSize="sm" color="red.500">
														{errors.name?.type === 'required' &&
															'Country is required'}
													</Text>
													{serverErrors?.name && (
														<Text fontSize="sm" mt="2" color="red.500">
															{serverErrors?.name[0]}
														</Text>
													)}
												</>
											)}
											{locationType && locationType !== 'country' && (
												<>
													<Select
														size={'lg'}
														placeholder="Select Country"
														borderColor={'gray.300'}
														{...register('country_id', {
															required: true,
															onChange: (e) => {
																setValue('state_id', null)
																if (e.target.value) {
																	fetchStateList(e.target.value)
																}
																setStateList([])
															},
														})}
													>
														{countryList.length > 0 &&
															countryList.map((country) => (
																<option key={country.id} value={country.id}>
																	{country.name}
																</option>
															))}
													</Select>
													<Text fontSize="sm" color="red.500">
														{errors.country_id?.type === 'required' &&
															'Country is required'}
													</Text>
													{serverErrors?.country_id && (
														<Text fontSize="sm" mt="2" color="red.500">
															{serverErrors?.country_id[0]}
														</Text>
													)}
												</>
											)}
										</FormControl>
										{locationType && locationType !== 'country' && (
											<FormControl mb={3}>
												<FormLabel htmlFor="state/province">
													State/Province
												</FormLabel>
												{locationType === 'state' && (
													<>
														<Input
															id="state/province"
															type="text"
															placeholder={'Type To Change State/Province'}
															borderColor={'gray.300'}
															size={'lg'}
															{...register('name', {
																required: true,
															})}
														/>
														<Text fontSize="sm" color="red.500">
															{errors.name?.type === 'required' &&
																'State/Province is required'}
														</Text>
														{serverErrors?.name && (
															<Text fontSize="sm" mt="2" color="red.500">
																{serverErrors?.name[0]}
															</Text>
														)}
													</>
												)}
												{locationType === 'city' && (
													<>
														<Select
															size={'lg'}
															placeholder="Select State/Province"
															borderColor={'gray.300'}
															{...register('state_id', {
																required: true,
															})}
														>
															{stateList &&
																stateList.length > 0 &&
																stateList.map((state) => (
																	<option key={state.id} value={state.id}>
																		{state.name}
																	</option>
																))}
														</Select>
														<Text fontSize="sm" color="red.500">
															{errors.state_id?.type === 'required' &&
																'State/Province is required'}
														</Text>
														{serverErrors?.state_id && (
															<Text fontSize="sm" mt="2" color="red.500">
																{serverErrors?.state_id[0]}
															</Text>
														)}
													</>
												)}
											</FormControl>
										)}
										{locationType && locationType === 'city' && (
											<FormControl mb={3}>
												<FormLabel htmlFor="city">City</FormLabel>
												<Input
													id="city"
													type="text"
													placeholder={'Type To Change City'}
													borderColor={'gray.300'}
													size={'lg'}
													{...register('name', {
														required: true,
													})}
												/>
												<Text fontSize="sm" color="red.500">
													{errors.name?.type === 'required' &&
														'City is required'}
												</Text>
												{serverErrors?.name && (
													<Text fontSize="sm" mt="2" color="red.500">
														{serverErrors?.name[0]}
													</Text>
												)}
											</FormControl>
										)}
									</GridItem>

									<GridItem colSpan={['1', '1', '1', '2']}>
										<FormControl>
											<FormLabel>Cover Photo</FormLabel>
											<Box
												display={'block'}
												as="label"
												w={'full'}
												borderWidth={'1px'}
												borderStyle={'solid'}
												borderColor={'gray.400'}
												htmlFor="browseLogo"
												pos={'relative'}
												style={{
													cursor: 'pointer',
													'& > .label': {
														display: 'none',
													},
													'&:hover > .label': {
														display: 'block',
													},
												}}
											>
												<AspectRatio ratio={16 / 9}>
													{coverPhoto ? (
														<Image
															src={coverPhoto ? coverPhoto : ''}
															alt={''}
															w={'100%'}
															h={'100%'}
															objectFit={'contain !important'}
														/>
													) : (
														<></>
													)}
												</AspectRatio>

												<Input
													w={0}
													h={0}
													p={0}
													position={'absolute'}
													name="coverPhoto"
													zIndex={'-99999'}
													opacity={'0'}
													type={'file'}
													id={'browseLogo'}
													onChange={browseImage}
													accept={'image/*'}
													// {...register('logo', { required: true })}
													// isRequired={!fData}
												/>
												<Text
													as="span"
													className={'label'}
													pos={'absolute'}
													top={'50%'}
													left={'50%'}
													fontSize={'12px'}
													display={'block'}
													whiteSpace={'nowrap'}
													px={'2'}
													py={'1'}
													rounded={'full'}
													color={'white'}
													bg={'blue.500'}
													textAlign={'center'}
													textTransform={'uppercase'}
													transform={'translate(-50%, -50%)'}
												>
													Change Image
												</Text>
											</Box>
										</FormControl>
									</GridItem>
								</Grid>
								<Button
									w={['full', 'full', '200px', '200px']}
									mt={'5'}
									textAlign={'left'}
									type="submit"
									isLoading={isLoading}
									loadingText="ADDING..."
									variant={'brand'}
								>
									ADD
								</Button>
							</Box>
						)}
					</AdminBlockCard>
				}
			</Box>
		</>
	)
}

export default AddLocation
