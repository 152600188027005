export const objectToQueryParams = (obj) => {
	return Object.keys(obj)
		.map((key) => key + '=' + obj[key])
		.join('&')
}

export const queryParamsToObject = (qParams) => {
	return JSON.parse(
		'{"' +
			decodeURI(qParams)
				.replace(/"/g, '\\"')
				.replace(/&/g, '","')
				.replace(/=/g, '":"') +
			'"}'
	)
}
