import { useEffect, useState } from 'react'
import {
	Grid,
	GridItem,
	FormControl,
	Input,
	Select,
	Button,
} from '@chakra-ui/react'
import { FiSearch } from 'react-icons/fi'
import { getRequest } from '../services/base.service'
import { fetchBusinessList } from '../redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { objectToQueryParams } from '../helpers/utils'

const HomeSearchForm = () => {
	const [countries, setCountries] = useState([])
	const [states, setStates] = useState([])
	const [cities, setCities] = useState([])
	const [selectedData, setSelectedData] = useState({})

	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		fetchCountries()
	}, [])

	const handleOnChange = (name, value) => {
		if (name === 'country') {
			if (!value) {
				setStates([])
				setCities([])
				return
			}
			fetchState(value)
		} else if (name === 'state') {
			if (!value) {
				setCities([])
				return
			}
			fetchCities(value)
		}
		setSelectedData({ ...selectedData, [name]: value })
	}

	const fetchCountries = () => {
		getRequest('countries')
			.then((res) => {
				setCountries(res.result)
			})
			.catch((err) => {
				console.error(err)
			})
	}

	const fetchState = (countryId) => {
		getRequest(`states/${countryId}`)
			.then((res) => {
				setStates(res.result?.states || [])
				setCities(res.result?.cities || [])
			})
			.catch((err) => {
				console.error(err)
			})
	}

	const fetchCities = (stateId) => {
		getRequest(`cities/${stateId}`)
			.then((res) => {
				setCities(res.result?.cities || [])
			})
			.catch((err) => {
				console.error(err)
			})
	}

	const handleSubmitSearchForm = (e) => {
		e.preventDefault()
		dispatch(
			fetchBusinessList(selectedData, (isSuccess) => {
				if (isSuccess) navigate(`/search?${objectToQueryParams(selectedData)}`)
			})
		)
	}

	return (
		<Grid
			// p={['15px', '20px', 0, 0]}
			// bg={['white', 'white', 'transparent', 'transparent']}
			// borderWidth={['1px', '1px', 0, 0]}
			// borderColor={'gray.200'}
			as={'form'}
			onSubmit={handleSubmitSearchForm}
			templateRows="repeat(2, 1fr)"
			templateColumns="repeat(4, 1fr)"
			gap={4}
		>
			<GridItem colSpan={4}>
				<FormControl>
					<Input
						id="keywords"
						type="text"
						onChange={(e) => handleOnChange('keyword', e.target.value)}
						size={'lg'}
						borderColor={'gray.500'}
						placeholder="Business/Product/Product Category"
					/>
				</FormControl>
			</GridItem>

			<GridItem colSpan={[2, 2, 0, 0]}>
				<FormControl>
					<Select
						size={'lg'}
						onChange={(e) => handleOnChange('country', e.target.value)}
						borderColor={'gray.500'}
						placeholder={'Country'}
					>
						{countries.map((country, index) => (
							<option key={index} value={country.id}>
								{country.name}
							</option>
						))}
					</Select>
				</FormControl>
			</GridItem>

			<GridItem colSpan={[2, 2, 0, 0]}>
				<Select
					size={'lg'}
					onChange={(e) => handleOnChange('state', e.target.value)}
					isDisabled={!states.length > 0}
					borderColor={'gray.500'}
					placeholder={'State'}
				>
					{states.map((state, index) => (
						<option key={index} value={state.id}>
							{state.name}
						</option>
					))}
				</Select>
			</GridItem>

			<GridItem colSpan={[2, 2, 0, 0]}>
				<Select
					size={'lg'}
					onChange={(e) => handleOnChange('city', e.target.value)}
					isDisabled={!cities.length > 0}
					borderColor={'gray.500'}
					placeholder={'City'}
				>
					{cities.map((city, index) => (
						<option key={index} value={city.id}>
							{city.name}
						</option>
					))}
				</Select>
			</GridItem>

			<GridItem colSpan={[2, 2, 0, 0]}>
				<FormControl>
					<Input
						id="keywords"
						onChange={(e) => handleOnChange('zipcode', e.target.value)}
						type="text"
						size={'lg'}
						borderColor={'gray.500'}
						placeholder="Zip Code"
					/>
				</FormControl>
			</GridItem>

			<GridItem textAlign={'center'} color={'brand.50'} colSpan={4}>
				<Button
					type={'submit'}
					bg={'brand.500'}
					size={'lg'}
					w={'full'}
					maxW={'320px'}
					_hover={{
						bg: 'brand.600',
						color: 'white',
					}}
					_focus={{
						bg: 'brand.700',
						color: 'white',
					}}
					_active={{
						bg: 'brand.700',
						color: 'white',
					}}
					leftIcon={<FiSearch />}
				>
					Search
				</Button>
			</GridItem>
		</Grid>
	)
}

export default HomeSearchForm
